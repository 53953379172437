import React from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { useAppSelector } from 'hooks';

import { WmsManager } from 'models';

import { ratingToName } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    legend: {
      borderRadius: 4,
      filter: 'drop-shadow(0px 2px 4px rgba(128, 143, 153, 0.4))',
      marginTop: theme.spacing(1),
    },
    legendItem: {
      width: 100,
      height: theme.spacing(4),
      display: 'inline-block',
      textAlign: 'center',
      paddingTop: 5,
      fontWeight: 'bold',
    },
  }),
);

const RatingLegend = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { legends } = useAppSelector((state) => state.layers);

  const legend = legends.IDZ10136_AUS_AFDRS_max_fdr_SFC.object;

  return (
    <div style={{ float: 'right', marginTop: theme.spacing(7) }}>
      <span style={{ color: theme.palette.common.neutralDark }}>Fire danger rating</span>
      <div className={classes.legend}>
        <div
          className={classes.legendItem}
          style={{
            background: WmsManager.getLegendEntry(0, legend)?.color ?? theme.palette.common.white,
            borderRadius: '4px 0 0 4px',
          }}
        >
          {ratingToName(0)}
        </div>
        <div
          className={classes.legendItem}
          style={{
            background: WmsManager.getLegendEntry(1, legend)?.color ?? theme.palette.common.neutralXXLight,
          }}
        >
          {ratingToName(1)}
        </div>
        <div
          className={classes.legendItem}
          style={{ background: WmsManager.getLegendEntry(2, legend)?.color ?? theme.palette.common.neutralXXLight }}
        >
          {ratingToName(2)}
        </div>
        <div
          className={classes.legendItem}
          style={{ background: WmsManager.getLegendEntry(3, legend)?.color ?? theme.palette.common.neutralXXLight }}
        >
          {ratingToName(3)}
        </div>
        <div
          className={classes.legendItem}
          style={{
            background: WmsManager.getLegendEntry(4, legend)?.color ?? theme.palette.common.neutralXXLight,
            borderRadius: '0 4px 4px 0',
            color: theme.palette.common.white,
          }}
        >
          {ratingToName(4)}
        </div>
      </div>
    </div>
  );
};

export default RatingLegend;
