import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Paper, Button } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';

import { IncidentManager, Loadable } from 'models';
import { toReadableAge, toTimeDDMonYearFormat } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 250,
      margin: theme.spacing(0.25),
      padding: theme.spacing(1),
      border: `0px`,
      backgroundColor: 'rgba(0,0,0,0.75)',
      display: 'grid',
      borderRadius: 4,
    },
    white: {
      color: theme.palette.common.white,
    },
    btn: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.85)',
      },
    },
    leftRight: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr auto',
    },
    title: {
      color: theme.palette.common.neutralXXLight,
      fontWeight: 'bold',
      width: '100%',
    },
    subtitle: {
      color: theme.palette.common.neutralXXLight,
      fontWeight: 'bold',
    },
    value: {
      color: theme.palette.common.neutralXLight,
      fontWeight: 'bold',
    },
  }),
);

interface IncidentPopupProps {
  close: () => void;
  onViewDetails?: (incident: IncidentManager.Incident) => void;
  incidents?: Loadable<IncidentManager.Incident[]>;
}

const IncidentPopup: React.FunctionComponent<IncidentPopupProps> = ({ close, incidents, onViewDetails }) => {
  const classes = useStyles();

  const [index, setIndex] = useState(0);

  const incident = incidents?.object?.[index];

  const prevIncident = () => {
    setIndex(index - 1 < 0 ? (incidents?.object?.length ?? 1) - 1 : index - 1);
  };

  const nextIncident = () => {
    setIndex((index + 1) % (incidents?.object?.length ?? 0));
  };

  useEffect(() => {
    setIndex(0);
  }, [incidents?.object]);

  if (incident == null)
    return (
      <Paper className={classes.root}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ChevronLeft className={classes.white} onClick={prevIncident} />
          <Typography className={classes.white} variant="subtitle2">
            {index + 1} of {incidents?.object?.length ?? 0} Incidents
          </Typography>
          <ChevronRight className={classes.white} onClick={nextIncident} />
        </div>
        <Typography className={classes.title} variant="subtitle1">
          No incident
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className={classes.btn} onClick={close}>
            <Close /> Close
          </Button>
          <Button className={classes.btn} disabled>
            View Details
          </Button>
        </div>
      </Paper>
    );

  let lastUpdatedText = incident.updateDate != null ? toReadableAge(Date.now() - incident.updateDate.getTime()) : null;

  if (lastUpdatedText == null && incident.notified)
    lastUpdatedText = toReadableAge(Date.now() - incident.notified.getTime());
  if (lastUpdatedText == null) lastUpdatedText = 'N/A';

  return (
    <Paper className={classes.root}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ChevronLeft className={classes.white} onClick={prevIncident} />
        <Typography className={classes.white} variant="subtitle2">
          {index + 1} of {incidents?.object?.length ?? 0} Incidents
        </Typography>
        <ChevronRight className={classes.white} onClick={nextIncident} />
      </div>
      <Typography className={classes.title} variant="subtitle1">
        {incident.title ?? 'No title'}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {incident.warningCategory}
      </Typography>

      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Last Updated:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {lastUpdatedText}
        </Typography>
      </div>

      {incident.notified != null && (
        <div className={classes.leftRight}>
          <Typography className={classes.white} variant="subtitle2">
            Created:
          </Typography>
          <Typography align="right" className={classes.value} variant="subtitle2">
            {toTimeDDMonYearFormat(incident.notified)}
          </Typography>
        </div>
      )}

      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Id:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.nativeId}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Type:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.fireType}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Status:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.status}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          LGA:{' '}
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.lga}
        </Typography>
      </div>
      <div>
        <Typography className={classes.white} variant="subtitle2">
          Location:{' '}
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.locationDescription}
        </Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button className={classes.btn} onClick={close}>
          <Close /> Close
        </Button>
        <Button className={classes.btn} onClick={() => onViewDetails && onViewDetails(incident)}>
          View Details
        </Button>
      </div>
    </Paper>
  );
};

IncidentPopup.displayName = 'IncidentPopup';
export default IncidentPopup;
