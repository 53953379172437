import { LayerManager } from 'models';

interface GenerateOffsetsMatrixInterface {
  layers: LayerManager.Layer[];
}

function generateOffsetsMatrixHelper({ layers }: GenerateOffsetsMatrixInterface) {
  // Check all layers are 24 hour layers
  const checkNonDailyLayer = layers.find((e) => e.hoursPerStep !== 24);

  const msToHours = 1000 * 60 * 60;

  // Don't generate the offsets matrix if any layer is non 24-hour
  if (checkNonDailyLayer !== undefined) return undefined;

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  const offsetsMap: { [key: string]: number } = {};

  layers.forEach((layer) => {
    if (layer.timeSteps === null || layer.serviceName === null || layer.timeSteps.length === 0) {
      return undefined;
    }

    // Calculate the difference between the earliest timestep date and the start of 'today' (local timezone)
    const earliestTimeStepDate = new Date(layer.timeSteps[0].getTime());
    const nearestNextDayDate = new Date(earliestTimeStepDate);
    nearestNextDayDate.setDate(nearestNextDayDate.getDate() + 1);
    nearestNextDayDate.setHours(0, 0, 0, 0);
    const differenceInHrs = (nearestNextDayDate.getTime() - earliestTimeStepDate.getTime()) / msToHours;

    // Max time difference between states should be about 3 hours. Hence a maximum acceptable difference to apply this offset should be about 4 hours.
    // If the 'earliest time' is within 4 hours of the 'next nearest day' then set an offset.
    // e.g. if The 'earliest time' is 2300 hrs Saturday, apply a 1 hr offset to move the timeslider to Sunday.
    if (layer.timeSteps[0] < startOfToday && differenceInHrs < 4) {
      const offset = (nearestNextDayDate.getTime() - layer.timeSteps[0].getTime()) / msToHours;
      offsetsMap[layer.id] = offset;
    } else {
      offsetsMap[layer.id] = 0;
    }
  });

  return offsetsMap;
}

export default generateOffsetsMatrixHelper;
