/* eslint-disable no-template-curly-in-string */
import { ReportConfig } from 'models/export';

export const makeA4MapPage = (mapId: string, legendId: string, layerId: string): ReportConfig.Page => ({
  elements: [
    {
      type: 'map',
      id: mapId,
      width: 220,
      maxWidth: 220,
      maxHeight: 140,
      location: {
        x: {
          align: 'left',
        },
        y: {
          align: 'top',
          offset: 8,
        },
      },
    },
    {
      type: 'image',
      id: 'north',
      width: 8,
      url: '/north-arrow.png',
      location: {
        x: {
          align: 'left',
          offset: 1,
        },
        y: {
          align: 'top',
          offset: 9,
        },
      },
    },
    {
      type: 'text',
      id: 'smalltime',
      text: '${timeslider_date_small}',
      fontSize: 8,
      fontStyle: 'bold',
      location: {
        x: {
          align: 'centerof',
          id: mapId,
        },
        y: {
          align: 'under',
          id: mapId,
          offset: -6,
        },
      },
    },
    {
      type: 'text',
      id: 'title',
      text: '${title}',
      fontSize: 18,
      fontStyle: 'bold',
      location: {
        x: {
          align: 'center',
        },
        y: {
          align: 'top',
          offset: 2,
        },
      },
    },
    {
      type: 'text',
      id: 'subtitle',
      text: `Layer: \${${layerId}_name}`,
      fontSize: 16,
      fontStyle: 'bold',
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 5,
          id: mapId,
        },
      },
    },
    {
      type: 'text',
      id: 'legendTitle',
      text: `Legend \${${layerId}_units_bracket}`,
      location: {
        x: {
          align: 'rightof',
          id: mapId,
          offset: 2,
        },
        y: {
          align: 'under',
          offset: 2,
          id: 'title',
        },
      },
      fontSize: 12,
      fontStyle: 'bold',
    },
    {
      type: 'custom_image',
      id: 'mainLegend',
      refId: legendId,
      location: {
        x: {
          align: 'rightof',
          id: mapId,
          offset: 2,
        },
        y: {
          align: 'under',
          id: 'legendTitle',
          offset: -2,
        },
      },
    },
    {
      type: 'custom_image',
      id: 'incidentLegend',
      refId: 'incidentLegend',
      location: {
        x: {
          align: 'rightof',
          id: mapId,
          offset: 3,
        },
        y: {
          align: 'under',
          id: 'mainLegend',
          offset: 1,
        },
      },
    },
    {
      type: 'custom_image',
      id: 'weatherStationLegend',
      refId: 'weatherStationLegend',
      location: {
        x: {
          align: 'rightof',
          id: mapId,
          offset: 3,
        },
        y: {
          align: 'under',
          id: 'incidentLegend',
          offset: 1,
        },
      },
    },
    {
      type: 'text',
      text: 'Notes:',
      id: 'notesTitle',
      fontStyle: 'bold',
      fontSize: 12,
      location: {
        x: {
          align: 'left',
          offset: 80,
        },
        y: {
          align: 'under',
          id: 'subtitle',
          offset: 2,
        },
      },
    },
    {
      type: 'text',
      text: '${notes}',
      fontSize: 10,
      width: 140,
      location: {
        x: {
          align: 'left',
          offset: 80,
        },
        y: {
          align: 'under',
          id: 'notesTitle',
        },
      },
    },
    {
      type: 'text',
      id: 'detailsTitle',
      text: 'Report Details:',
      fontSize: 12,
      fontStyle: 'bold',
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          id: 'subtitle',
          offset: 2,
        },
      },
    },
    {
      type: 'text',
      text: 'Time Selected',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          id: 'detailsTitle',
          offset: 1,
        },
      },
    },
    {
      type: 'text',
      id: 'timesliderTime',
      text: '${timeslider_date}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          id: 'detailsTitle',
          offset: 1,
        },
      },
    },
    {
      type: 'text',
      text: 'Last Updated',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'timesliderTime',
        },
      },
    },
    {
      type: 'text',
      id: 'layerLastUpdated',
      text: `\${${layerId}_last_updated}`,
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'timesliderTime',
        },
      },
    },
    {
      type: 'text',
      text: 'Generated on',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'layerLastUpdated',
        },
      },
    },
    {
      type: 'text',
      id: 'generatedText',
      text: '${date}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'layerLastUpdated',
        },
      },
    },
    {
      type: 'text',
      text: 'Generated by',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'generatedText',
        },
      },
    },
    {
      type: 'text',
      id: 'generatedBy',
      text: '${user_name}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'generatedText',
        },
      },
    },
    {
      type: 'text',
      text: 'Attributions',
      fontSize: 10,
      width: 80,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'generatedBy',
        },
      },
    },
    {
      type: 'text',
      id: 'attributions',
      text: '${attributions}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'generatedBy',
        },
      },
    },
  ],
});

export const makeA3MapPage = (mapId: string, legendId: string, layerId: string): ReportConfig.Page => ({
  elements: [
    {
      type: 'map',
      id: mapId,
      width: 340,
      maxWidth: 340,
      maxHeight: 220,
      location: {
        x: {
          align: 'left',
        },
        y: {
          align: 'top',
          offset: 8,
        },
      },
    },
    {
      type: 'image',
      id: 'north',
      width: 8,
      url: '/north-arrow.png',
      location: {
        x: {
          align: 'left',
          offset: 1,
        },
        y: {
          align: 'top',
          offset: 9,
        },
      },
    },
    {
      type: 'text',
      id: 'smalltime',
      text: '${timeslider_date}',
      fontSize: 9,
      fontStyle: 'bold',
      location: {
        x: {
          align: 'centerof',
          id: mapId,
        },
        y: {
          align: 'under',
          id: mapId,
          offset: -9.5,
        },
      },
    },
    {
      type: 'text',
      id: 'title',
      text: '${title}',
      fontSize: 18,
      fontStyle: 'bold',
      location: {
        x: {
          align: 'center',
        },
        y: {
          align: 'top',
          offset: 2,
        },
      },
    },
    {
      type: 'text',
      id: 'subtitle',
      text: `Layer: \${${layerId}_name}`,
      fontSize: 16,
      fontStyle: 'bold',
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 5,
          id: mapId,
        },
      },
    },
    {
      type: 'text',
      id: 'legendTitle',
      text: `Legend \${${layerId}_units_bracket}`,
      location: {
        x: {
          align: 'rightof',
          id: mapId,
          offset: 2,
        },
        y: {
          align: 'under',
          offset: 2,
          id: 'title',
        },
      },
      fontSize: 12,
      fontStyle: 'bold',
    },
    {
      type: 'custom_image',
      id: 'mainLegend',
      refId: legendId,
      location: {
        x: {
          align: 'rightof',
          id: mapId,
          offset: 2,
        },
        y: {
          align: 'under',
          id: 'legendTitle',
          offset: -2,
        },
      },
    },
    {
      type: 'custom_image',
      id: 'incidentLegend',
      refId: 'incidentLegend',
      location: {
        x: {
          align: 'rightof',
          id: mapId,
          offset: 3,
        },
        y: {
          align: 'under',
          id: 'mainLegend',
          offset: 1,
        },
      },
    },
    {
      type: 'custom_image',
      id: 'weatherStationLegend',
      refId: 'weatherStationLegend',
      location: {
        x: {
          align: 'rightof',
          id: mapId,
          offset: 3,
        },
        y: {
          align: 'under',
          id: 'incidentLegend',
          offset: 1,
        },
      },
    },
    {
      type: 'text',
      text: 'Notes:',
      id: 'notesTitle',
      fontStyle: 'bold',
      fontSize: 12,
      location: {
        x: {
          align: 'left',
          offset: 90,
        },
        y: {
          align: 'under',
          id: 'subtitle',
          offset: 2,
        },
      },
    },
    {
      type: 'text',
      text: '${notes}',
      fontSize: 10,
      width: 240,
      location: {
        x: {
          align: 'left',
          offset: 90,
        },
        y: {
          align: 'under',
          id: 'notesTitle',
        },
      },
    },
    {
      type: 'text',
      id: 'detailsTitle',
      text: 'Report Details:',
      fontSize: 12,
      fontStyle: 'bold',
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          id: 'subtitle',
          offset: 2,
        },
      },
    },
    {
      type: 'text',
      text: 'Time Selected',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          id: 'detailsTitle',
          offset: 1,
        },
      },
    },
    {
      type: 'text',
      id: 'timesliderTime',
      text: '${timeslider_date}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          id: 'detailsTitle',
          offset: 1,
        },
      },
    },
    {
      type: 'text',
      text: 'Last Updated',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'timesliderTime',
        },
      },
    },
    {
      type: 'text',
      id: 'layerLastUpdated',
      text: `\${${layerId}_last_updated}`,
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'timesliderTime',
        },
      },
    },
    {
      type: 'text',
      text: 'Generated on',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'layerLastUpdated',
        },
      },
    },
    {
      type: 'text',
      id: 'generatedText',
      text: '${date}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'layerLastUpdated',
        },
      },
    },
    {
      type: 'text',
      text: 'Generated by',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'generatedText',
        },
      },
    },
    {
      type: 'text',
      id: 'generatedBy',
      text: '${user_name}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'generatedText',
        },
      },
    },
    {
      type: 'text',
      text: 'Attributions',
      fontSize: 10,
      width: 80,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'generatedBy',
        },
      },
    },
    {
      type: 'text',
      id: 'attributions',
      text: '${attributions}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'generatedBy',
        },
      },
    },
  ],
});

export const a4Report: ReportConfig.Root = {
  name: 'Export Map (A4)',
  summary: 'Will produce A PDF containing the current map view in the A4 size.',
  pages: [makeA4MapPage('map', 'legend', 'layer')],
  defaults: {
    paperSize: 'A4',
    paperOrientation: 'landscape',
    fontName: 'helvetica',
    header: {
      elements: [
        {
          type: 'image',
          url: '/logo-2x/default.png',
          imageType: 'png',
          location: {
            x: {
              align: 'left',
              offset: -5,
            },
            y: {
              align: 'top',
              offset: -5,
            },
          },
        },
      ],
    },
    footer: {
      elements: [
        {
          type: 'text',
          text: '${url}',
          fontSize: 8,
          location: {
            x: {
              align: 'right',
              offset: 0,
            },
            y: {
              align: 'bottom',
              offset: 5,
            },
          },
        },
      ],
    },
  },
};

export const a3Report: ReportConfig.Root = {
  name: 'Export Map (A3)',
  summary: 'Will produce A PDF containing the current map view in the A3 size.',
  pages: [makeA3MapPage('map', 'legend', 'layer')],
  defaults: {
    paperSize: 'A3',
    paperOrientation: 'landscape',
    fontName: 'helvetica',
    header: {
      elements: [
        {
          type: 'image',
          url: '/logo-2x/default.png',
          imageType: 'png',
          location: {
            x: {
              align: 'left',
              offset: -5,
            },
            y: {
              align: 'top',
              offset: -5,
            },
          },
        },
      ],
    },
    footer: {
      elements: [
        {
          type: 'text',
          text: '${url}',
          fontSize: 8,
          location: {
            x: {
              align: 'right',
              offset: 0,
            },
            y: {
              align: 'bottom',
              offset: 5,
            },
          },
        },
      ],
    },
  },
};

export const makeMultiPageA4Report = (pages: number): ReportConfig.Root => ({
  ...a4Report,
  pages: [...Array(pages)].map((_, i) => makeA4MapPage(`map${i}`, `legend${i}`, `layer${i}`)),
  name: 'Export Maps (A4)',
  summary: 'Will produce A PDF containing an A4 page for each of the maps view.',
});

export const makeMultiPageA3Report = (pages: number): ReportConfig.Root => ({
  ...a3Report,
  pages: [...Array(pages)].map((_, i) => makeA3MapPage(`map${i}`, `legend${i}`, `layer${i}`)),
  name: 'Export Maps (A3)',
  summary: 'Will produce A PDF containing an A3 page for each of map views.',
});

export const makeExtraTablePage = (id: number): ReportConfig.Page => ({
  paperOrientation: 'portrait',
  elements: [
    {
      type: 'html',
      id: `table${id}`,
      refId: `table${id}`,
      width: 200,
      location: {
        x: {
          align: 'center',
        },
        y: {
          align: 'top',
          offset: 35,
        },
      },
    },

    {
      type: 'text',
      id: 'title',
      text: '${title}',
      fontSize: 14,
      fontStyle: 'bold',
      location: {
        x: {
          align: 'center',
        },
        y: {
          align: 'top',
          offset: 3,
        },
      },
    },

    {
      type: 'text',
      id: 'detailsTitle',
      text: 'Details:',
      fontSize: 12,
      fontStyle: 'bold',
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          id: 'title',
          offset: 7,
        },
      },
    },

    {
      type: 'text',
      text: 'Last Updated',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'detailsTitle',
        },
      },
    },
    {
      type: 'text',
      id: 'layerLastUpdated',
      text: '${updated}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'detailsTitle',
        },
      },
    },

    {
      type: 'text',
      text: 'Generated on',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 0,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'layerLastUpdated',
        },
      },
    },
    {
      type: 'text',
      id: 'generatedText',
      text: '${date}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 25,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'layerLastUpdated',
        },
      },
    },

    {
      type: 'text',
      text: 'Generated by',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 100,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'detailsTitle',
        },
      },
    },
    {
      type: 'text',
      id: 'generatedBy',
      text: '${user_name}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 125,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'detailsTitle',
        },
      },
    },

    {
      type: 'text',
      text: 'Selected Date',
      fontSize: 10,
      location: {
        x: {
          align: 'left',
          offset: 100,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'generatedBy',
        },
      },
    },
    {
      type: 'text',
      id: 'selectedDate',
      text: '${selected_date}',
      fontSize: 10,
      width: 55,
      location: {
        x: {
          align: 'left',
          offset: 125,
        },
        y: {
          align: 'under',
          offset: 0,
          id: 'generatedBy',
        },
      },
    },
  ],
});

export const makeFDRTableReport = (extraPages: number): ReportConfig.Root => ({
  name: 'FDR Table Report',
  summary:
    'A multipage pdf containing a section for rendered HTML intended to be a table and with some metadata on top of each page.',
  pages: [...new Array(extraPages + 1)].map((_, i) => makeExtraTablePage(i)),
  defaults: {
    paperSize: 'A4',
    paperOrientation: 'landscape',
    fontName: 'helvetica',
    header: {
      elements: [
        {
          type: 'image',
          url: '/logo-2x/default.png',
          imageType: 'png',
          width: 35,
          location: {
            x: {
              align: 'left',
              offset: -4,
            },
            y: {
              align: 'top',
              offset: -2,
            },
          },
        },
      ],
    },
    footer: {
      elements: [
        {
          type: 'text',
          text: '${url}',
          fontSize: 8,
          location: {
            x: {
              align: 'right',
              offset: 0,
            },
            y: {
              align: 'bottom',
              offset: 5,
            },
          },
        },
      ],
    },
  },
});
