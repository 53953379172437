import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography } from '@material-ui/core';

import { MenuCard } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    menuText: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cardText: {
      fontWeight: 'bold',
      paddingBottom: theme.spacing(3),
    },
  }),
);

function FDVMenu() {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Typography variant="h4" className={classes.title}>
            Fire Danger Viewer
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container className={classes.menu}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <MenuCard title="Fire Danger Viewer" actionText="Access map view" to="/">
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Explore forecast layers
                </Typography>
              </MenuCard>
            </Grid>
            <Grid item xs={4}>
              <MenuCard title="Fire Danger Rating" actionText="Access table view" to="/ratings">
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Browse fire danger ratings
                </Typography>
              </MenuCard>
            </Grid>
            <Grid item xs={4}>
              <MenuCard title="Fire Behaviour Index" actionText="Access definitions" to="/definitions">
                <Typography variant="h6" color="textPrimary" className={classes.cardText}>
                  Understand the Index
                </Typography>
              </MenuCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default FDVMenu;
