import React, { FunctionComponent, useEffect, useState } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Divider, Theme } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { useAppSelector, useAppDispatch } from 'hooks';
import { MeteogramActions } from 'state/meteograms';

import { LayerManager, MeteogramManager } from 'models';
import { ratingToId } from 'utils';

import { ButtonTabs } from 'components';
import OverviewPanel from './Overview';
import KeyFactors from './KeyFactors';
import BigChart from './BigChart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      padding: 0,
      background: theme.palette.common.neutralXXLight,
    },
    closeContainer: {
      padding: theme.spacing(2),
      borderLeft: `1px solid ${theme.palette.common.neutralLight}`,
    },
    closeButton: {
      color: theme.palette.common.neutralDark,
    },
  }),
);

interface IProps {
  onClose: () => void;
  onResize: () => void;
  coords: number[] | null;
  selectedDate: Date | null;
  weatherStationId?: number;
  isOpen: boolean;
  identifyData: Record<string, number | string> | null;
}

const meteogramOptions = [
  'Overview',
  '',
  'Fire Danger Rating',
  'Fire Behaviour Index',
  'All meteograms',
  '',
  'Key factors',
];

const DetailsPanel: FunctionComponent<IProps> = ({
  onClose,
  onResize,
  coords,
  selectedDate,
  isOpen,
  weatherStationId,
  identifyData,
}) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const { meteograms } = useAppSelector((state) => state.meteograms);
  const { selectedMainLayer } = useAppSelector((state) => state.layers);

  const [activeTabIndex, setActiveTabIndex] = useState('Overview');

  const makeObservationData = (
    ws: MeteogramManager.Meteograms.WeatherStation | null,
    field: keyof MeteogramManager.Meteograms.WeatherStation.Observation,
  ): [Date, number][] => {
    if (ws == null) return [];
    return ws.observations.map((obs) => [new Date(obs.created), +obs[field]]);
  };

  type DatasetType = {
    data: [Date, number][];
    label: string;
    color: string;
    showLine?: boolean;
    pointRadius?: number;
  }[];

  const layersToChart: LayerManager.Layer.LayerIds[] = [
    'IDZ71000_AUS_T_SFC',
    'IDZ71018_AUS_RH_SFC',
    'IDZ71071_AUS_WindMagKmh_SFC',
    'IDZ71089_AUS_Wind_Dir_SFC',
    'IDZ71115_AUS_CHaines_SFC',
    'IDZ10134_AUS_AFDRS_fdr_SFC',
    'IDZ10135_AUS_AFDRS_fbi_SFC',
    'IDZ10138_AUS_AFDRS_ros_SFC',
    'IDZ10141_AUS_AFDRS_flame_height_SFC',
    'IDZ10142_AUS_AFDRS_spotting_distance_SFC',
    'IDZ10139_AUS_AFDRS_intensity_SFC',
  ];

  const observationMap: Partial<
    Record<LayerManager.Layer.LayerIds, keyof MeteogramManager.Meteograms.WeatherStation.Observation>
  > = {
    IDZ71000_AUS_T_SFC: 'airTemperature',
    IDZ71071_AUS_WindMagKmh_SFC: 'windSpeedKmh',
    IDZ71089_AUS_Wind_Dir_SFC: 'windDirDeg',
    IDZ71018_AUS_RH_SFC: 'relHumidity',
  };

  let chartsEarliestTime: Date | undefined;
  let chartsLatestTime: Date | undefined;
  const chartDatasets: Partial<Record<LayerManager.Layer.LayerIds, DatasetType>> = {};

  if (meteograms.status === 'finished') {
    layersToChart.forEach((layer) => {
      meteograms.object?.series[layer]?.forecast?.forEach((d) => {
        if (chartsEarliestTime == null || d[0] < chartsEarliestTime) {
          chartsEarliestTime = new Date(d[0]);
        }

        if (chartsLatestTime == null || d[0] > chartsLatestTime) {
          chartsLatestTime = new Date(d[0]);
        }
      });

      meteograms.object?.series[layer]?.hindcast?.forEach((d) => {
        if (chartsEarliestTime == null || d[0] < chartsEarliestTime) {
          chartsEarliestTime = new Date(d[0]);
        }

        if (chartsLatestTime == null || d[0] > chartsLatestTime) {
          chartsLatestTime = new Date(d[0]);
        }
      });
    });

    layersToChart.forEach((layer) => {
      chartDatasets[layer] = [
        {
          data: meteograms.object?.series[layer]?.forecast != null ? meteograms.object.series[layer].forecast : [],
          label: 'Forecast',
          color: 'black',
        },
        {
          data:
            meteograms.object?.series[layer]?.hindcast != null
              ? (meteograms.object.series[layer].hindcast as [Date, number][])
              : [],
          label: 'Old Forecast',
          color: 'darkgrey',
        },
      ];

      if (observationMap[layer] != null)
        chartDatasets[layer]?.push({
          data: meteograms.object?.weatherStation
            ? makeObservationData(
                meteograms.object.weatherStation,
                observationMap[layer] as keyof MeteogramManager.Meteograms.WeatherStation.Observation,
              )
            : [],
          label: 'Observation',
          color: 'green',
          showLine: false,
          pointRadius: 1,
        });
    });
  }

  useEffect(() => {
    if (isOpen && coords) {
      dispatch(
        MeteogramActions.getMeteograms({
          coords,
          weatherStationId,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords, isOpen]);

  const renderContent = () => {
    switch (activeTabIndex) {
      case 'Overview':
        return (
          <OverviewPanel
            selectedDate={selectedDate}
            selectedLayer={selectedMainLayer}
            coords={coords}
            identifyData={identifyData}
            chartSettings={[
              {
                name: 'Fire Danger Rating',
                units: '',
                datasets: chartDatasets.IDZ10134_AUS_AFDRS_fdr_SFC,
                yAxesLabelsCallback: (label) => ratingToId(+label),
              },
              {
                name: 'Fire Behaviour Index',
                units: '',
                datasets: chartDatasets.IDZ10135_AUS_AFDRS_fbi_SFC,
              },
              {
                name: 'Temperature',
                units: '°C',
                datasets: chartDatasets.IDZ71000_AUS_T_SFC,
              },
              {
                name: 'Wind Speed',
                units: 'km/h',
                datasets: chartDatasets.IDZ71071_AUS_WindMagKmh_SFC,
              },
              {
                name: 'Wind Direction',
                units: '°',
                datasets: chartDatasets.IDZ71089_AUS_Wind_Dir_SFC,
              },
            ]}
          />
        );
      case 'Fire Danger Rating':
        return (
          <div>
            <BigChart
              key={8}
              name="Fire Danger Rating"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ10134_AUS_AFDRS_fdr_SFC}
              yAxesLabelsCallback={(label) => ratingToId(+label)}
            />
          </div>
        );
      case 'Fire Behaviour Index':
        return (
          <div>
            <BigChart
              key={9}
              name="Fire Behaviour Index"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ10135_AUS_AFDRS_fbi_SFC}
            />
          </div>
        );
      case 'All meteograms':
        return (
          <div style={{ height: 620, overflowY: 'auto' }}>
            <BigChart
              key="IDZ71000_AUS_T_SFC"
              name="Temperature"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ71000_AUS_T_SFC}
              units="°C"
              min={chartsEarliestTime}
              max={chartsLatestTime}
            />
            <BigChart
              key="IDZ71018_AUS_RH_SFC"
              name="Relative Humidity"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ71018_AUS_RH_SFC}
              units="%"
              min={chartsEarliestTime}
              max={chartsLatestTime}
            />
            <BigChart
              key={5}
              name="Wind Speed"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ71071_AUS_WindMagKmh_SFC}
              units="km/h"
              min={chartsEarliestTime}
              max={chartsLatestTime}
            />
            <BigChart
              key={6}
              name="Wind Direction"
              color="#9B51E0"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ71089_AUS_Wind_Dir_SFC}
              units="°"
              min={chartsEarliestTime}
              max={chartsLatestTime}
            />
            <BigChart
              key={7}
              name="CHaines"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ71115_AUS_CHaines_SFC}
              min={chartsEarliestTime}
              max={chartsLatestTime}
            />
            <BigChart
              key={8}
              name="Fire Danger Rating"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ10134_AUS_AFDRS_fdr_SFC}
              min={chartsEarliestTime}
              max={chartsLatestTime}
              yAxesLabelsCallback={(label) => ratingToId(+label)}
            />
            <BigChart
              key={9}
              name="Fire Behaviour Index"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ10135_AUS_AFDRS_fbi_SFC}
              min={chartsEarliestTime}
              max={chartsLatestTime}
            />
            <BigChart
              key={10}
              name="Fire Intensity"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ10139_AUS_AFDRS_intensity_SFC}
              min={chartsEarliestTime}
              max={chartsLatestTime}
            />
            <BigChart
              key={11}
              name="Rate of Spread"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ10138_AUS_AFDRS_ros_SFC}
              min={chartsEarliestTime}
              max={chartsLatestTime}
            />
            <BigChart
              key={12}
              name="Flame height"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ10141_AUS_AFDRS_flame_height_SFC}
              min={chartsEarliestTime}
              max={chartsLatestTime}
            />
            <BigChart
              key={13}
              name="Spotting Distance"
              color="#008040"
              selectedDate={selectedDate}
              datasets={chartDatasets.IDZ10142_AUS_AFDRS_spotting_distance_SFC}
              min={chartsEarliestTime}
              max={chartsLatestTime}
            />
          </div>
        );
      case 'Key factors':
        return <KeyFactors />;
      default:
        return <>Not implemented</>;
    }
  };

  const handleTabSwitch = (value: string) => {
    setActiveTabIndex(value);

    onResize();
  };

  return (
    <>
      {renderContent()}
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flexGrow: 1 }} className={classes.buttonContainer}>
          <ButtonTabs options={meteogramOptions} onClick={handleTabSwitch} activeIndex={activeTabIndex} />
        </div>
        <div className={classes.closeContainer}>
          <Button onClick={onClose} className={classes.closeButton}>
            <Close /> Close details panel
          </Button>
        </div>
      </div>
    </>
  );
};

export default DetailsPanel;
