import React from 'react';
import DaySegment from '../function_components/DaySegments';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface GenerateDaySegmentsInterface {
  earliestTime: Date | null | undefined;
  latestTime: Date | null | undefined;
  hoursPerStep: number | null;
  startOfDay: Date | null | undefined;
  forecastDays: number[];
  timeStepOffsets: { [timeStepOffset: string]: number };
  useStyles: (props?: any) => ClassNameMap;
  hourInMs: number;
  tabWidth: number;
  tabSpacing: number;
}

function generateDaySegments({
  earliestTime,
  latestTime,
  hoursPerStep,
  startOfDay,
  forecastDays,
  timeStepOffsets,
  useStyles,
  hourInMs,
  tabWidth,
  tabSpacing,
}: GenerateDaySegmentsInterface) {
  let currDay: Date;
  const days =
    hoursPerStep != null && startOfDay != null && earliestTime != null && latestTime != null
      ? forecastDays.map((val) => {
          if (val !== 0) {
            currDay.setDate(currDay.getDate() + 1);
          } else {
            currDay = new Date(earliestTime);
            currDay.setHours(0, 0, 0, 0);
          }

          return (
            <DaySegment
              key={val}
              idx={val}
              hoursPerStep={hoursPerStep}
              earliestTime={new Date(earliestTime.getTime())}
              latestTime={new Date(latestTime.getTime())}
              day={new Date(currDay.getTime())}
              timeStepOffsets={timeStepOffsets}
              useStyles={useStyles}
              hourInMs={hourInMs}
              tabWidth={tabWidth}
              tabSpacing={tabSpacing}
            />
          );
        })
      : [];
  return days;
}

export default generateDaySegments;
