interface StopDraggingSliderInterface {
  sliderTabRef: React.RefObject<HTMLDivElement>;
  isPlayingInterval: NodeJS.Timeout | null;
}

/**
 * Handles the mouse up event and effectively stops the slider from being dragged
 * and update the timeslider state to be the final drag normalised date.
 * @param sliderTabRef A reference to the slider tag HTML element
 * @param isPlayingInterval A reference to the playing interval timeout
 * @returns The following variables (in order):
 *    - the `sliderTabRef` is NOT null flag
 *    - the `marginLeft` is NOT NaN flag
 *    - the `isPlayingInterval` is NOT null flag
 *    - and the the `marginLeft` computed value.
 */
function stopDraggingSliderEventHandler({
  sliderTabRef,
  isPlayingInterval,
}: StopDraggingSliderInterface): [boolean, boolean, boolean, number | null] {
  let sliderTabRefCurrentFlag = false;
  let marginLeftNotNaNFlag = false;
  let isPlayingIntervalFlag = false;
  let marginLeftReturnVar = null;

  if (sliderTabRef.current) {
    sliderTabRefCurrentFlag = true;
    const marginLeft = +sliderTabRef.current.style.marginLeft.substr(
      0,
      sliderTabRef.current.style.marginLeft.length - 2,
    );
    if (!Number.isNaN(marginLeft)) {
      marginLeftNotNaNFlag = true;
      marginLeftReturnVar = marginLeft;
    }
  }
  // restart interval when dragging stops
  if (isPlayingInterval != null) isPlayingIntervalFlag = true;

  return [sliderTabRefCurrentFlag, marginLeftNotNaNFlag, isPlayingIntervalFlag, marginLeftReturnVar];
}

export default stopDraggingSliderEventHandler;
