import React from 'react';
import Tab from '../function_components/Tab';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface GetActiveTabInterface {
  currentTime: Date | null | undefined;
  hoursFromStart: number | undefined;
  startOfDay: Date | null | undefined;
  forecastDays: number[] | Date[];
  hourInMs: number;
  useStyles: (props?: any) => ClassNameMap;
  tabSpacing: number;
  tabWidth: number;
}

function getActiveTab({
  currentTime,
  forecastDays,
  hourInMs,
  hoursFromStart,
  startOfDay,
  useStyles,
  tabSpacing,
  tabWidth,
}: GetActiveTabInterface) {
  const activeTab =
    currentTime != null &&
    hoursFromStart != null &&
    startOfDay != null &&
    currentTime.getTime() >= startOfDay.getTime() &&
    currentTime.getTime() < startOfDay.getTime() + forecastDays.length * 24 * hourInMs ? (
      <Tab isMainTab hours={NaN} useStyles={useStyles} tabSpacing={tabSpacing} tabWidth={tabWidth} />
    ) : undefined;

  return activeTab;
}

export default getActiveTab;
