import React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

interface TabProps {
  isDark?: boolean;
  isInactive?: boolean;
  hours: number;
  isMainTab?: boolean;
  style?: React.CSSProperties;
  useStyles: (props?: any) => ClassNameMap;
  tabWidth: number;
  tabSpacing: number;
}

/**
 * A Tab in the timeslider, is of variable length depending on the amount of time it represents.
 * Used to represent an individual time step.
 * Intended usage of variables:
 * When Dark, it represents a new 24-h interval (when not daily)
 * When Inactive (light), it represents a timestep without data.
 *    Usually occurs when padding steps from the start of the day segment to the first step with data.
 * When it represent the timestep of the selected time, it's considered the active tab.
 */
const Tab: React.FunctionComponent<TabProps> = ({
  isDark,
  hours,
  isMainTab,
  style,
  isInactive,
  useStyles,
  tabSpacing,
  tabWidth,
}) => {
  const classes = useStyles();
  if (isMainTab) return <div className={classes.tabActive} style={style} />;
  // Tab width is calculated using the base tabWidth and tabSpacing variables
  // For a single hour, it's just 1 tabwidth, however for every hour after this
  // the width must also be wide enough to cover the space that would usually
  // have been between the hourly tabs, this keeps the full day in line.
  const generalStyles = { width: hours * tabWidth + (hours - 1) * tabSpacing, ...style };

  if (isDark) return <div className={classes.tabDark} style={generalStyles} />;

  if (isInactive) return <div className={classes.tabInactive} style={generalStyles} />;

  return <div className={classes.tabNormal} style={generalStyles} />;
};

export default Tab;
