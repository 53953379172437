/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import Chart from 'chart.js';
import React, { createRef } from 'react';
import { customColors } from 'theme';

interface ChartDataset {
  data: { x: number; y: number }[];
  color?: string;
  label?: string;
  showLine?: boolean;
  pointRadius?: number;
}

interface IProps {
  datasets: ChartDataset[];
  labels: (string | number)[];
  color?: string;
  verticalLines?: { index: Date; color: string }[];
  timeFormat?: string;
  units?: string;
  min?: number;
  max?: number;
  yAxesLabelsCallback?: (
    value: string | number,
    index: number,
    values: string[] | number[],
  ) => string | number | null | undefined;
}

class ChartComponent extends React.Component<IProps> {
  chartDiv = createRef<HTMLCanvasElement>();

  chart?: Chart;

  componentDidMount() {
    // add vertical lines to the chart
    const originalLineDraw = Chart.controllers.line.prototype.draw;
    Chart.helpers.extend(Chart.controllers.line.prototype, {
      // @ts-ignore
      draw: function (...args) {
        const { chart } = this;
        const { ctx } = chart;
        const xaxis = chart.scales['x-axis-0'];
        const yaxis = chart.scales['y-axis-0'];

        (chart.config.data.verticalLines || []).forEach((line: any) => {
          const coords = xaxis.getPixelForValue(line.index);
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(coords, yaxis.top);
          ctx.strokeStyle = line.color;
          ctx.lineWidth = 2;
          ctx.lineTo(coords, yaxis.bottom);
          ctx.stroke();
          ctx.restore();
        });

        originalLineDraw.apply(this, args);
      },
    });

    this.renderChart();
  }

  componentDidUpdate(prevProps: IProps) {
    const { verticalLines } = this.props;
    let needsUpdate = false;

    if (prevProps.verticalLines && verticalLines) {
      prevProps.verticalLines?.forEach((val, idx) => {
        if (val.index !== verticalLines[idx]?.index) needsUpdate = true;
      });
    } else if (verticalLines || prevProps.verticalLines) {
      // And condition already checked, so this is effectively the XOR condition
      needsUpdate = true;
    }

    if (needsUpdate) {
      this.renderChart();
    }
  }

  componentWillUnmount() {
    if (this.chart) this.chart.destroy();
  }

  renderChart() {
    if (!this.chartDiv.current) return;

    const { datasets, color, verticalLines, timeFormat, labels, units, min, max, yAxesLabelsCallback } = this.props;

    this.chart?.destroy();

    // eslint-disable-next-line
    this.chart = new Chart(this.chartDiv.current, {
      type: 'line',
      data: {
        labels: labels,
        // @ts-ignore
        verticalLines,
        datasets: datasets.map((ds) => ({
          fill: false,
          borderColor: ds.color ?? color,
          pointBackgroundColor: ds.color ?? color,
          data: ds.data,
          label: ds.label,
          pointRadius: ds.pointRadius ?? 0,
          pointHitRadius: 4,
          lineTension: 0,
          showLine: ds.showLine ?? true,
        })),
      },
      options: {
        legend: {
          display: false,
        },
        animation: {
          duration: 0,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                min: min as unknown as string,
                max: max as unknown as string,
                displayFormats: {
                  day: timeFormat || 'D/M ddd',
                },
                unit: 'day',
                minUnit: 'day',
              },
              ticks: {
                fontColor: customColors.neutralDark,
                backdropColor: customColors.neutralLight,
              },
              gridLines: {
                color: customColors.neutralLight,
                borderDash: [2, 2],
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: false,
              },
              ticks: {
                // stepSize: 1, // Should allow the memory crash not to occur according to https://github.com/chartjs/Chart.js/issues/7063
                fontColor: customColors.neutralDark,
                backdropColor: customColors.neutralLight,
                callback: yAxesLabelsCallback ?? ((label) => `     ${+label}`.slice(-5)),
              },
              gridLines: {
                color: customColors.neutralLight,
                borderDash: [2, 2],
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              if (tooltipItem.datasetIndex == null || data.datasets == null) return '';

              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              if (tooltipItem.yLabel) label += `${(+tooltipItem.yLabel).toFixed(1)}${units ?? ''}`;

              return label;
            },
          },
        },
      },
    });
  }

  render() {
    return <canvas ref={this.chartDiv} />;
  }
}

// @ts-ignore
ChartComponent.displayName = 'ChartComponent';

export default ChartComponent;
