interface ScrollToTabInterface {
  sliderSection: React.RefObject<HTMLDivElement>;
  sliderTabRef: React.RefObject<HTMLDivElement>;
}

function scrollToTabEventHandler({ sliderSection, sliderTabRef }: ScrollToTabInterface) {
  setTimeout(() => {
    if (sliderSection.current && sliderTabRef.current) {
      const marginLeft = +sliderTabRef.current.style.marginLeft.substr(
        0,
        sliderTabRef.current.style.marginLeft.length - 2,
      );
      sliderSection.current.scrollTo({
        top: 0,
        left: marginLeft - sliderSection.current.offsetWidth / 2,
        behavior: 'smooth',
      });
    }
  }, 10);
}

export default scrollToTabEventHandler;
