/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography, Paper, Button } from '@material-ui/core';
import { ChevronLeft, ChevronRight, Close } from '@material-ui/icons';

import { MeteogramManager, Loadable } from 'models';
import { useAppDispatch } from 'hooks';
import { AnalyticsActions } from 'state/analytics';
import { toReadableAge } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 250,
      margin: theme.spacing(0.25),
      padding: theme.spacing(1),
      border: `0px`,
      backgroundColor: 'rgba(0,0,0,0.75)',
      display: 'grid',
      borderRadius: 4,
    },
    white: {
      color: theme.palette.common.white,
    },
    btn: {
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.85)',
      },
    },
    leftRight: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr auto',
    },
    title: {
      color: theme.palette.common.neutralXXLight,
      fontWeight: 'bold',
      width: '100%',
    },
    subtitle: {
      color: theme.palette.common.neutralXXLight,
      fontWeight: 'bold',
    },
    value: {
      color: theme.palette.common.neutralXLight,
      fontWeight: 'bold',
    },
  }),
);

interface WeatherStationPopupProps {
  close: () => void;
  onViewDetails?: (weatherStation: MeteogramManager.Meteograms.WeatherStation) => void;
  weatherStations?: Loadable<MeteogramManager.Meteograms.WeatherStation[]>;
}

const WeatherStationPopup: React.FunctionComponent<WeatherStationPopupProps> = ({
  close,
  weatherStations,
  onViewDetails,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [index, setIndex] = useState(0);

  const weatherStation = weatherStations?.object?.[index];

  const observation = weatherStation?.observations[0];

  const prevWeatherStation = () => {
    setIndex(index - 1 < 0 ? (weatherStations?.object?.length ?? 1) - 1 : index - 1);
  };

  const nextWeatherStation = () => {
    setIndex((index + 1) % (weatherStations?.object?.length ?? 0));
  };

  useEffect(() => {
    setIndex(0);

    if (weatherStations?.object) dispatch(AnalyticsActions.postWeatherStationView(weatherStations.object[0]));
  }, [weatherStations?.object]);

  useEffect(() => {
    if (weatherStations?.object) dispatch(AnalyticsActions.postWeatherStationView(weatherStations.object[index]));
  }, [index]);

  if (weatherStation == null)
    return (
      <Paper className={classes.root}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ChevronLeft className={classes.white} onClick={prevWeatherStation} />
          <Typography className={classes.white} variant="subtitle2">
            {index + 1} of {weatherStations?.object?.length ?? 0} Weather Stations
          </Typography>
          <ChevronRight className={classes.white} onClick={nextWeatherStation} />
        </div>
        <Typography className={classes.title} variant="subtitle1">
          No Weather Station
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button className={classes.btn} onClick={close}>
            <Close /> Close
          </Button>
          <Button className={classes.btn} disabled>
            View Details
          </Button>
        </div>
      </Paper>
    );

  return (
    <Paper className={classes.root}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ChevronLeft className={classes.white} onClick={prevWeatherStation} />
        <Typography className={classes.white} variant="subtitle2">
          {index + 1} of {weatherStations?.object?.length ?? 0} Weather Stations
        </Typography>
        <ChevronRight className={classes.white} onClick={nextWeatherStation} />
      </div>
      <Typography className={classes.title} variant="subtitle1">
        {weatherStation.name ?? 'No Name'}
      </Typography>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Last Observation:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {observation ? toReadableAge(Date.now() - observation.created.getTime()) : 'N/A'}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Station Height:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {weatherStation.height}m
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Temperature:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {observation?.airTemperature}°C
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Wind Speed:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {observation?.windSpeedKmh}km/h
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Wind Direction:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {observation?.windDir}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Relative Humidity:
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {observation?.relHumidity}%
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography className={classes.white} variant="subtitle2">
          Rainfall (since 9am):
        </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {Number.isNaN(observation?.rainfall) ? 'N/A' : `${observation?.rainfall}mm`}
        </Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button className={classes.btn} onClick={close}>
          <Close /> Close
        </Button>
        <Button className={classes.btn} onClick={() => onViewDetails && onViewDetails(weatherStation)}>
          View Details
        </Button>
      </div>
    </Paper>
  );
};

WeatherStationPopup.displayName = 'WeatherStationPopup';
export default WeatherStationPopup;
