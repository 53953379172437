/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Divider, Theme, Typography } from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'hooks';
import { LayerManager } from 'models';
import { LayerActions } from 'state/layers';

import LayerListItem from './LayerListItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
    },
    title: {
      color: theme.palette.common.neutralDark,
    },
  }),
);

interface BookmarkedLayersProps {}

const BookmarkedLayers: React.FunctionComponent<BookmarkedLayersProps> = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { auth } = useAppSelector((state) => state);
  const { bookmarkedLayers, bookmarkUpdate } = useAppSelector((state) => state.layers);

  const mainBookmarkLayers = bookmarkedLayers.object?.filter((l) => l.isMainLayer || l.isBaseMap || l.isBoundary);
  const otherBookmarkLayers = bookmarkedLayers.object?.filter((l) => !(l.isMainLayer || l.isBaseMap || l.isBoundary));

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (auth.status === 'finished') {
      if (bookmarkUpdate.status === 'finished' || bookmarkUpdate.status === 'idle')
        dispatch(LayerActions.getBookmarkedLayers());

      // regularly update layers every 2 minutes
      interval = setInterval(() => {
        if (bookmarkUpdate.status === 'idle' || bookmarkUpdate.status === 'finished')
          dispatch(LayerActions.getBookmarkedLayers());
      }, 5 * 60 * 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [bookmarkUpdate.status, auth?.status, dispatch]);

  const mapFn = (layer: LayerManager.Layer) => <LayerListItem key={layer.id} layer={layer} />;

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        Bookmarks
      </Typography>
      {otherBookmarkLayers?.map(mapFn)}
      <Divider />
      {mainBookmarkLayers?.map(mapFn)}
    </div>
  );
};

BookmarkedLayers.displayName = 'BookmarkedLayers';
export default BookmarkedLayers;
