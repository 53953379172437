import { LayerManager } from 'models';

interface SetCurrentTimeInterface {
  setStateCurrentTime: (value: React.SetStateAction<Date | null | undefined>) => void;
  date?: Date | null;
  initial?: boolean;
  layers: LayerManager.Layer[] | undefined;
  onTimeChange?: (date: Date | null | undefined, layerDates: Record<string, Date | null>, initial: boolean) => void;
}

function setCurrentTimeHelper({ setStateCurrentTime, date, initial, layers, onTimeChange }: SetCurrentTimeInterface) {
  setStateCurrentTime(date);
  const layerTimes: Record<string, Date | null> = {};
  const dateOfTimeStamp = date ? new Date(date.getTime()) : null;

  dateOfTimeStamp?.setTime(dateOfTimeStamp.getTime());

  if (date)
    layers?.forEach((layer) => {
      layerTimes[layer.id] = LayerManager.getStepTime(layers, layer.id, date);
    });

  if (onTimeChange) onTimeChange(dateOfTimeStamp, layerTimes, initial ?? false);
}

export default setCurrentTimeHelper;
