import { LayerManager } from 'models';

interface CalcTickSizeInterface {
  layers: LayerManager.Layer[] | undefined;
}

/**
 * Calculate tick size (need to get the minimum of the assigned layers)
 * @param layers
 * @returns The tick size
 */
function calcTickSizeHelper({ layers }: CalcTickSizeInterface) {
  return (
    layers?.reduce<number | null>((acc, layer) => {
      // If we get a non-time enabled layer, then ignore
      if (!layer.isTimeEnabled || layer.hoursPerStep === 0) return acc;

      // If we haven't yet initialised acc, set it to the first layer
      if (acc == null) return layer.hoursPerStep;

      // Any mixing of 24 hour layer and 1 or 3 hour layers should result in an hourly layer
      // This is because 3 hourly layers will overlap with multiple daily layers as
      // they don't start at 00.
      if ((acc === 24 && layer.hoursPerStep < 24) || (acc < 24 && layer.hoursPerStep === 24)) return 1;

      // Any mix of 3 hourly and hourly layers should result in hourly
      // If only 3 hourly layers, then it should be 3 hourly as (so far)
      // all 3 hourly layers start at a consistant time
      // (if this changes, force it to be hourly if multiple layers)
      // If all hourly layers, of course make it hourly
      return layer.hoursPerStep < acc ? layer.hoursPerStep : acc;
    }, null) ?? null
  );
}

export default calcTickSizeHelper;
