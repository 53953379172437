import React from 'react';
import { Typography } from '@material-ui/core';
import Tab from './Tab';
import DaySegmentProps from '../interfaces/daySegmentProps';

/**
 * An entire day is represented by one of these DaySegments/
 * The timeslider only displays full segments, and each segment will handle how the tabs should look.
 * Builds 24 / hoursPerStep number of tabs,
 *  so for a daily layer, there will only be 1 tab, however
 *  for an hourly layer, there will be 24 tabs, the first being 'Dark'
 *  to represent the start of the day.
 */

// Set the input date to midnight of the same day.
const setToMidnight = (date: Date) => {
  date.setHours(0, 0, 0, 0);
};

// Calculate the number of days (float) with data present
const calculateOffset = (earliestTime: Date, latestTime: Date): number => {
  const DayToMs = 1000 * 60 * 60 * 24;
  return (latestTime.getTime() - earliestTime.getTime()) / DayToMs;
};

// Use the offset to calculate the hour difference between the earliest time and
// the beginning of the day the data belongs to (may cross days due to timezone/daylight savings)
const calculateTimeDiff = (
  earliestTimeMs: number,
  midnightEarliestTimeMs: number,
  dailyOffset: number,
  stepInterval: number,
): number => {
  if (stepInterval === 24) {
    return dailyOffset < 7 ? dailyOffset - 7 : earliestTimeMs - midnightEarliestTimeMs;
  } else {
    return 0;
  }
};

// Return whether the segment in the timeslider should be darker grey for an active data segment or
// light grey if there is no data present for that time.
const isInactive = (
  hoursPerStep: number,
  dailyOffset: number,
  isAfterLatest: boolean,
  isBeforeEarliest: boolean,
): boolean => {
  // Set timeslider tab to lighter grey if timestep is outside of earliest/latest bounds.
  // Specifically handles the case where daily layers have 7 or 8 days in different timezones.
  if (hoursPerStep === 1) {
    return isBeforeEarliest || isAfterLatest;
  }
  if (hoursPerStep === 24 && dailyOffset) {
    if (dailyOffset < 8) {
      return isAfterLatest;
    }
    return isBeforeEarliest;
  } else {
    return isBeforeEarliest || isAfterLatest;
  }
};

const DaySegment: React.FunctionComponent<DaySegmentProps> = ({
  hoursPerStep,
  day,
  earliestTime,
  latestTime,
  timeStepOffsets,
  useStyles,
  hourInMs,
  tabWidth,
  tabSpacing,
}) => {
  const classes = useStyles();

  // Create midnight time/time in milliseconds based on input time
  const startOfDay = new Date(day);
  setToMidnight(startOfDay);
  const startOfDayMs = startOfDay.getTime();

  // Create midnight earliest time/time in milliseconds
  const midnightEarliestTime = new Date(earliestTime);
  setToMidnight(midnightEarliestTime);
  const midnightEarliestTimeMs = midnightEarliestTime.getTime();

  // Convert earliest/latest times into milliseconds for easy comparisons
  const earliestTimeMs = earliestTime.getTime();
  const latestTimeMs = latestTime.getTime();

  // Calculate the offset to the start of day
  const dailyOffset = calculateOffset(midnightEarliestTime, latestTime);

  // Calculate the different in time between the earliest time and the time data is stamped as
  let timeDiffMs = calculateTimeDiff(earliestTimeMs, midnightEarliestTimeMs, dailyOffset, hoursPerStep);

  // If the timeDiffMs is 0, this is not an daily layer. Return the earliest day/time
  // Else return the beginning of the day at midnight
  const trueEarliestTime = timeDiffMs === 0 ? earliestTimeMs : midnightEarliestTimeMs;

  // Calculate number of tabs required based on hoursPerStep (eg. 24/3/1 - Daily/3 Hourly/Hourly)
  const tabCount = Math.floor(24 / hoursPerStep);
  const tabs = [];

  // Calculate all the timeslider tab times for the given day and create
  // styling based off of hours per step and within data present bounds (inbetween earliest and latest data timestamps).
  for (let i = 0; i < tabCount; i += 1) {
    const defaultTabTime = startOfDayMs + hourInMs * i * hoursPerStep;
    // tabTimeMs =
    //   hoursPerStep === 24 ? defaultTabTime + timeStepOffsets.offsetFor24HourLayers * hourInMs : defaultTabTime;

    let isBeforeEarliest: boolean;
    let isAfterLatest: boolean;
    if (hoursPerStep === 24) {
      isBeforeEarliest = defaultTabTime + timeStepOffsets.offsetFor24HourLayers * hourInMs < trueEarliestTime;
      isAfterLatest = defaultTabTime + timeStepOffsets.offsetFor24HourLayers * hourInMs >= latestTimeMs - timeDiffMs;
    } else if (hoursPerStep === 1) {
      timeDiffMs = timeStepOffsets.offsetFor1HourLayers * 60 * 60 * 1000;
      isBeforeEarliest = defaultTabTime + timeDiffMs < trueEarliestTime;
      isAfterLatest = defaultTabTime > latestTimeMs;
    } else {
      timeDiffMs = timeStepOffsets.offsetFor3HourLayers * 60 * 60 * 1000;
      isBeforeEarliest = defaultTabTime < trueEarliestTime;
      isAfterLatest = defaultTabTime > latestTimeMs + (3 * 60 * 60 * 1000 - timeDiffMs);
    }

    tabs.push(
      <Tab
        key={i}
        isDark={i === 0 && hoursPerStep < 24}
        hours={hoursPerStep}
        isInactive={isInactive(hoursPerStep, dailyOffset, isAfterLatest, isBeforeEarliest)}
        useStyles={useStyles}
        tabWidth={tabWidth}
        tabSpacing={tabSpacing}
      />,
    );
  }

  const extraTabStyles: React.CSSProperties = {};

  if (hoursPerStep === 3) {
    // 3-Hourly layers are special as they may not start at 00 in the day and
    // refer to their literal range
    // Seperate to daily layers which may not start at 00 but still refer to
    // the day starting at 00.
    let offsetHours = ((earliestTimeMs - startOfDayMs) / hourInMs) % hoursPerStep;
    // If offsetHours +1 or +2, we want the first tick to start before the start
    // of the day
    if (offsetHours > 0) offsetHours -= hoursPerStep;
    extraTabStyles.transform = `translateX(${
      offsetHours * tabWidth + offsetHours * (hoursPerStep - 1) * tabSpacing
    }px)`;
  }

  return (
    <div className={classes.daySegment}>
      <div className={classes.tabsSegment} style={extraTabStyles}>
        {tabs}
      </div>
      <div className={classes.dottedLineSegment}>
        <div>
          <Typography variant="subtitle2" className={classes.dayText} align="center">
            {/* This is the 'MON 11/10' part in the background which defines the day */}
            {day.toLocaleDateString('en-AU', { weekday: 'short', day: '2-digit', month: '2-digit' }).replace(/,/g, '')}
          </Typography>
        </div>
        <div className={classes.halfLineSegment}>
          <div />
          <div className={classes.quarterDaySegment} />
          <div className={classes.quarterDaySegment} style={{ marginLeft: 1.6 }} />
          <div className={classes.quarterDaySegment} style={{ marginLeft: 3.2 }} />
        </div>
      </div>
    </div>
  );
};

export default DaySegment;
