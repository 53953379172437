interface RestartIntervalInterface {
  isPlayingInterval: NodeJS.Timeout | null;
  setisPlayingInterval: (value: React.SetStateAction<NodeJS.Timeout | null>) => void;
  intervalFunction: () => void;
  newPlaybackSpeed: number | undefined;
  playBackSpeeds: number[];
  playbackSpeedIndex: number;
  currentTime: Date | null | undefined;
}

function restartIntervalEventHandler({
  isPlayingInterval,
  setisPlayingInterval,
  intervalFunction,
  newPlaybackSpeed,
  playBackSpeeds,
  playbackSpeedIndex,
  currentTime,
}: RestartIntervalInterface) {
  if (isPlayingInterval != null) {
    clearInterval(isPlayingInterval);
  }
  if (currentTime) {
    setisPlayingInterval(
      setInterval(intervalFunction, 1000 / (newPlaybackSpeed ?? playBackSpeeds[playbackSpeedIndex])),
    );
  }
}

export default restartIntervalEventHandler;
