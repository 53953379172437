import React, { PropsWithChildren, FunctionComponent, useState } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Grid, Divider, Typography, Card, CardContent, Button } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';

import { ButtonTabs } from 'components';

import { DangerDefinitionsManager } from 'models';
import definitions from 'utils/firedangerdefinitions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      color: theme.palette.text.primary,
    },
    subTitle: {
      marginBottom: theme.spacing(2),
      color: theme.palette.common.neutralDark,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    legend: {
      borderRadius: 4,
      filter: 'drop-shadow(0px 2px 4px rgba(128, 143, 153, 0.4))',
      marginTop: theme.spacing(1),
    },
    legendItem: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      display: 'inline-block',
      textAlign: 'center',
      paddingTop: 5,
      fontWeight: 'bold',
    },
    th: {
      fontWeight: 'bold',
    },
    buttonContainer: {
      marginBottom: theme.spacing(1),
      position: 'relative',
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: 'auto 1fr',
      alignItems: 'center',
    },
    filterButton: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    card: {
      margin: theme.spacing(1),
      background: theme.palette.common.neutralXXLight,
    },
    optionsTitle: {
      marginRight: theme.spacing(1),
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
    },
    cellTitle: {
      fontWeight: 'bold',
      color: theme.palette.common.black70,
    },
    cellDivider: {
      marginBottom: theme.spacing(1),
    },
    redFlagText: {
      fontWeight: 'bold',
      color: 'red',
    },
    buttonLink: {
      textDecoration: 'underline',
      textTransform: 'unset',
      color: theme.palette.common.neutralDark,
    },
  }),
);

const getByFuelType = (fuelType: DangerDefinitionsManager.FuelTypes): DangerDefinitionsManager.Cell[] =>
  definitions.cells.filter((cell) => cell.fuelType === fuelType);

const Cell: FunctionComponent<PropsWithChildren<{ title?: string; className?: string }>> = ({
  children,
  title,
  className,
}) => {
  const classes = useStyles();

  return (
    <Card className={`${classes.card} ${className ?? ''}`}>
      <CardContent>
        {title != null && (
          <Typography variant="subtitle1" className={classes.cellTitle}>
            {title}
          </Typography>
        )}
        {title != null && <Divider className={classes.cellDivider} />}
        {children}
      </CardContent>
    </Card>
  );
};

const tabs = ['Definitions', 'Downloads and Metadata'];

const fuelTypeTabs = DangerDefinitionsManager.fuelTypes;

function FDVDefinitions() {
  const classes = useStyles();
  const theme = useTheme();

  const searchParams = new URLSearchParams(window.location.search);
  const searchedFuelType = searchParams.get('fuelType') as DangerDefinitionsManager.FuelTypes;

  const [activeTab, setTab] = useState('Definitions');
  const [fuelType, setRawFuelTypeTab] = useState<DangerDefinitionsManager.FuelTypes>(
    DangerDefinitionsManager.fuelTypes.indexOf(searchedFuelType) > -1 ? searchedFuelType : 'Forest',
  );
  const [category, setCategoryTab] = useState<DangerDefinitionsManager.Categories>('1');

  const setFuelTypeTab = (ft: DangerDefinitionsManager.FuelTypes) => {
    setRawFuelTypeTab(ft);
    if (definitions.categoryRange[ft] < +category) {
      setCategoryTab(`${definitions.categoryRange[ft]}` as DangerDefinitionsManager.Categories);
    }
  };

  const categories = [...new Array(definitions.categoryRange[fuelType])].map(
    (_, i) => `${i + 1}` as DangerDefinitionsManager.Categories,
  );
  const categoryTabs = categories.map((i) => definitions.categoryToText[fuelType][i] ?? i);

  const filteredByFuelType = getByFuelType(fuelType);

  const filteredByFuelTypeAndCategory = filteredByFuelType
    .filter((cell) => cell.category === category)
    .sort(
      (a, b) =>
        DangerDefinitionsManager.segments.indexOf(a.segment) - DangerDefinitionsManager.segments.indexOf(b.segment),
    );

  const cells: Partial<Record<DangerDefinitionsManager.Segments, DangerDefinitionsManager.Cell>> = {};
  filteredByFuelTypeAndCategory.forEach((cell) => {
    cells[cell.segment] = cell;
  });

  const calculatedStyle = makeStyles(() =>
    createStyles({
      root: {},
      addColor: {
        '& > div > div > p': {
          color: category === '1' || category === '6' ? theme.palette.common.white : theme.palette.common.black,
        },
        '& > div > h6': {
          color: category === '1' || category === '6' ? theme.palette.common.white : theme.palette.common.black,
        },
        backgroundColor: definitions.colors[fuelType][category],
      },
    }),
  )();

  const onDownload = async (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography variant="h3" className={classes.title}>
            <span style={{ color: theme.palette.common.neutralDark }}>Understand the Index</span>
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <div className={classes.buttonContainer}>
            <ButtonTabs activeIndex={activeTab} options={tabs} onClick={setTab} />
          </div>
          <div className={classes.buttonContainer}>
            <Typography variant="subtitle1" className={classes.optionsTitle}>
              Fire Spread Model:
            </Typography>
            <ButtonTabs
              activeIndex={fuelType}
              options={fuelTypeTabs}
              onClick={(tab) => {
                setFuelTypeTab(tab as DangerDefinitionsManager.FuelTypes);
              }}
            />
          </div>
          {activeTab === 'Definitions' && (
            <div>
              <div className={classes.buttonContainer}>
                <Typography variant="subtitle1" className={classes.optionsTitle}>
                  Fire Behaviour Index:
                </Typography>
                <ButtonTabs
                  activeIndex={definitions.categoryToText[fuelType][category] ?? ''}
                  options={categoryTabs}
                  colors={categories.map((tab: string) => {
                    return definitions.colors[fuelType][tab as DangerDefinitionsManager.Categories] ?? 'unset';
                  })}
                  onClick={(tab) => {
                    const selectedCat = Object.keys(definitions.categoryToText[fuelType]).find((cat) => {
                      return definitions.categoryToText[fuelType][cat as DangerDefinitionsManager.Categories] === tab;
                    });
                    if (selectedCat) setCategoryTab(selectedCat as DangerDefinitionsManager.Categories);
                  }}
                />
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: 'auto' }}>
                {filteredByFuelTypeAndCategory.map((cell) => (
                  <Cell
                    key={`${cell.fuelType}${cell.category}${cell.segment}`}
                    className={cell.segment === 'Key messages' ? calculatedStyle.addColor : undefined}
                    title={cell.segment}
                  >
                    {cell.content}
                  </Cell>
                ))}
                <Cell key="Conditions to look out for" title="Conditions to look out for">
                  {filteredByFuelTypeAndCategory.map((cell) => (
                    <React.Fragment key={cell.segment}>
                      {cell.redFlags.map((redFlag) => (
                        <Typography key={redFlag} className={classes.redFlagText} variant="subtitle1">
                          {redFlag}
                        </Typography>
                      ))}
                    </React.Fragment>
                  ))}
                </Cell>
              </div>
            </div>
          )}
          {activeTab === 'Downloads and Metadata' && (
            <div>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr', gridTemplateRows: 'auto' }}>
                <Cell title="Downloads">
                  <div>
                    <Button
                      key="buttonbooklet"
                      className={classes.buttonLink}
                      variant="text"
                      endIcon={<LaunchIcon />}
                      onClick={() => onDownload(definitions.pdfDownloadLink[fuelType])}
                    >
                      <Typography>Download the {fuelType} Fire Behaviour Index Quick Guide.</Typography>
                    </Button>
                  </div>
                </Cell>
                <Cell title="Metadata">{definitions.meta[fuelType]}</Cell>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default FDVDefinitions;
