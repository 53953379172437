interface IntervalFunctionInterface {
  currentTime: Date | null | undefined;
  latestTime: Date | null | undefined;
  earliestTime: Date | null | undefined;
  hoursPerStep: number | null;
  normaliseDate: (date: Date) => Date;
  setCurrentTime: (date?: Date | null, initial?: boolean) => void;
  setAndNormaliseCurrentTime: (date?: Date | null, initial?: boolean) => void;
  scrollToTab: () => Promise<void>;
  initialIntervalDate: Date;
  hourInMs: number;
}

function intervalFunctionEventHandler({
  currentTime,
  latestTime,
  earliestTime,
  hoursPerStep,
  normaliseDate,
  setCurrentTime,
  setAndNormaliseCurrentTime,
  scrollToTab,
  initialIntervalDate,
  hourInMs,
}: IntervalFunctionInterface) {
  if (currentTime && latestTime && earliestTime && hoursPerStep) {
    const newDate = new Date(initialIntervalDate.getTime() + hoursPerStep * hourInMs);
    if (newDate <= normaliseDate(latestTime)) {
      setCurrentTime(newDate);
      initialIntervalDate = newDate;
    } else {
      setAndNormaliseCurrentTime(earliestTime);
      initialIntervalDate = normaliseDate(earliestTime);
    }
  }

  scrollToTab();

  return initialIntervalDate;
}

export default intervalFunctionEventHandler;
