import { LayerManager } from 'models';

interface SearchEarliestTimeInterface {
  layers: LayerManager.Layer[] | undefined;
}

/**
 * Search for the earliest time from the timesteps out of all the layers provided.
 * @returns The following (in order)
 * - The earliest date.
 * - The offset keys (to be added after the helper has run)
 * - The offset values (to be added after the helper has run)
 */
function searchEarliestTimeHelper({
  layers,
}: SearchEarliestTimeInterface): [Date | null | undefined, LayerManager.Layer.LayerIds[], number[]] {
  const offsetsKeysList: LayerManager.Layer.LayerIds[] = [];
  const offsetValuesList: number[] = [];

  const result = layers?.reduce<Date | null>((acc, layer) => {
    if (!layer.isTimeEnabled || layer.timeSteps == null || layer.timeSteps.length === 0) return acc;

    const earliestForThisLayer = layer.timeSteps.reduce((earliestDateSoFar, date) =>
      earliestDateSoFar > date ? date : earliestDateSoFar,
    );
    // While we're going through each layer, the offsets for their individual earliest times are calculated.
    // This is so we know that time in the day the layer starts at, providing a nice default time.
    // For non-hourly layers (daily, 3-hourly) it's useful for getting their interval offset.
    offsetsKeysList.push(layer.id);
    offsetValuesList.push(earliestForThisLayer?.getHours() ?? 0);

    return acc == null || earliestForThisLayer < acc ? earliestForThisLayer : acc;
  }, null);

  return [result, offsetsKeysList, offsetValuesList];
}

export default searchEarliestTimeHelper;
