import React from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Typography, Paper } from '@material-ui/core';
import { LocationSearching } from '@material-ui/icons';

import { IncidentManager } from 'models';
import { toReadableAge, toTimeDDMonYearFormat } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 100,
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
    },
    leftRight: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr auto',
    },
    actions: {
      display: 'grid',
    },
    title: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
    },
    subtitle: {
      color: theme.palette.common.grey,
      fontWeight: 'bold',
    },
    value: {
      color: theme.palette.common.grey,
      fontWeight: 'bold',
    },
    zoomIcon: {
      cursor: 'pointer',
    },
  }),
);

interface IncidentListItemProps {
  incident: IncidentManager.Incident;
  onZoomToClick?: (incident: IncidentManager.Incident) => void;
}

const IncidentListItem: React.FunctionComponent<IncidentListItemProps> = ({ incident, onZoomToClick }) => {
  const classes = useStyles();
  const theme = useTheme();

  let lastUpdatedText = incident.updateDate != null ? toReadableAge(Date.now() - incident.updateDate.getTime()) : null;

  if (lastUpdatedText == null && incident.notified)
    lastUpdatedText = toReadableAge(Date.now() - incident.notified.getTime());
  if (lastUpdatedText == null) lastUpdatedText = 'N/A';

  return (
    <Paper className={classes.root}>
      <div className={classes.leftRight} style={{ marginBottom: theme.spacing(1) }}>
        <div>
          <Typography variant="subtitle1" className={classes.title}>
            {incident.title}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {incident.warningCategory}
          </Typography>
          <Typography variant="subtitle2" className={classes.subtitle}>
            Last Updated {lastUpdatedText}
          </Typography>
        </div>
        <div className={classes.actions}>
          <LocationSearching className={classes.zoomIcon} onClick={() => onZoomToClick && onZoomToClick(incident)} />
        </div>
      </div>

      <div className={classes.leftRight}>
        <Typography variant="subtitle2">Id: </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.nativeId}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography variant="subtitle2">Jurisdiction: </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.jurisdiction?.toUpperCase()}
        </Typography>
      </div>

      {incident.notified != null && (
        <div className={classes.leftRight}>
          <Typography variant="subtitle2">Created:</Typography>
          <Typography align="right" className={classes.value} variant="subtitle2">
            {toTimeDDMonYearFormat(incident.notified)}
          </Typography>
        </div>
      )}

      <div className={classes.leftRight}>
        <Typography variant="subtitle2">Type: </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.fireType}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography variant="subtitle2">Status: </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.status}
        </Typography>
      </div>
      <div className={classes.leftRight}>
        <Typography variant="subtitle2">LGA: </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.lga}
        </Typography>
      </div>
      <div>
        <Typography variant="subtitle2">Location: </Typography>
        <Typography align="right" className={classes.value} variant="subtitle2">
          {incident.locationDescription}
        </Typography>
      </div>
    </Paper>
  );
};

IncidentListItem.displayName = 'IncidentListItem';
export default IncidentListItem;
