/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router';
import { renderToStaticMarkup } from 'react-dom/server';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  Theme,
  Grid,
  Divider,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

import { PDFReport } from 'models/export';
import { makeFDRTableReport } from 'utils/reports';

import OptionButton from 'components/OptionButton';

import { ButtonTabs } from 'components';
import RatingLegend from 'components/ratings/RatingsLegend';
import RatingRow from 'components/ratings/RatingRow';
import { jurisdictions, Common, LayerManager, DangerRatingsManager, WmsManager, Loadable } from 'models';

import { useAppSelector, useAppDispatch } from 'hooks';
import { DangerRatingActions } from 'state/dangerratings';
import { LayerActions } from 'state/layers';
import { AnalyticsActions } from 'state/analytics';

import {
  toTimeDDMonYearFormat,
  toDDMonFormatAEST,
  getUserState,
  round,
  getFullName,
  ratingStringToAbbr,
  ratingStringToNumber,
} from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      color: theme.palette.text.primary,
    },
    subTitle: {
      marginBottom: theme.spacing(2),
      color: theme.palette.common.neutralDark,
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2.5),
    },
    menu: {
      marginTop: theme.spacing(5),
    },
    legend: {
      borderRadius: 4,
      filter: 'drop-shadow(0px 2px 4px rgba(128, 143, 153, 0.4))',
      marginTop: theme.spacing(1),
    },
    legendItem: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      display: 'inline-block',
      textAlign: 'center',
      paddingTop: 5,
      fontWeight: 'bold',
    },
    th: {
      fontWeight: 'bold',
    },
    buttonContainer: {
      marginBottom: theme.spacing(5),
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
    filterButton: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
  }),
);

const useStylesTable = makeStyles((theme: Theme) =>
  createStyles({
    th: {
      fontWeight: 'bold',
      fontSize: '16px',
      textAlign: 'center',
      borderBottom: `1px solid ${theme.palette.common.neutralXLight}`,
      borderRight: `1px solid ${theme.palette.common.neutralXLight}`,
    },
    smPad: {
      padding: 16,
    },
    xsPad: {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16,
    },
  }),
);

const useThinStylesTable = makeStyles((theme: Theme) =>
  createStyles({
    th: {
      fontWeight: 'bold',
      fontSize: '12px',
      textAlign: 'center',
      borderBottom: `1px solid ${theme.palette.common.neutralXLight}`,
      borderRight: `1px solid ${theme.palette.common.neutralXLight}`,
    },
    smPad: {
      padding: 12,
    },
  }),
);

const juristictionNameMap: { id: Common.Jurisdictions; name: string }[] = [
  { id: 'act', name: 'Australian Capital Territory' },
  { id: 'nsw', name: 'New South Wales' },
  { id: 'nt', name: 'Northern Territory' },
  { id: 'qld', name: 'Queensland' },
  { id: 'sa', name: 'South Australia' },
  { id: 'tas', name: 'Tasmania' },
  { id: 'vic', name: 'Victoria' },
  { id: 'wa', name: 'Western Australia' },
];

const SummaryForecastTable: React.FunctionComponent<{
  data: DangerRatingsManager.FDR.SummaryForecast[];
  legends: Record<LayerManager.Layer.LayerIds, Loadable<WmsManager.WMS.GetLegend.Legend>>;
  thin?: boolean;
  startExpanded?: boolean[];
  onExpandedListChange?: (list: boolean[]) => void;
}> = ({ data, legends, thin, startExpanded, onExpandedListChange }) => {
  const normalClasses = useStylesTable();
  const thinClasses = useThinStylesTable();
  const classes = thin ? thinClasses : normalClasses;

  const [expandedList, setExpandedListRaw] = useState(startExpanded ?? [...new Array(data.length)].map(() => false));

  const setExpandedList = (idx: number, expanded: boolean) => {
    const newList = [...expandedList];
    newList[idx] = expanded;
    setExpandedListRaw(newList);
    if (onExpandedListChange) onExpandedListChange(newList);
  };

  return (
    <TableContainer>
      <Table size={thin ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th} style={{ textAlign: 'left' }}>
              Region
            </TableCell>
            <TableCell className={classes.th}>Rating</TableCell>
            <TableCell className={classes.th}>Sub-area</TableCell>
            <TableCell className={classes.th}>Index</TableCell>
            <TableCell className={classes.th}>WCDI</TableCell>
            <TableCell className={classes.th}>cHaines index</TableCell>
            <TableCell className={classes.th}>cHaines weather alert</TableCell>
            <TableCell className={classes.th}>Thunderstorm activity level</TableCell>
            <TableCell className={classes.th}>Minor fuel flag</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => {
            return (
              <RatingRow
                key={row.name}
                legends={legends}
                thin={thin}
                initExpanded={expandedList?.[i]}
                onExpandChange={(expanded) => {
                  setExpandedList(i, expanded);
                }}
                rating={{
                  name: row.name,
                  values: [
                    {
                      value: ratingStringToNumber(row.summary.fdr) ?? '',
                      layerId: 'IDZ10136_AUS_AFDRS_max_fdr_SFC',
                      text: ratingStringToAbbr(row.summary.fdr) ?? '',
                    },
                    {
                      value: ratingStringToNumber(row.summary.fdrMaxInSubAreas) ?? '',
                      layerId: 'IDZ10136_AUS_AFDRS_max_fdr_SFC',
                      text: ratingStringToAbbr(row.summary.fdrMaxInSubAreas) ?? '',
                    },
                    {
                      value: row.summary.fbi != null ? round(row.summary.fbi) : '',
                      layerId: 'IDZ10137_AUS_AFDRS_max_fbi_SFC',
                    },
                    {
                      value: row.summary.wcdi != null ? round(row.summary.wcdi, 2) : '',
                      layerId: 'IDZ10146_AUS_AFDRS_wcdi_SFC',
                    },
                    {
                      value: row.summary.hainesIndex ?? '',
                      layerId: 'IDZ71115_AUS_CHaines_SFC', // Delayed
                    },
                    {
                      value: row.summary.hainesFlag === 'yes' ? 1 : 0,
                      layerId: 'IDZ10143_AUS_AFDRS_chaines_flag_SFC',
                      text: row.summary.hainesFlag ?? '',
                    },
                    {
                      value: row.summary.lightningActivityLevel ?? '',
                      layerId: 'IDZ71114_AUS_LAL2_SFC',
                    },
                    row.summary.minorFdrExtremeFlag ?? '',
                  ],
                  subRegions: row.summary.areas.map((area) => ({
                    name: area.name,
                    values: [
                      '',
                      {
                        value: ratingStringToNumber(area.fdr) ?? '',
                        layerId: 'IDZ10136_AUS_AFDRS_max_fdr_SFC',
                        text: ratingStringToAbbr(area.fdr) ?? '',
                      },
                      {
                        value: area.fbi != null ? round(area.fbi) : '',
                        layerId: 'IDZ10137_AUS_AFDRS_max_fbi_SFC',
                      },
                      {
                        value: area.wcdi != null ? round(area.wcdi, 2) : '',
                        layerId: 'IDZ10146_AUS_AFDRS_wcdi_SFC',
                      },
                      {
                        value: area.hainesIndex ?? '',
                        layerId: 'IDZ71115_AUS_CHaines_SFC',
                      },
                      {
                        value: area.hainesFlag === 'yes' ? 1 : 0,
                        layerId: 'IDZ10143_AUS_AFDRS_chaines_flag_SFC',
                        text: area.hainesFlag ?? '',
                      },
                      {
                        value: area.lightningActivityLevel ?? '',
                        layerId: 'IDZ71114_AUS_LAL2_SFC',
                      },
                      row.summary.minorFdrExtremeFlag ?? '',
                    ],
                  })),
                }}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DetailedForecastTable: React.FunctionComponent<{
  data: DangerRatingsManager.FDR.DetailedForecast[];
  legends: Record<LayerManager.Layer.LayerIds, Loadable<WmsManager.WMS.GetLegend.Legend>>;
}> = ({ data, legends }) => {
  const classes = useStylesTable();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.th} style={{ textAlign: 'left' }} rowSpan={2}>
              Region
            </TableCell>
            <TableCell className={classes.th} rowSpan={2}>
              FBI
            </TableCell>
            <TableCell className={classes.th} rowSpan={2}>
              Coverage
            </TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`} colSpan={3}>
              Coverage
            </TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`} colSpan={3}>
              Start hour
            </TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`} colSpan={3}>
              Hours at
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={`${classes.th} ${classes.xsPad}`}>&gt;=24</TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`}>&gt;=50</TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`}>&gt;=100</TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`}>&gt;=24</TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`}>&gt;=50</TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`}>&gt;=100</TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`}>&gt;=24</TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`}>&gt;=50</TableCell>
            <TableCell className={`${classes.th} ${classes.xsPad}`}>&gt;=100</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            const fuelTypes = Object.keys(row.data);
            const fuelTypeSortOrder = [
              'GRASSLAND',
              'WOODLAND',
              'SPINIFEX',
              'MALLEE HEATH',
              'SHRUBLAND',
              'FOREST',
              'PINE',
              'BUTTONGRASS',
              'OTHER',
              'TOTAL',
            ];

            return (
              <RatingRow
                key={row.name}
                legends={legends}
                legendColorDefault="#ffffff"
                rating={{
                  name: row.name,
                  values: [],
                  subRegions: fuelTypes
                    .sort(
                      (a, b) => fuelTypeSortOrder.indexOf(a.toUpperCase()) - fuelTypeSortOrder.indexOf(b.toUpperCase()),
                    )
                    .map((fuelType) => {
                      const d = row.data[fuelType];

                      return {
                        name: fuelType.slice(0, 1).toUpperCase() + fuelType.slice(1).replace('_', ' '),
                        values: [
                          d.fbi != null ? round(d.fbi) : '',
                          d.coverage,
                          d.coverageFbi2,
                          d.coverageFbi3,
                          d.coverageFbi4,
                          d.startTimeFbi2,
                          d.startTimeFbi3,
                          d.startTimeFbi4,
                          d.periodFbi2,
                          d.periodFbi3,
                          d.periodFbi4,
                        ],
                      };
                    }),
                }}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const WeatherForecastTable: React.FunctionComponent<{
  data: DangerRatingsManager.FDR.WeatherForecast[];
  legends: Record<LayerManager.Layer.LayerIds, Loadable<WmsManager.WMS.GetLegend.Legend>>;
  thin?: boolean;
}> = ({ data, legends, thin }) => {
  const normalClasses = useStylesTable();
  const thinClasses = useThinStylesTable();
  const classes = thin ? thinClasses : normalClasses;

  return (
    <TableContainer>
      <Table size={thin ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.th} ${classes.smPad}`} style={{ textAlign: 'left' }}>
              Region
            </TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Max temp (°C)</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Min RH (%)</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Wind dir</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Wind spd (km/h)</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Wind gust (km/h)</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Wind dir 1500m</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Wind spd 1500m (km/h)</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Rain (mm)</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>Mixing hgt (m)</TableCell>
            <TableCell className={`${classes.th} ${classes.smPad}`}>TAL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <RatingRow
              key={row.name}
              legends={legends}
              thin={thin}
              rating={{
                name: row.name,
                values: [
                  {
                    value: `${row.weather.airTemperatureMaximum50Percentile}-${row.weather.airTemperatureMaximum90Percentile}`,
                    layerId: 'IDZ71002_AUS_MaxT_SFC',
                  },
                  {
                    value: `${row.weather.relativeHumidityMinimum10Percentile}-${row.weather.relativeHumidityMinimum50Percentile}`,
                    layerId: 'IDZ71018_AUS_RH_SFC',
                  },
                  row.weather.windDirectionAtElevation1,
                  {
                    value: `${row.weather.windSpeedAtElevation150Percentile}-${row.weather.windSpeedAtElevation190Percentile}`,
                    layerId: 'IDZ71071_AUS_WindMagKmh_SFC',
                  },
                  {
                    value: `${row.weather.windGustAtElevation150Percentile}-${row.weather.windGustAtElevation190Percentile}`,
                    layerId: 'IDZ71072_AUS_WindGustKmh_SFC',
                  },
                  row.weather.windDirectionAtElevation2 ?? '',
                  {
                    value: row.weather.windSpeedAtElevation2,
                    layerId: 'IDZ71110_AUS_WindMagKmh_1500mAMSL',
                  },
                  {
                    value: `${row.weather.precipitation75Percentile}-${row.weather.precipitation25Percentile}`,
                    layerId: 'IDZ71015_AUS_DailyPrecip50Pct_SFC',
                  },
                  {
                    value: row.weather.mixHgt,
                    layerId: 'IDZ71109_AUS_MixHgt_SFC',
                  },
                  {
                    value: row.weather.lal ?? '',
                    layerId: 'IDZ71114_AUS_LAL2_SFC',
                  },
                ],
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const legendsToGet: LayerManager.Layer.LayerIds[] = [
  'IDZ10136_AUS_AFDRS_max_fdr_SFC',
  'IDZ71115_AUS_CHaines_SFC', // Delayed
  'IDZ10143_AUS_AFDRS_chaines_flag_SFC',
  'IDZ10142_AUS_AFDRS_spotting_distance_SFC',
  'IDZ10137_AUS_AFDRS_max_fbi_SFC',
  'IDZ71002_AUS_MaxT_SFC',
  'IDZ71015_AUS_DailyPrecip50Pct_SFC',
  'IDZ71071_AUS_WindMagKmh_SFC',
  'IDZ71110_AUS_WindMagKmh_1500mAMSL',
  'IDZ71072_AUS_WindGustKmh_SFC',
  'IDZ71018_AUS_RH_SFC',
  'IDZ71109_AUS_MixHgt_SFC',
  'IDZ71114_AUS_LAL2_SFC',
  'IDZ10146_AUS_AFDRS_wcdi_SFC',
];

const rowsPerPage = 21;

function FDVRatings() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { auth } = useAppSelector((state) => state);
  const { detailed, summary, weather } = useAppSelector((state) => state.dangerRatings);
  const { legends } = useAppSelector((state) => state.layers);

  const searchParams = new URLSearchParams(window.location.search);
  const [filterText, setFilterText] = useState(searchParams.get('search') ?? '');
  const [selectedDay, setSelectedDay] = useState(0);

  const [expandedList, setExpandedList] = useState<boolean[]>();

  const dataMapping = {
    'Summary forecast': summary.object,
    'Detailed forecast': detailed.object,
    'Weather forecast': weather.object,
  };

  const tabs: (keyof typeof dataMapping)[] = ['Summary forecast', 'Detailed forecast', 'Weather forecast'];

  const [generating, setGenerating] = useState(false);

  const [activeTab, setActiveTab] = useState<keyof typeof dataMapping>('Summary forecast');

  const dirtySelectedJurisdiction = useParams<{ stateId?: string }>()?.stateId ?? null;
  const selectedJurisdiction = (
    dirtySelectedJurisdiction && jurisdictions.indexOf(dirtySelectedJurisdiction as Common.Jurisdictions) > -1
      ? dirtySelectedJurisdiction
      : null
  ) as Common.Jurisdictions | null;

  const selectedJurisdictionName =
    (selectedJurisdiction && juristictionNameMap.find((x) => x.id === selectedJurisdiction))?.name ?? null;

  const reverseDayMap: Record<string, number> = {};
  const days: string[] = [];

  if (selectedJurisdiction) {
    summary.object?.forecasts[selectedJurisdiction].forEach((day, idx) => {
      const formattedDay = toDDMonFormatAEST(day.forecastStart);
      days.push(formattedDay);
      reverseDayMap[formattedDay] = idx;
    });
  }

  const selectedData = dataMapping[activeTab];

  const selectedForecast =
    (selectedJurisdiction && selectedData?.forecasts[selectedJurisdiction]?.[selectedDay]?.data) ?? null;

  const firstIssueTimeIndex = selectedForecast?.findIndex((el: any) => el.issueTime != null) ?? -1;
  const selectedForecastIssueTime = firstIssueTimeIndex > -1 ? selectedForecast?.[firstIssueTimeIndex].issueTime : '';

  const filteredForecast =
    // @ts-ignore Issue about the 3 selectedForecast types not being compatible within filter but they all extend Area?
    (selectedForecast?.filter(
      (row: DangerRatingsManager.FDR.Area) =>
        !filterText || row.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1,
    ) ?? null) as typeof selectedForecast;

  const dispatchGetLegend = (id: LayerManager.Layer.LayerIds) => {
    const layer = LayerManager.findLayer(id, LayerManager.layerData) as LayerManager.Layer | undefined;
    if (layer)
      dispatch(
        LayerActions.getLegend({
          layer,
        }),
      );
  };

  useEffect(() => {
    if (auth.status === 'finished') {
      legendsToGet.forEach((id) => {
        if (legends[id].status === 'idle') {
          dispatchGetLegend(id);
        }
      });
    }
  }, [auth.status]);

  useEffect(() => {
    if (selectedJurisdiction == null) {
      let initialJurisdiction = getUserState(auth.object);
      if (initialJurisdiction == null) initialJurisdiction = 'nsw';

      navigate(`/ratings/${initialJurisdiction}${window.location.search}`, {
        replace: true,
      });
    }
  });

  useEffect(() => {
    let interval: any;
    if (auth.status === 'finished') {
      if (detailed.status === 'idle') dispatch(DangerRatingActions.getDetailedForecast());
      if (summary.status === 'idle') dispatch(DangerRatingActions.getSummaryForecast());
      if (weather.status === 'idle') dispatch(DangerRatingActions.getWeatherForecast());

      interval = setInterval(() => {
        // every 5 minutes re-get the data
        if (detailed.status === 'idle' || detailed.status === 'finished')
          dispatch(DangerRatingActions.getDetailedForecast());
        if (summary.status === 'idle' || summary.status === 'finished')
          dispatch(DangerRatingActions.getSummaryForecast());
        if (weather.status === 'idle' || weather.status === 'finished')
          dispatch(DangerRatingActions.getWeatherForecast());
      }, 5 * 60 * 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [weather.status, summary.status, detailed.status, auth.status]);

  const handleSelectState = (index: number, value: string) => {
    const newState = juristictionNameMap.find((x) => x.name === value);
    if (!newState) return;
    navigate(`/ratings/${newState.id}`);
  };

  const handleSummaryDownload = () => {
    if (summary.object && filteredForecast && selectedJurisdiction) {
      setGenerating(true);
      document.body.style.overflow = 'hidden';

      const rowsPerRecord = (filteredForecast as DangerRatingsManager.FDR.SummaryForecast[]).map(
        (item, idx) => 1 + +(expandedList?.[idx] || 0) * item.summary.areas.length,
      );

      const calculateEndIndex = (start: number): number => {
        let idx = start + 1;
        let total = rowsPerRecord[start];
        while (rowsPerRecord[idx] != null && total + rowsPerRecord[idx] <= rowsPerPage) {
          total += rowsPerRecord[idx];
          idx += 1;
        }
        return idx;
      };

      const slicesPerPage: [number, number][] = [];

      const calculateSlice = (page: number): [number, number] => {
        if (page < 0) return [0, 0];

        if (page === 0) {
          slicesPerPage[0] = [0, calculateEndIndex(0)];
          return slicesPerPage[0];
        }

        const startingPoint = slicesPerPage[page - 1][1];
        slicesPerPage[page] = [startingPoint, calculateEndIndex(startingPoint)];

        return slicesPerPage[page];
      };

      const extraPages = Math.ceil(rowsPerRecord.reduce((acc, cur) => acc + cur) / rowsPerPage) - 1;

      const report = new PDFReport(makeFDRTableReport(extraPages));

      const dateOptions = {
        weekday: 'short',
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        // @ts-ignore
        hourCycle: 'h23',
      } as any;

      report.setVariable('title', 'Fire Danger Ratings: Summary Report');
      report.setVariable(
        'selected_date',
        summary.object.forecasts[selectedJurisdiction][selectedDay].forecastStart
          .toLocaleDateString('en-AU', {
            weekday: 'short',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          .replace(/,/g, ''),
      );
      report.setVariable('updated', summary.object.updated.toLocaleDateString('en-AU', dateOptions).replace(/,/g, ''));

      report.setVariable('url', window.location.host);

      report.setVariable(
        'user_name',
        getFullName({
          familyName: auth.object?.decoded_token?.family_name,
          firstName: auth.object?.decoded_token?.given_name,
          userName: auth.object?.decoded_token?.['cognito:username'],
        }),
      );
      report.setVariable('user_email', auth.object?.decoded_token?.email);

      report.setCallback('addHTML', (id) => {
        const matchTableId = /^table(\d)$/.exec(id);
        if (matchTableId && !Number.isNaN(+matchTableId[1])) {
          const pageNum = +matchTableId[1];

          const slice = calculateSlice(pageNum);
          const segment = filteredForecast?.slice(...slice) ?? null;
          const slicedExpandedList = expandedList?.slice(...slice);

          const renderResult = renderToStaticMarkup(
            <SummaryForecastTable
              data={segment as DangerRatingsManager.FDR.SummaryForecast[]}
              legends={legends}
              startExpanded={slicedExpandedList}
              thin
            />,
          );

          return renderResult;
        }

        return null;
      });

      report
        .generateReport()
        .then((doc) => {
          if (doc) {
            doc.save(`summary.pdf`);
            dispatch(AnalyticsActions.postGenerateReport('Fire Danger Ratings Summary Report'));
          } else {
            console.error('Unable to Generate report');
            dispatch(
              AnalyticsActions.postGenerateReportError(
                'Fire Danger Ratings Summary Report',
                'Unable to Generate report',
              ),
            );
          }
          document.body.style.overflow = 'unset';
          setGenerating(false);
        })
        .catch((e) => {
          document.body.style.overflow = 'unset';
          setGenerating(false);
          console.error('Error when generating report');
          console.error(e);

          dispatch(AnalyticsActions.postGenerateReportError('Fire Danger Ratings Summary Report', JSON.stringify(e)));
        });
    }
  };

  const handleWeatherDownload = () => {
    if (weather.object && filteredForecast && selectedJurisdiction) {
      setGenerating(true);
      document.body.style.overflow = 'hidden';

      const extraPages = Math.ceil(filteredForecast.length / rowsPerPage) - 1;

      const report = new PDFReport(makeFDRTableReport(extraPages));

      const dateOptions = {
        weekday: 'short',
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        // @ts-ignore
        hourCycle: 'h23',
      } as any;

      report.setVariable('title', 'Fire Danger Ratings: Weather Report');
      report.setVariable(
        'selected_date',
        weather.object.forecasts[selectedJurisdiction][selectedDay].forecastStart
          .toLocaleDateString('en-AU', {
            weekday: 'short',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          .replace(/,/g, ''),
      );
      report.setVariable('updated', weather.object.updated.toLocaleDateString('en-AU', dateOptions).replace(/,/g, ''));

      report.setVariable('url', window.location.host);

      report.setVariable(
        'user_name',
        getFullName({
          familyName: auth.object?.decoded_token?.family_name,
          firstName: auth.object?.decoded_token?.given_name,
          userName: auth.object?.decoded_token?.['cognito:username'],
        }),
      );
      report.setVariable('user_email', auth.object?.decoded_token?.email);

      report.setCallback('addHTML', (id) => {
        const matchTableId = /^table(\d)$/.exec(id);
        if (matchTableId && !Number.isNaN(+matchTableId[1])) {
          const segment =
            filteredForecast?.slice(+matchTableId[1] * rowsPerPage, (+matchTableId[1] + 1) * rowsPerPage) ?? null;
          const renderResult = renderToStaticMarkup(
            <WeatherForecastTable
              data={segment as DangerRatingsManager.FDR.WeatherForecast[]}
              legends={legends}
              thin
            />,
          );

          return renderResult;
        }

        return null;
      });

      report
        .generateReport()
        .then((doc) => {
          if (doc) {
            doc.save(`weather.pdf`);
            dispatch(AnalyticsActions.postGenerateReport('Fire Danger Ratings Weather Report'));
          } else {
            console.error('Unable to Generate report');
            dispatch(
              AnalyticsActions.postGenerateReportError(
                'Fire Danger Ratings Weather Report',
                'Unable to Generate report',
              ),
            );
          }
          document.body.style.overflow = 'unset';
          setGenerating(false);
        })
        .catch((e) => {
          document.body.style.overflow = 'unset';
          setGenerating(false);
          console.error('Error when generating report');
          console.error(e);

          dispatch(AnalyticsActions.postGenerateReportError('Fire Danger Ratings Weather Report', JSON.stringify(e)));
        });
    }
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <RatingLegend />
          <Typography variant="h4" className={classes.title}>
            <span style={{ color: theme.palette.common.neutralDark }}>Fire danger rating /</span>{' '}
            {selectedJurisdictionName ?? 'No jurisdiciton selected'}
            <OptionButton
              ariaLabel="switch jurisdictions"
              options={juristictionNameMap.map((x) => x.name)}
              onChange={handleSelectState}
            />
          </Typography>
          <Typography className={classes.subTitle}>
            Issued at {selectedForecastIssueTime && toTimeDDMonYearFormat(selectedForecastIssueTime)}
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <div className={classes.buttonContainer} style={{ marginBottom: theme.spacing(0.5) }}>
            <ButtonTabs
              activeIndex={activeTab}
              options={tabs}
              onClick={(option) => {
                setActiveTab(option as keyof typeof dataMapping);
              }}
            />
            <TextField
              value={filterText}
              size="small"
              onChange={(event: any) => setFilterText(event?.target?.value)}
              variant="outlined"
              placeholder="Filter Regions"
            />
          </div>
          <div className={classes.buttonContainer}>
            <ButtonTabs
              activeIndex={days[selectedDay]}
              options={days}
              onClick={(option) => {
                setSelectedDay(reverseDayMap[option]);
              }}
            />

            {activeTab === 'Summary forecast' && filteredForecast != null && (
              <Button onClick={handleSummaryDownload}>
                {generating && (
                  <CircularProgress style={{ width: 16, height: 16, margin: '0px 8px' }} aria-valuetext="loading" />
                )}{' '}
                Download Summary as PDF <GetApp />
              </Button>
            )}
            {activeTab === 'Weather forecast' && filteredForecast != null && (
              <Button onClick={handleWeatherDownload}>
                {generating && (
                  <CircularProgress style={{ width: 16, height: 16, margin: '0px 8px' }} aria-valuetext="loading" />
                )}{' '}
                Download Weather as PDF <GetApp />
              </Button>
            )}
          </div>
          {activeTab === 'Summary forecast' && filteredForecast != null && (
            <SummaryForecastTable
              data={filteredForecast as DangerRatingsManager.FDR.SummaryForecast[]}
              legends={legends}
              onExpandedListChange={(list) => {
                setExpandedList(list);
              }}
            />
          )}
          {activeTab === 'Detailed forecast' && filteredForecast != null && (
            <DetailedForecastTable
              data={filteredForecast as DangerRatingsManager.FDR.DetailedForecast[]}
              legends={legends}
            />
          )}
          {activeTab === 'Weather forecast' && filteredForecast != null && (
            <WeatherForecastTable
              data={filteredForecast as DangerRatingsManager.FDR.WeatherForecast[]}
              legends={legends}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default FDVRatings;
