// import definitions from 'utils/firedangerdefinitions';

export enum FuelTypesEnum {
  'Forest',
  'Grass',
  'Spinifex',
  'Savanna',
  'Shrubland',
  'Mallee-heath',
  'Pine',
  'Buttongrass',
}

export enum SegmentEnum {
  'Key messages',
  'Intensities & thresholds',
  'Indicative fire behaviour & fire weather',
  'Fire suppression & containment',
  'Consequences',
  'Prescribed burn implications',
  'References',
}

export type FuelTypes = keyof typeof FuelTypesEnum;

export type Categories = '1' | '2' | '3' | '4' | '5' | '6';

export type Segments = keyof typeof SegmentEnum;

export interface Cell {
  category: Categories;
  content: JSX.Element;
  fuelType: FuelTypes;
  redFlags: string[];
  segment: Segments;
}

export interface FireDangerDefinitions {
  categoryNameAFAC: Record<FuelTypes, Partial<Record<Categories, Categories>>>;
  categoryRange: Record<FuelTypes, number>;
  categoryToText: Record<FuelTypes, Partial<Record<Categories, string>>>;
  cells: Cell[];
  colors: Record<FuelTypes, Partial<Record<Categories, string>>>;
  meta: Record<FuelTypes, JSX.Element>;
  pdfDownloadLink: Record<FuelTypes, string>;
}

export const fuelTypes = Object.keys(FuelTypesEnum).filter((x) => Number.isNaN(+x)) as FuelTypes[];

export const categories = new Array(6).map((_, i) => `${i + 1}`) as Categories[];

export const segments = Object.keys(SegmentEnum).filter((x) => Number.isNaN(+x)) as Segments[];

// export const getByFuelType = (fuelType: FuelTypes): Cell[] =>
//   definitions.cells.filter((cell) => cell.fuelType === fuelType);
