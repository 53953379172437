import React, { useRef, useState } from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Button, Theme, Typography, CircularProgress, TextField } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { PrettierTextArea } from 'components';
import { LayerManager } from 'models';
import { useAppSelector, useAppDispatch } from 'hooks';

import { PDFReport, ReportConfig } from 'models/export';
import { LayerActions } from 'state/layers';
import { AnalyticsActions } from 'state/analytics';
import { makeMultiPageA3Report, makeMultiPageA4Report } from 'utils/reports';
import { getFullName } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(0),
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
    },
    title: {
      color: theme.palette.common.neutralDark,
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      color: theme.palette.common.neutralDark,
    },
    twocolumns: {},
    groupTitle: {
      color: theme.palette.common.grey,
      fontWeight: 'bold',
    },
    groupSection: {
      margin: `${theme.spacing(2)}px 0px`,
    },
    icon: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
    },
    closeIcon: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      cursor: 'pointer',
    },
    filterSection: {
      margin: theme.spacing(1),
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '1fr auto auto',
    },
    filterSectionVertical: {
      margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      display: 'grid',
      gridAutoFlow: 'row',
      gridTemplateRows: '1fr auto auto',
    },
    sidebarBtn: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      border: `1px solid ${theme.palette.common.neutralLight}`,
      marginBottom: 2,
      borderRadius: 8,
      color: theme.palette.common.black70,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
      },
    },
    textArea: {
      width: '100%',
      height: 300,
      border: 'none',
      padding: theme.spacing(2),
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      color: '#757575',
      fontSize: '16px',
      resize: 'none',
      outline: 0,
    },
  }),
);

interface ComparisonExportManagerProps {
  mapRefs?: React.RefObject<any>[];
  selectedDate?: Date | null;
  viewCount: number;
}

const ComparisonExportManager: React.FunctionComponent<ComparisonExportManagerProps> = ({
  mapRefs,
  selectedDate,
  viewCount,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { auth } = useAppSelector((state) => state);
  const { layers, selectedLayers, selectedCompareLayers, selectedBaseMap } = useAppSelector((state) => state.layers);

  const reports = [makeMultiPageA4Report(viewCount), makeMultiPageA3Report(viewCount)];

  const compareLayers = selectedCompareLayers.map((l) => (l != null ? LayerManager.findLayer(l, layers.object) : null));

  const incidentsEnabled = selectedLayers && selectedLayers.indexOf('Incidents') > -1;

  const incidentLayer = LayerManager.findLayer('Incidents', layers.object);

  const weatherStationsEnabled = selectedLayers && selectedLayers.indexOf('WeatherStations') > -1;

  const weatherStationsLayer = LayerManager.findLayer('WeatherStations', layers.object);

  const testRef = useRef(null);

  const [title, setTitle] = useState('My Report');

  const [notes, setNotes] = useState('');

  const [selectedReportId, setSelectedReportId] = useState<string | null>(reports[0].name);

  const selectedReport: ReportConfig.Root | null = reports.find((report) => report.name === selectedReportId) ?? null;

  const [generating, setGenerating] = useState(false);

  const handleDownload = () => {
    if (selectedReport) {
      const report = new PDFReport(selectedReport);
      setGenerating(true);

      report.setCallback('getMapId', (id) => {
        const mapIdRegex = /^map(\d+)$/g;
        const res = mapIdRegex.exec(id);

        return (res?.[1] && mapRefs?.[+res[1]]?.current) ?? null;
      });

      report.setCallback('getImageData', async (id: string) => {
        const legendIdRegex = /^legend(\d+)$/g;
        const legendId = legendIdRegex.exec(id);

        if (legendId?.[1] != null && compareLayers[+legendId[1]] != null) {
          return dispatch(LayerActions.getLegendImage({ layer: compareLayers[+legendId[1]] as LayerManager.Layer }));
        }

        if (incidentsEnabled && incidentLayer && id === 'incidentLegend') {
          return dispatch(LayerActions.getLegendImage({ layer: incidentLayer }));
        }

        if (weatherStationsEnabled && weatherStationsLayer && id === 'weatherStationLegend') {
          return dispatch(LayerActions.getLegendImage({ layer: weatherStationsLayer }));
        }

        return null;
      });

      const dateSettings = {
        weekday: 'short',
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        // @ts-ignore
        hourCycle: 'h23',
      };

      const dateSmallSettings = {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        // @ts-ignore
        hourCycle: 'h23',
      };

      const attributions: string[] = [];
      if (compareLayers) attributions.push('BoM ADFD');

      // Full atribution if enough space is "© OpenStreetMap contributors". National Basemap also uses OSM
      if (selectedBaseMap && (selectedBaseMap === 'OSM' || selectedBaseMap === 'NationalBaseMap'))
        attributions.push('© OpenStreetMap');

      if (selectedBaseMap && (selectedBaseMap === 'NationalBaseMap' || selectedBaseMap === 'World_Bathymetry_Imagery'))
        attributions.push('Geoscience Australia');

      report.setVariable('title', title);
      report.setVariable('url', window.location.host);
      report.setVariable(
        'timeslider_date',
        selectedDate
          ? selectedDate.toLocaleDateString('en-AU', dateSettings as any).replace(/,/g, '')
          : 'No Date Selected',
      );
      report.setVariable(
        'timeslider_date_small',
        selectedDate ? selectedDate.toLocaleDateString('en-AU', dateSmallSettings as any).replace(/,/g, '') : 'N/A',
      );

      report.setVariable('attributions', attributions.join(', '));

      [...Array(viewCount)].forEach((_, i) => {
        const layer = compareLayers[i];
        report.setVariable(`layer${i}_name`, layer?.name ?? 'No Layer Selected');
        report.setVariable(`layer${i}_summary`, layer?.summary ?? '');
        report.setVariable(`layer${i}_units`, layer?.units ?? '');
        report.setVariable(`layer${i}_units_bracket`, layer?.units ? `(${layer.units})` : '');
        report.setVariable(
          `layer${i}_last_updated`,
          layer?.lastUpdated
            ? new Date(layer.lastUpdated).toLocaleDateString('en-AU', dateSettings as any).replace(/,/g, '')
            : 'N/A',
        );
      });

      report.setVariable(
        'user_name',
        getFullName({
          familyName: auth.object?.decoded_token?.family_name,
          firstName: auth.object?.decoded_token?.given_name,
          userName: auth.object?.decoded_token?.['cognito:username'],
        }),
      );
      report.setVariable('user_email', auth.object?.decoded_token?.email);
      report.setVariable('notes', notes);

      report
        .generateReport()
        .then((doc) => {
          if (doc) {
            doc.save(`${title ?? 'my_report'}.pdf`);
            dispatch(AnalyticsActions.postGenerateReport(selectedReport.name));
          } else {
            console.error('Unable to Generate report');
            dispatch(AnalyticsActions.postGenerateReportError(selectedReport.name, 'Unable to Generate report'));
          }
          setGenerating(false);
        })
        .catch((e) => {
          setGenerating(false);
          console.error('Error when generating report');
          console.error(e);

          dispatch(AnalyticsActions.postGenerateReportError(selectedReport.name, JSON.stringify(e)));
        });
    }
  };

  return (
    <div ref={testRef} className={classes.root}>
      <div
        style={{ display: 'grid', gridAutoFlow: 'column', gridTemplateColumns: '1fr auto', margin: theme.spacing(1) }}
      >
        <div />
        {generating && <CircularProgress style={{ margin: theme.spacing(1) }} size={20} aria-valuetext="loading" />}
      </div>
      <div style={{ display: 'grid', gridTemplateRows: '1fr auto', overflowY: 'hidden' }}>
        <div>
          <div className={classes.filterSectionVertical}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Title:
            </Typography>
            <TextField
              value={title}
              onChange={(event: any) => setTitle(event?.target?.value)}
              variant="outlined"
              placeholder="Report Title"
            />
          </div>

          <div className={classes.filterSectionVertical}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {selectedReport ? selectedReport.summary : 'No report selected'}
            </Typography>
          </div>

          <div className={classes.filterSectionVertical}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Reports:
            </Typography>
            <ToggleButtonGroup
              value={selectedReportId}
              orientation="vertical"
              exclusive
              onChange={(_, value) => {
                setSelectedReportId(value as string | null);
              }}
              aria-label="select a report to download"
            >
              {reports.map((report) => (
                <ToggleButton key={report.name} value={report.name} aria-label={`view ${report.name}`}>
                  {report.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>

          <div className={classes.filterSectionVertical}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Notes:
            </Typography>
            <PrettierTextArea
              placeholder="Short note for the report..."
              onChange={(e) => setNotes(e.target.value)}
              minRows={5}
              maxRows={15}
              value={notes}
              className={classes.textArea}
            />
          </div>
        </div>

        <Button className={classes.sidebarBtn} disabled={!selectedReport} onClick={handleDownload}>
          <GetApp /> Download PDF
        </Button>
      </div>
    </div>
  );
};

ComparisonExportManager.displayName = 'ComparisonExportManager';
export default ComparisonExportManager;
