import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import { useAppSelector, useAppDispatch } from 'hooks';
import { handleLogin } from 'state';
import { AnalyticsActions } from 'state/analytics';
import { refreshToken } from 'state/auth';
import {
  FDVMenu,
  FDVViewer,
  FDVRatings,
  FDVDefinitions,
  HelpMenu,
  HelpDetails,
  HelpAddEdit,
  AdminEditUser,
  AdminListUsers,
  AdminNewUser,
  SelfEditMyDetails,
  DownloadFile,
} from 'pages';
import { NavBar, Error404 } from 'components';
import { Theme } from '@material-ui/core';
import FDVMapCompare from 'pages/FDVMapCompare';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      padding: theme.spacing(1),
      color: theme.palette.text.primary,
      width: '100%',
    },
    root: {
      height: '100vh',
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
    },
    fixScroll: {
      overflow: 'hidden',
    },
    error404: {
      margin: theme.spacing(2),
    },
  }),
);

function FireDangerViewer() {
  const auth = useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);
  const [loginAttempted, setLoginAttempted] = useState(auth?.object?.creds != null);

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const naviagte = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (auth.status === 'finished') dispatch(AnalyticsActions.postPageView(location.pathname));
  }, [location, auth.status]);

  useEffect(() => {
    // Update the document title using the browser API
    document.title = `AFDRS Fire Danger Viewer`;

    handleLogin(dispatch, auth, user, naviagte);
    setLoginAttempted(true);

    const interval = setInterval(() => {
      if (auth.object) dispatch(refreshToken({ auth: auth.object }));
    }, 18 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, auth, user, naviagte]);

  return loginAttempted ? (
    <div className={classes.root}>
      <NavBar menuLocation="/menu" />
      <Routes>
        {/* Common/Help Pages */}
        <Route path="/download" element={<DownloadFile />} />
        <Route path="/help/edit/:helpItem?" element={<HelpAddEdit />} />
        <Route path="/help/:helpItem" element={<HelpDetails />} />
        <Route path="/help" element={<HelpMenu />} />

        {/* FDV Specici Pages */}
        <Route
          path="/menu"
          element={
            <div>
              <FDVMenu />
            </div>
          }
        />

        <Route path="/compare" element={<FDVMapCompare />} />

        <Route path="/ratings/:stateId?" element={<FDVRatings />} />

        <Route path="/definitions" element={<FDVDefinitions />} />

        <Route
          path="/"
          element={
            <div className={classes.fixScroll}>
              <FDVViewer />
            </div>
          }
        />

        {/* Admin/User Manager Pages */}
        <Route
          path="/admin/users/new"
          element={
            <div>
              <AdminNewUser />
            </div>
          }
        />

        <Route
          path="/admin/users/:userId"
          element={
            <div>
              <AdminEditUser />
            </div>
          }
        />

        <Route path="/self/mydetails" element={<SelfEditMyDetails />} />

        <Route
          path="/admin/users"
          element={
            <div>
              <AdminListUsers />
            </div>
          }
        />
        {/* Catch-All Error 404 */}
        <Route
          element={
            <div className={classes.error404}>
              <Error404 link="/menu" />
            </div>
          }
        />
      </Routes>
    </div>
  ) : (
    <></>
  );
}

export default FireDangerViewer;
