import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';

import { DangerDefinitionsManager } from 'models';

const Text = withStyles((theme: Theme) => ({
  root: {
    fontSize: 14,
    color: theme.palette.common.black70,
  },
}))(Typography);

const BoldText = withStyles((theme: Theme) => ({
  root: {
    fontSize: 14,
    color: theme.palette.common.black70,
    fontWeight: 'bold',
  },
}))(Typography);

const basicGridStyle: React.CSSProperties = {
  display: 'grid',
  gap: '8px',
  gridTemplateColumns: 'minmax(80px, 1fr) 1fr',
};

const basicGridStyleSmall: React.CSSProperties = {
  display: 'grid',
  gap: '8px',
  gridTemplateColumns: '180px 1fr',
};

const span2Columns: React.CSSProperties = { gridColumnEnd: 'span 2' };

const fireDangerDefinitions: DangerDefinitionsManager.FireDangerDefinitions = {
  pdfDownloadLink: {
    Buttongrass:
      'https://www.afac.com.au/docs/default-source/afdrs/afdrs-quick-guide---buttongrass.pdf?sfvrsn=4&download=true',
    Forest: 'https://www.afac.com.au/docs/default-source/afdrs/afdrs-quick-guide---forest.pdf?sfvrsn=4&download=true',
    Grass: 'https://www.afac.com.au/docs/default-source/afdrs/afdrs-quick-guide---grassland.pdf?sfvrsn=4&download=true',
    'Mallee-heath':
      'https://www.afac.com.au/docs/default-source/afdrs/afdrs-quick-guide---mallee.pdf?sfvrsn=4&download=true',
    Pine: 'https://www.afac.com.au/docs/default-source/afdrs/afdrs-quick-guide---pine.pdf?sfvrsn=4&download=true',
    Savanna: 'https://www.afac.com.au/docs/default-source/afdrs/afdrs_quick_guide---savanna.pdf?sfvrsn=4&download=true',
    Shrubland:
      'https://www.afac.com.au/docs/default-source/afdrs/afdrs_quick_guide---shrubland.pdf?sfvrsn=4&download=true',
    Spinifex:
      'https://www.afac.com.au/docs/default-source/afdrs/afdrs-quick-guide---spinifex.pdf?sfvrsn=4&download=true',
  },
  colors: {
    Forest: {
      '1': '#542788', // Dark Purple
      '2': '#9998C3', // Light Purple
      '3': '#D8DAEB', // XLight Purple
      '4': '#FCE2AC', // Yellow
      '5': '#EF8A62', // Orange
      '6': '#B2182B', // Red
    },
    Grass: {
      '1': '#542788', // Dark Purple
      '2': '#9998C3', // Light Purple
      '3': '#D8DAEB', // XLight Purple
      '4': '#FCE2AC', // Yellow
      '5': '#EF8A62', // Orange
      '6': '#B2182B', // Red
    },
    Spinifex: {
      '1': '#542788', // Dark Purple
      '2': '#9998C3', // Light Purple
      '3': '#FCE2AC', // Yellow
      '4': '#EF8A62', // Orange
    },
    Savanna: {
      '1': '#542788', // Dark Purple
      '2': '#9998C3', // Light Purple
      '3': '#FCE2AC', // Yellow
      '4': '#EF8A62', // Orange
    },
    Shrubland: {
      '1': '#542788', // Dark Purple
      '2': '#9998C3', // Light Purple
      '3': '#D8DAEB', // XLight Purple
      '4': '#FCE2AC', // Yellow
      '5': '#EF8A62', // Orange
    },
    'Mallee-heath': {
      '1': '#542788', // Dark Purple
      '2': '#9998C3', // Light Purple
      '3': '#D8DAEB', // XLight Purple
      '4': '#FCE2AC', // Yellow
      '5': '#EF8A62', // Orange
    },
    Pine: {
      '1': '#542788', // Dark Purple
      '2': '#9998C3', // Light Purple
      '3': '#D8DAEB', // XLight Purple
      '4': '#FCE2AC', // Yellow
      '5': '#EF8A62', // Orange
      '6': '#B2182B', // Red
    },
    Buttongrass: {
      '1': '#542788', // Dark Purple
      '2': '#9998C3', // Light Purple
      '3': '#D8DAEB', // XLight Purple
      '4': '#FCE2AC', // Yellow
      '5': '#EF8A62', // Orange
      '6': '#B2182B', // Red
    },
  },
  categoryNameAFAC: {
    Buttongrass: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
    },
    Forest: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
    },
    Grass: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
    },
    'Mallee-heath': {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
    },
    Pine: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
    },
    Savanna: {
      '1': '1',
      '2': '2',
      '3': '4',
      '4': '5',
    },
    Shrubland: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
    },
    Spinifex: {
      '1': '1',
      '2': '2',
      '3': '4',
      '4': '5',
    },
  },
  categoryToText: {
    Buttongrass: {
      '1': '0-5',
      '2': '6-11',
      '3': '12-23',
      '4': '24-49',
      '5': '50-99',
      '6': 'Over 100',
    },
    Forest: {
      '1': '0-5',
      '2': '6-11',
      '3': '12-23',
      '4': '24-49',
      '5': '50-99',
      '6': 'Over 100',
    },
    Grass: {
      '1': '0-5',
      '2': '6-11',
      '3': '12-23',
      '4': '24-49',
      '5': '50-99',
      '6': 'Over 100',
    },
    'Mallee-heath': {
      '1': '0-5',
      '2': '6-11',
      '3': '12-23',
      '4': '24-49',
      '5': 'Over 50',
    },
    Savanna: {
      '1': '0-5',
      '2': '6-11',
      '3': '12-49',
      '4': 'Over 50',
    },
    Shrubland: {
      '1': '0-5',
      '2': '6-11',
      '3': '12-23',
      '4': '24-49',
      '5': 'Over 50',
    },
    Pine: {
      '1': '0-5',
      '2': '6-11',
      '3': '12-23',
      '4': '24-49',
      '5': '50-99',
      '6': 'Over 100',
    },
    Spinifex: {
      '1': '0-5',
      '2': '6-11',
      '3': '12-49',
      '4': 'Over 50',
    },
  },
  categoryRange: {
    Buttongrass: 6,
    Forest: 6,
    Grass: 6,
    'Mallee-heath': 5,
    Pine: 6,
    Savanna: 4,
    Shrubland: 5,
    Spinifex: 4,
  },
  meta: {
    Forest: (
      <div>
        <Text>Assumptions and methodology:</Text>
        <Text>
          <p>
            (a) Flame heights are based on McArthur’s equation for flame height (Noble, 1980).
            <br />
            (b) Rates of spread are back-calculated based on Byram’s fireline intensity and a range of fuel load varying
            from 10-20 t/ha.
            <br />
            (c) Spotting distances are based on McArthur’s spotting equation.
            <br />
            (d) Potential fire area and perimeter are based on a 4 hour fire run under max FDR with a range of L:B ratio
            as determined by wind speeds ranging from 10-40 km/hr (as per Cruz et. al. (2015) (on the back page)) and a
            fuel load varying from 10-20 t/ha. Assumes no suppression.
            <br />
            (e) The lowest (no. 1) category assumes that only 50% of the fuel load is available for burning, and this is
            represented in flame heights, rates of spread and potential fire size.
            <br />
            (f) Categories 2 &amp; 3 assume an additional 3 t/ha is available (contributed by bark &amp; elevated
            layers), and categories 5 and 6 assume an additional 8 t/ha is available (contributed by bark, elevated and
            canopy fuel layers) and these are applied to calculations of flame height, rate of spread, potential
            spotting distance as well as fire area and perimeter.
            <br />
            (g) Reference time to 5 ha is based on the shortest time for the category under following conditions: wind
            speed 25 kph, L:B ratio of 3:3 and a fuel load of 10 t/ha.
            <br />
            (h) References: 1 Harris et. al. (2011): Establishing a link between the power of fire and community loss:
            The first steps towards developing a bushfire severity scale. 2 Blanchi et. al. (2010): Meteorological
            conditions and wildfire-related houseloss in Australia.
          </p>
        </Text>
        <div style={{ ...basicGridStyle, marginTop: 16, marginBottom: 16 }}>
          <Text>Last updated:</Text>
          <Text>11/07/2019</Text>
          <Text>Date of Next Review:</Text>
          <Text>Est. 2023</Text>
        </div>
      </div>
    ),
    Grass: (
      <div>
        <Text>Assumptions and methodology:</Text>
        <Text>
          <p>
            (a) Flame heights are based on figure for &apos;grazed&apos; grassland in Cheney &amp; Sullivan (2008):
            Grassfires: Fuel, weather and fire behaviour.
            <br />
            (b) Rates of spread are back-calculated based on fireline intensity (Byram (1959): Combustion of forest
            fuels) and a range of fuel load varying from 3-6 t/ha (McArthur (1973): Grassland fire danger meter MkIV)
            assumed max fuel load was between 4-5 t/ha).
            <br />
            (c) Maximum potential fire area and perimeter are based on a 4 hour fire run (and a 30 minute fire run)
            under max FDR with a range of L:B ratio (grassland) as determined by wind speeds ranging from 10-40 km/hr
            (as per Cruz et. al. (2015): A Guide to Rate of Fire Spread Models for Australian Vegetation (on the back
            page)) and a fuel load varying from 3-6 t/ha.
            <br />
            (d) 2 Blanchi et.al. (2010): Meteorological conditions and wildfire-related houseloss in Australia.
            <br />
            (e) 3 Wilson (1988): Width of firebreak that is necessary to stop grass fires: Some field experiments.
          </p>
        </Text>
        <div style={{ ...basicGridStyle, marginTop: 16, marginBottom: 16 }}>
          <Text>Last updated:</Text>
          <Text>11/07/2019</Text>
          <Text>Date of Next Review:</Text>
          <Text>Est. 2023</Text>
        </div>
      </div>
    ),
    Spinifex: (
      <div>
        <Text>Assumptions and methodology:</Text>
        <Text>
          <p>
            (a) Flame heights are based on Neils flame height equation for spinifex (Burrows et al 2017)
            <br />
            (b) Rates of Spread are back-calculated based on Byram’s fireline intensity and a range of fuel load varying
            from 8-16.5 t/ha (Based on a range of fuel loads (3.5-16.5; Burrows et al, 2015) and Griffin &amp; Allan
            (1984) mean fuel load of 7.5 t/ha (assuming that 8 t/ha is on the heavy end of a light fuel load)
            <br />
            (c) Spotting distances are based on descriptions from Burrows et al 1991, 2006, 2015)
            <br />
            (d) Potential fire area and perimeter are based on a 4 hour fire run under max FDR (without suppression or
            containment) with a range of LB ratio as determined by wind speeds ranging from 10-40 km/hr (as per Cruz et
            al 2015 (on the back page)) and a fuel load varying from 3.5-16.5 t/ha
          </p>
        </Text>
        <div style={{ ...basicGridStyle, marginTop: 16, marginBottom: 16 }}>
          <Text>Last updated:</Text>
          <Text>INTERIM VERSION</Text>
          <Text>Date of Next Review:</Text>
          <Text>Est. 2023</Text>
        </div>
      </div>
    ),
    Savanna: (
      <div>
        <Text>Assumptions and methodology:</Text>
        <Text>
          <p>
            (a) Flame heights are based on figure for &apos;grazed&apos; grassland in Cheney &amp; Sullivan (2008):
            Grassfires: Fuel, weather and fire behaviour.
            <br />
            (b) Rates of Spread are back-calculated using Byram’s fireline intensity and a range of fuel load varying
            from 5-8 t/ha (based on fuel descriptions from Tropical Savannas CRC (2001) Savanna Burning. Understanding
            and using fire in northern Australia (p. 22: fuel loads generally range from 2-8 t/ha) together with fuel
            loads from Anderson et. al. (2011) grass curing dataset, where all fuels were &lt;8 t/ha with one exception
            which was 10 t/ha of around 30 sample datapoints in Australian northern savanna)
            <br />
            (c) Spotting distances are based on general descriptions.
            <br />
            (d) Potential fire area and perimeter are based on a 4 hour fire run under max FDR (without suppression or
            containment) with a range of L:B ratio (GRASS) as determined by wind speeds ranging from 10-40 km/hr (as per
            Cruz et. al. (2015) (on the back page)) and a fuel load varying from 7-14 t/ha.
            <br />
            (e) Width of firebreak are based on that necessary to stop grass fires based on Wilson (1988). 1 Tropical
            Savannas CRC (2001) Savanna Burning. Understanding and using fire in northern Australia. 2 Wilson, A (1988)
            Width of firebreak that is necessary to stop grass fires: Some field experiments. Canadian Journal of Forest
            Research 18, 682-687.
          </p>
        </Text>
        <div style={{ ...basicGridStyle, marginTop: 16, marginBottom: 16 }}>
          <Text>Last updated:</Text>
          <Text>11/07/2019</Text>
          <Text>Date of Next Review:</Text>
          <Text>Est. 2023</Text>
        </div>
      </div>
    ),
    Shrubland: (
      <div>
        <Text>Assumptions and methodology:</Text>
        <Text>
          <p>
            (a) Flame heights are based on Cruz&apos;s flame height equation for mallee-heath (Pers. Comm. Cruz 2017)
            <br />
            (b) Rates of Spread are back-calculated based on Byram’s fireline intensity and a range of fuel load varying
            from 6-30 t/ha (Based on steady state of shrublands (Bel K), McCaw etal 1989 wildfire in Fitzgerald River NP
            range of fuel loads together with Anderson etal 2015 in both the evaluation and modelling datasets (assuming
            that 6 t/ha is on the heavy end of a light fuel load)
            <br />
            (c) Spotting distances are based on pers. Comm. with Ryan Butler 2017
            <br />
            (d) Potential fire area and perimeter are based on a 4 hour fire run under max FDR (without suppression or
            containment) with a range of LB ratio (forest) as determined by wind speeds ranging from 10-40 km/hr (as per
            Cruz et al 2015 (on the back page)) and a fuel load varying from 6-30 t/ha
          </p>
        </Text>

        <div style={{ ...basicGridStyle, marginTop: 16, marginBottom: 16 }}>
          <Text>Last updated:</Text>
          <Text>INTERIM VERSION</Text>
          <Text>Date of Next Review:</Text>
          <Text>Est. 2023</Text>
        </div>
      </div>
    ),
    'Mallee-heath': (
      <div>
        <Text>Assumptions and methodology:</Text>
        <Text>
          <p>
            (a) Flame lengths are based on Byram&apos;s (1959) flame length evaluated by Cruz et.al. (2010).
            <br />
            (b) Flame heights are based on a re-analysis of Cruz et.al. (2013) data whereby flame height is a function
            of rate of spread.
            <br />
            (c) Rates of Spread are back-calculated based on Byram&apos;s fireline intensity and a range of fuel load
            varying from 7-14 t/ha.
            <br />
            (d) Descriptions of spotting potential are based on Cruz et.al. (2010).
            <br />
            (e) Potential fire area and perimeter are based on a 4 hour fire run under max FDR (without suppression or
            containment) with an L:B ratio as determined by (GRASS), wind speeds ranging from 10-40 km/hr for forests
            (as per Cruz et. al. (2015) (on the back page)) and a fuel load varying from 7-14 t/ha.
            <br />
            (f) Developed for semi-arid mallee heath (Cruz et.al. 2013): Probability of self-sustained surface fire is
            calculated using 10 m open wind speed, moisture content of dead litter (determined by Temp &amp; RH) and
            overstorey mallee cover. Probability of crown fire occurrence is calculated based on 10 m open wind speed
            and moisture content of dead litter (determined by Temp &amp; RH). 1 Cruz et.al. (2010)
          </p>
        </Text>
        <div style={{ ...basicGridStyle, marginTop: 16, marginBottom: 16 }}>
          <Text>Last updated:</Text>
          <Text>11/07/2019</Text>
          <Text>Date of Next Review:</Text>
          <Text>Est. 2023</Text>
        </div>
      </div>
    ),
    Pine: (
      <div>
        <Text>Assumptions and methodology:</Text>
        <Text>
          <p>
            (a) Flame heights are based on McArthur’s equation for flame height (Noble, 1980).
            <br />
            (b) Rates of spread are back-calculated based on Byram’s fireline intensity and a range of fuel load varying
            from 10-20 t/ha (plus an additional 11 t/ha for cat&apos;s 4,5,&amp; 6 for canopy fuel load).
            <br />
            (c) Spotting distances are based on a generalisation that pine spotting distances are 0.1 x McArthur’s
            spotting equation (FFMG (2007) and Douglas (1964) and (1973)).
            <br />
            (d) Potential fire area and perimeter are based on a 4 hour fire run under max FDR with a range of L:B ratio
            as determined by wind speeds ranging from 10-40 km/hr (as per Cruz et. al. (2015) (on the back page)) and a
            fuel load varying from 10-20 t/ha (plus 11 t/ha canopy fuel load for cat&apos;s 4,5 &amp; 6). Assumes no
            suppression.
            <br />
            (e) The lowest (no. 1) category assumes that only 50% of the fuel load is available for burning, and this is
            represented in flame heights, rates of spread and potential fire size.
            <br />
            (f) Categories 4, 5 and 6 assume an additional 11 t/ha is available (contributed by canopy fuel layer).
            <br />
            (g) Reference time to 5 ha is based on the shortest time for the category under following conditions: wind
            speed 25 km/hr, L:B ratio of 3:3 and a fuel load of 10/t/ha.
            <br />
            (h) References: 1 Harris et. al. (2011): Establishing a link between the power of fire and community loss:
            The first steps towards developing a bushfire severity scale. 2 Blanchi et. al. (2010): Meteorological
            conditions and wildfire-related houseloss in Australia.
          </p>
        </Text>
        <div style={{ ...basicGridStyle, marginTop: 16, marginBottom: 16 }}>
          <Text>Last updated:</Text>
          <Text>25/07/2019</Text>
          <Text>Date of Next Review:</Text>
          <Text>Est. 2023</Text>
        </div>
      </div>
    ),
    Buttongrass: (
      <div>
        <Text>Assumptions and methodology:</Text>
        <Text>
          <p>
            (a) Flame heights are determined using Marsden-Smedley etal 1999 equation for flame height (based on
            head-fire ROS, site productivity and site age)
            <br />
            (b) Rates of Spread are based on those categorised in Table 9 of Marsden-Smedley etal 1999
            <br />
            (c) Spotting distances are based on descriptions within Table 9 of Marsden-Smedley etal 1999
            <br />
            (d) Potential fire area and perimeter are based on a 4 hour fire run under max FDR with a range of LB ratio
            (FOREST) as determined by wind speeds ranging from 10-40 km/hr (as per Cruz et al 2015 (on the back page))
            and a fuel load varying from 10-20 t/ha
          </p>
        </Text>
        <div style={{ ...basicGridStyle, marginTop: 16, marginBottom: 16 }}>
          <Text>Last updated:</Text>
          <Text>INTERIM VERSION</Text>
          <Text>Date of Next Review:</Text>
          <Text>Est. 2023</Text>
        </div>
      </div>
    ),
  },
  cells: [
    // ======== Forest ========
    // ----- Cat 1 -----
    {
      fuelType: 'Forest',
      category: '1',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Mostly self-extinguishing, trouble-free fires.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '1',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>0</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>100</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '1',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fire difficult to ignite and sustain. Fires generally unlikely to spread and likely to self-extinguish.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>0-40 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;1 m</Text>
            <Text>
              <b>Spotting potential</b>
            </Text>
            <Text>Potential for any spotting is very limited and likely &lt;150 m</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '1',
      segment: 'Consequences',
      content: (
        <div>
          <Text>Community losses are unlikely.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '1',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire control relatively simple. Delayed containment possible with suitable conditions. Head-fire readily
            suppressed with offensive, direct attack techniques. Initial attack success is typically very high.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>11 hrs</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Small fires that are likely &lt;<b>1.5 ha</b> (&lt;<i> 0.2 ha</i>). Fires may be allowed to spread within an
            extended (time and area) containment objective.
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            &lt;<b>0.5 km</b> (<i>0.1 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '1',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Marginal prescribed burning conditions, even at peak of the day. Opportunities may arise where burn
            objectives target very low intensity, particularly heavy or dry fuels.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '1',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              DRY EUCALYPT FOREST FIRE MODEL
              <br />
              Cheney, N.P., Gould, J.S., McCaw, W.L. and Anderson, W.R. (2012) Predicting fire behaviour in dry eucalypt
              forest in southern Australia. Forest Ecology and Management 280, 120-131.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 2 -----
    {
      fuelType: 'Forest',
      category: '2',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Typical prescribed burning conditions.</BoldText>
          <BoldText>Fires generally easy to suppress and contain.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '2',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>100</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>750</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '2',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Slow spreading fires, typically involving surface and near-surface fuels and sometimes bark and elevated
              fuels. Spotting is sporadic and limited to short-distances.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>20-110 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;4 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for spotting is limited with short distance spotting possible up to 400 m</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '2',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Community losses are unlikely however unattended or poorly prepared houses and infrastructure may be at
            risk.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '2',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire control mostly simple with sufficient resources and becoming more complex at higher intensities.
            Offensive, direct attack techniques on head-fire or flanks largely successful in fire control. Delayed
            containment sometimes possible with suitable conditions.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>4 hrs</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>10 ha</b> (<i>0.2 ha</i>). Fires may be allowed to spread within an extended (time
            and area) containment objective.
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>1 km</b> (<i>0.1 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '2',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Typical prescribed burning conditions. Simple burns with adequate resourcing. Upper limit for private
            landholder burning provided adequate resourcing, training, necessary approvals and permits.
          </Text>
        </div>
      ),
      redFlags: ['Strong wind gusts forecast'],
    },
    {
      fuelType: 'Forest',
      category: '2',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              DRY EUCALYPT FOREST FIRE MODEL
              <br />
              Cheney, N.P., Gould, J.S., McCaw, W.L. and Anderson, W.R. (2012) Predicting fire behaviour in dry eucalypt
              forest in southern Australia. Forest Ecology and Management 280, 120-131.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 3 -----
    {
      fuelType: 'Forest',
      category: '3',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Most bushfires in this category.</BoldText>
          <BoldText>Fires typically suppressed with direct, parallel or indirect attack.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '3',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>750</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>4,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '3',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Actively spreading fires typically involving surface, near-surface, elevated and bark fuel layers and
              occasionally canopy fuels. Low-moderate spotting frequency; isolated medium range spotting can occur.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>60-600 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>2-8 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Short distance spotting occurring with increasing frequency with possible medium distance spotting up to 2
              km
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Forest',
      category: '3',
      segment: 'Consequences',
      content: (
        <div>
          <Text>Unattended or poorly prepared houses and infrastructure may be at risk.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '3',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fires generally becoming more complex and require more resources to control. Combinations of direct,
            indirect or parallel attack may be necessary for fire control.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>45 mins</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>300 ha</b> (<i>5 ha</i>)
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>6.5 km</b> (<i>1 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Forest',
      category: '3',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions may be suitable for more complex prescribed burning subject to adequate resourcing and well
            established boundaries/edges. Prescribed burning may be conducted away from the peak of the day when
            conditions are optimal and lighting techniques are suitable to achieve prescribed burning objectives.
          </Text>
        </div>
      ),
      redFlags: ['Strong wind gusts forecast'],
    },
    {
      fuelType: 'Forest',
      category: '3',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              DRY EUCALYPT FOREST FIRE MODEL
              <br />
              Cheney, N.P., Gould, J.S., McCaw, W.L. and Anderson, W.R. (2012) Predicting fire behaviour in dry eucalypt
              forest in southern Australia. Forest Ecology and Management 280, 120-131.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 4 -----
    {
      fuelType: 'Forest',
      category: '4',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Initial attack success critical to prevent large fire development.</BoldText>
          <BoldText>Increasing focus on defensive suppression strategies.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '4',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>4,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>10,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '4',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Rapidly spreading fires with potential for development into large burn areas within burning period. Fires
              typically involving most fuel layers. Short-range spotting is prevalent, with possibility of medium range
              and occasional long-range distance spotting.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>0.3-1 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>7-14 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Short and medium distance spotting occurring with increasing frequency with possible long distance
              spotting up to 4 km
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Forest',
      category: '4',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            6 % of house loss has occurred under these conditions. Increased potential for pasture/crop/stock losses as
            well as rural assets such as fencing, machinery and buildings.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '4',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Both ground and aerial resources using offensive strategies likely to be unsuccessful during the peak of the
            day, with focus largely centred on the rear and flanks. Suppression increasingly focused on defensive
            strategies. Fire control is likely to be difficult and require increased resourcing.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>25 mins</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>1,000 ha</b> (<i>16 ha</i>)
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>11 km</b> (<i>1.5 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text>Increased risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Forest',
      category: '4',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions are unlikely to be suitable for prescribed burning. Potential fireline intensity and spotting
            activity pose a serious risk for burn escapes. Fire intensity may be inconsistent with land management
            objectives.
          </Text>
        </div>
      ),
      redFlags: ['Strong wind gusts forecast'],
    },
    {
      fuelType: 'Forest',
      category: '4',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              DRY EUCALYPT FOREST FIRE MODEL
              <br />
              Cheney, N.P., Gould, J.S., McCaw, W.L. and Anderson, W.R. (2012) Predicting fire behaviour in dry eucalypt
              forest in southern Australia. Forest Ecology and Management 280, 120-131.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 5 -----
    {
      fuelType: 'Forest',
      category: '5',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Conditions limit strategic suppression options.</BoldText>
          <BoldText>High levels of threat to life/property. </BoldText>
          <BoldText>Elevated risk to firefigter safety.</BoldText>
          <BoldText>Initial attack success critical to prevent large fire development.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '5',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>10,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>30,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '5',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fires likely to quickly transition to crowning. Possibility for fire behaviour to become erratic and plume
              driven. Strong convective column formation. Wind speed and direction likely to be erratic at times.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>0.7-3 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>11 m - approx. double forest height</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>High ember density in short and medium range with possible long distance spotting up to 12 km</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Forest',
      category: '5',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            24% of house loss has occurred under these conditions. Limited visibility due to smoke and dust. High risk
            to the community related to inappropriate pre-considered plans, inadequate sheltering. High likelihood of
            pasture/crop/stock loss together with loss of rural assets such as fencing, machinery and buildings.
            Increased risk of long term economic and environmental impacts.
          </Text>
          <Text>
            Strong winds are likely to impact infrastructure (e.g. power lines) and fall trees increasing the likelihood
            of new ignitions as well as obstructed roads and power outages.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '5',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Control of developed fires is extremely difficult and unlikely until conditions ease. Suppression will be
            largely based on defensive strategies, ensuring firefighter and community preparedness and safety. Offensive
            strategies could position crews in danger, however safe opportunities may exist for direct, indirect or
            parallel attack on the rear and flanks. Important initial attack opportunities may exist for new ignitions.
            Conditions on the fireground are likely to be extremely windy and smokey limiting visibility and restricting
            aviation and access. Aerial resources are likely to be ineffective at holding fire.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>10 mins</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires up to <b>9,000 ha</b> (<i>140 ha</i>)
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large fire perimeters up to <b>35 km</b> (<i>4.5 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text>Increased risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Forest',
      category: '5',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions will be unsuitable for prescribed burning. Potential fireline intensity and spotting activity
            pose a serious risk to firefighter safety and the community.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '5',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              DRY EUCALYPT FOREST FIRE MODEL
              <br />
              Cheney, N.P., Gould, J.S., McCaw, W.L. and Anderson, W.R. (2012) Predicting fire behaviour in dry eucalypt
              forest in southern Australia. Forest Ecology and Management 280, 120-131.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 6 -----
    {
      fuelType: 'Forest',
      category: '6',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Elevated risk to firefighter safety.</BoldText>
          <BoldText>High probability of loss of life and property.</BoldText>
          <BoldText>Initial attack success critical to prevent large fire development.</BoldText>
          <BoldText>
            Conditions limit strategic suppression options. Wind speed and limited visibility may ground some aviation
            resources.
          </BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '6',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>30,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>30,000+</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '6',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fires likely to quickly transition to crowning. Possibility for fire behaviour to become erratic and plume
              driven. Strong convective column formation. Wind speed and direction likely to be erratic at times.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>Rate of spread in excess of 2 km/hr can be expected and possibly &gt;3 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&gt;30 m (approx. double forest height)</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              High ember density in short and medium range with possible long distance spotting occurring 20-30 km ahead
              of the main fire front
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Forest',
      category: '6',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            70% of house loss has occurred under these conditions. Limited visibility due to smoke and dust. Very high
            risk to the community related to inappropriate pre-considered plans, inadequate sheltering. Extremely high
            likelihood of pasture/crop/stock loss together with loss of rural assets such as fencing, machinery and
            buildings. Very high risk of long term economic and environmental impacts.
          </Text>
          <Text>
            Extremely strong winds are likely to impact infrastructure (e.g. power lines) and fall trees increasing the
            likelihood of new ignitions as well as obstructed roads and power outages.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '6',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire control of developed fires is extremely difficult and unlikely until conditions ease. Focus will be
            largely based on defensive strategies, ensuring firefighter and community preparedness and safety. Offensive
            strategies could position crews in danger, however safe opportunities may exist for direct, indirect or
            parallel attack on the rear and flanks. Important initial attack opportunities may exist for new ignitions.
            Conditions on the fireground are likely to be extremely windy and smokey limiting visibility and restricting
            aviation and access. Conditions are likely to impact performance and effectiveness of aerial resources with
            a high probability that some aircraft will be unable to operate due to high winds and limited visibility.
            Systems such as communications, will be heavily challenged with a likelihood of difficulties and outages.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>&lt;10 mins</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires exceeding <b>1,000 ha</b> (<i>16 ha</i>) and potentially <b>&gt;9,000 ha</b> (
            <i>&gt;140 ha</i>)
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large fire perimeters exceeding <b>17 km</b> (<i>2 km</i>) and potentially <b>&gt;35 km</b> (<i>4.5 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text>Extreme risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Forest',
      category: '6',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions will be unsuitable for prescribed burning. Potential fireline intensity and spotting activity
            pose a serious risk to firefighter safety and the community.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Forest',
      category: '6',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              DRY EUCALYPT FOREST FIRE MODEL
              <br />
              Cheney, N.P., Gould, J.S., McCaw, W.L. and Anderson, W.R. (2012) Predicting fire behaviour in dry eucalypt
              forest in southern Australia. Forest Ecology and Management 280, 120-131.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ======== Grass ========
    // ----- Cat 1 -----
    {
      fuelType: 'Grass',
      category: '1',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Mostly self-extinguishing, trouble-free fires.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '1',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>0</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>100</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '1',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fire difficult to ignite and sustain. Fires generally unlikely to spread and likely to self-extinguish.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>0-30 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;0.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for any spotting is extremely limited.</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '1',
      segment: 'Consequences',
      content: (
        <div>
          <Text>Community losses are unlikely.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '1',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire control relatively simple. Delayed containment possible with suitable conditions. Head-fire readily
            suppressed with offensive, direct attack techniques. Initial attack success is typically very high.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Small fires that are likely &lt;<b>0.5 ha</b> (&lt;<i> 0.1 ha</i>). Fires may be allowed to spread within an
            extended (time and area) containment objective.
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            &lt;<b>0.5 km</b> (<i>0.1 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '1',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>Marginal prescribed burning conditions, even at peak of the day.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '1',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              CSIRO GRASSLAND FIRE SPREAD METER
              <br />
              Cheney, N.P., Gould, J.S. and Catchpole, W.R. (1998) Prediction of fire spread in grasslands.
              International Journal of Wildland Fire 8, 1-15.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 2 -----
    {
      fuelType: 'Grass',
      category: '2',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Typical prescribed burning conditions.</BoldText>
          <BoldText>Fires generally easily contained within simple road networks and fuel breaks.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '2',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>100</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>3,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '2',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fire easily sustained. Typically wind driven fires that can spread quickly.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>&lt;1.3 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;1.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for short distance spotting is limited.</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '2',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Community losses are unlikely however unattended or poorly prepared houses and infrastructure may be at
            risk.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '2',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire control mostly simple with sufficient resources and becoming more complex at higher intensities.
            Offensive, direct attack techniques on head-fire or flanks largely successful in fire control. 3 m wide fuel
            breaks are largely successful at holding fire where trees are absent. Delayed containment sometimes possible
            with suitable conditions.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>650 ha</b> (<i>10 ha</i>). Fires may be allowed to spread within an extended (time
            and area) containment objective.
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>11.5 km</b> (<i>1.5 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '2',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Typical prescribed burning conditions. Simple burns with adequate resourcing. Upper limit for private
            landholder burning provided adequate resourcing, training, necessary approvals and permits.
          </Text>
        </div>
      ),
      redFlags: ['Strong wind gusts forecast'],
    },
    {
      fuelType: 'Grass',
      category: '2',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              CSIRO GRASSLAND FIRE SPREAD METER
              <br />
              Cheney, N.P., Gould, J.S. and Catchpole, W.R. (1998) Prediction of fire spread in grasslands.
              International Journal of Wildland Fire 8, 1-15.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 3 -----
    {
      fuelType: 'Grass',
      category: '3',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Most bushfires in this category.</BoldText>
          <BoldText>
            Fires typically controlled within established road networks and fuel breaks together with using direct,
            indirect or parallel attack suppression strategies.
          </BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '3',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>3,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>9,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '3',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Typically wind driven and rapidly spreading fires with the potential to gain size quickly.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>0.6 - 5 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>1.5-2.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Possible short distance spotting occurring.</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Grass',
      category: '3',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Possible agricultural/pasture/crop/stock losses together with loss of rural assets such as fencing,
            machinery and buildings. Unattended or poorly prepared houses and infrastructure may be at risk.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '3',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fires generally becoming more complex and require more resources to control. Combinations of direct,
            indirect or parallel attack may be necessary for fire control. Requires increased effort and resources to
            contain fire within existing road networks and fuel break boundaries. Increased likelihood that a 3 m break
            will be ineffective.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires up to <b>10,500 ha</b> (<i>160 ha</i>)
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>45 km</b> (<i>6 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Grass',
      category: '3',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions may be suitable for more complex prescribed burning subject to adequate resourcing and well
            established boundaries/edges. Prescribed burning may be conducted away from the peak of the day when
            conditions are optimal and lighting techniques are suitable to achieve prescribed burning objectives.
          </Text>
        </div>
      ),
      redFlags: ['Strong wind gusts forecast'],
    },
    {
      fuelType: 'Grass',
      category: '3',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              CSIRO GRASSLAND FIRE SPREAD METER
              <br />
              Cheney, N.P., Gould, J.S. and Catchpole, W.R. (1998) Prediction of fire spread in grasslands.
              International Journal of Wildland Fire 8, 1-15.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 4 -----
    {
      fuelType: 'Grass',
      category: '4',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Fires may escalate very quickly.</BoldText>
          <BoldText>Increasing focus on defensive suppression strategies.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '4',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>9,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>17,500</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '4',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Wind driven, rapidly spreading fires with potential for development into large fire area/size and with the
              potential for short distance spotting and long flame lengths.
            </Text>
            <div style={basicGridStyleSmall}>
              <Text>
                <b>Rate of spread range</b>
              </Text>
              <Text>2.5-10 km/hr</Text>
              <Text>
                <b>Max flame height range</b>
              </Text>
              <Text>2-3 m</Text>
              <Text>
                <b>Spotting Potential</b>
              </Text>
              <Text>Possible short distance spotting occurring with increasing frequency.</Text>
            </div>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Grass',
      category: '4',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            High likelihood of agricultural/pasture/crop/stock losses together with loss of rural assets such as
            homesteads, fencing, machinery and buildings.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '4',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Offensive strategies likely to be challenged during the peak of the day with focus largely centred on the
            rear and flanks. Suppression increasingly focused on defensive strategies. Fire control may be difficult and
            typically requires larger fuel breaks &gt;10 m wide, together with increased resourcing and effort to
            contain.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires up to <b>37,000 ha</b> (<i>580 ha</i>)
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large fire perimeters up to <b>85 km</b> (<i>11 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text>Increased risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Grass',
      category: '4',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions are unlikely to be suitable for prescribed burning. Potential rates of spread, long flame lengths
            and short distance spotting pose a serious risk of burn escapes. Fire intensity may be inconsistent with
            land management objectives.
          </Text>
        </div>
      ),
      redFlags: ['Strong wind gusts forecast'],
    },
    {
      fuelType: 'Grass',
      category: '4',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              CSIRO GRASSLAND FIRE SPREAD METER
              <br />
              Cheney, N.P., Gould, J.S. and Catchpole, W.R. (1998) Prediction of fire spread in grasslands.
              International Journal of Wildland Fire 8, 1-15.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 5 -----
    {
      fuelType: 'Grass',
      category: '5',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Extremely rapid fire growth.</BoldText>
          <BoldText>Conditions limit strategic suppression options.</BoldText>
          <BoldText>High levels of threat to life/property.</BoldText>
          <BoldText>Elevated risk to firefighter safety.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '5',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>17,500</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>25,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '5',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Extremely rapid fire growth and increasing likelihood of large final fire area/size. Possibility for fire
              behaviour to become erratic and plume driven. Strong convective column formation. Wind speed and direction
              likely to be erratic at times.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>5-16 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>2.5-3.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Likely short distance spotting occurring with increasing frequency.</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Grass',
      category: '5',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Increasingly high likelihood of agricultural/pasture/crop/stock losses together with loss of rural assets
            such as homesteads, fencing, machinery and buildings. Limited visibility due to smoke and dust. High risk to
            the community related to inappropriate pre-considered plans, inadequate sheltering.
          </Text>
          <Text>
            Strong winds are likely to impact infrastructure (e.g. power lines) and fall trees increasing the likelihood
            of obstructed roads and power outages.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '5',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire control is extremely difficult and unlikely until conditions ease. Suppression will be largely based on
            defensive strategies, ensuring firefighter and community preparedness and safety. Offensive strategies could
            position crews in danger, however safe opportunities may exist for direct, indirect or parallel attack on
            the rear and flanks. Important initial attack opportunities may exist for new ignitions. Conditions on the
            fireground are likely to be extremely windy and smokey limiting visibility and restricting aviation and
            access.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires up to <b>100,000 ha</b> (<i>1,500 ha</i>)
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large fire perimeters up to <b>140 km</b> (<i>18 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text>Increased risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Grass',
      category: '5',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions will be unsuitable for prescribed burning. Potential fireline intensity and rates of spread pose
            a serious risk to firefighter safety and the community.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '5',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              CSIRO GRASSLAND FIRE SPREAD METER
              <br />
              Cheney, N.P., Gould, J.S. and Catchpole, W.R. (1998) Prediction of fire spread in grasslands.
              International Journal of Wildland Fire 8, 1-15.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 6 -----
    {
      fuelType: 'Grass',
      category: '6',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Extremely rapid fire growth.</BoldText>
          <BoldText>High probability of loss of life and property.</BoldText>
          <BoldText>Elevated risk to firefighter safety.</BoldText>
          <BoldText>Initial attack success critical to prevent large fire development.</BoldText>
          <BoldText>Conditions limit strategic suppression options.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '6',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>25,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>25,000+</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '6',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Extremely rapid fire growth and high likelihood of large final fire area/size. Possibility for fire
              behaviour to become erratic and plume driven. Strong convective column formation. Wind speed and direction
              likely to be erratic at times.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Rate of spread range</b>
            </Text>
            <Text>Rate of spread in excess of 8 km/hr can be expected and possibly &gt;16 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>Flame heights &gt;3 m and possibly &gt;3.5m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Likely short distance spotting occurring with increasing frequency.</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Grass',
      category: '6',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Extremely high likelihood of agricultural/pasture/crop/stock loss together with losses of rural assets such
            as homesteads, fencing, machinery and buildings. Very limited visibility due to smoke and dust. Very high
            risk to the community related to inappropriate pre-considered plans, inadequate sheltering.
          </Text>
          <Text>
            Extremely high likelihood of agricultural/pasture/crop/stock loss together with losses of rural assets such
            as homesteads, fencing, machinery and buildings. Very limited visibility due to smoke and dust. Very high
            risk to the community related to inappropriate pre-considered plans, inadequate sheltering.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '6',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire control is extremely difficult and unlikely until conditions ease. Suppression will be largely based on
            defensive strategies, ensuring firefighter and community preparedness and safety. Offensive strategies could
            position crews in danger, however safe opportunities may exist for direct, indirect or parallel attack on
            the rear and flanks. Important initial attack opportunities may exist for new ignitions. Conditions on the
            fireground are likely to be extremely windy and smokey limiting visibility and restricting aviation and
            access. Conditions are likely to impact performance and effectiveness of aerial resources with a high
            probability that some aircraft will be unable to operate due to high winds and limited visibility. Systems
            such as communications, will be heavily challenged with a likelihood of difficulties and outages. Fuel
            breaks &lt;100 m are likely to be ineffective at holding head-fires.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires exceeding <b>54,000 ha</b> (<i>100 ha</i>) and potentially <b>&gt;100,000 ha</b> (
            <i>&gt;1,500 ha</i>)
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large fire perimeters exceeding <b>60 km</b> (<i>8 km</i>) and potentially <b>&gt;140 km</b> (&gt;
            <i>18 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text>Extreme risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Grass',
      category: '6',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions will be unsuitable for prescribed burning. Potential fireline intensity and spotting activity
            pose a serious risk to firefighter safety and the community.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Grass',
      category: '6',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              CSIRO GRASSLAND FIRE SPREAD METER
              <br />
              Cheney, N.P., Gould, J.S. and Catchpole, W.R. (1998) Prediction of fire spread in grasslands.
              International Journal of Wildland Fire 8, 1-15.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ======== Spinifex ========
    // ----- Cat 1 -----
    {
      fuelType: 'Spinifex',
      category: '1',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Fire is unlikely to spread.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '1',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of rate of spread (m/h)</Text>
          <Text>0</Text>
          <Text>Upper limit of rate of spread (m/h)</Text>
          <Text>50</Text>
          <Text>Primary threshold limits</Text>
          <Text>Spread Index ≤0</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '1',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Likelihood of spread is largely a function of fuel cover and wind speed (see SI above). If fuel cover
              &lt;50% and wind speed &lt;12 km/h, flame dimensions are generally insufficient to breach inter-hummock
              gaps.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>&lt;50 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;2 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for any spotting is extremely limited.</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '1',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Small fires that are typically &lt;5 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Small fire perimeters that are typically &lt;1 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '1',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fire containment relatively simple. Mostly contained within simple road networks, fuel breaks and buffers.
            Suppression generally not necessary.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '1',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>Marginal conditions even at the peak of the day. The probability of sustained spread is minimal.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '1',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              SPINIFEX MODEL
              <br />
              1. Burrows, N.D., Ward, B. and Robinson A. (2009) Fuel dynamics and fire spread in spinifex grasslands of
              the western desert. Proceedings of the Royal Society of Queensland 115, 69-76.
              <br />
              2. Burrows N, Gill M, Sharples J (2018). Development and validation of a model for predicting fire
              behaviour in spinifex grasslands of arid Australia. International Journal of Wildland Fire 27, 271–279.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 2 -----
    {
      fuelType: 'Spinifex',
      category: '2',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Typical prescribed burning conditions.</BoldText>
          <BoldText>Spreading fires, generally easily contained within simple road networks and fuel breaks.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '2',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of rate of spread (m/h)</Text>
          <Text>50</Text>
          <Text>Upper limit of rate of spread (m/h) </Text>
          <Text>1,300</Text>
          {/* <Text>Primary threshold limits</Text> */}
          {/* <Text>0&lt;SI≤2 </Text> */}
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '2',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Winds speeds (2 m) 12-17 km/hr enabling sustained spread of fire. Largely wind driven head-fires burning
              in narrow strips and classic &apos;finger&apos; shapes.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>0.05 - 1.3 km/h</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;3.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for spotting is limited.</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '2',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Possible fires up to 2,500 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Possible fire perimeters up to 20 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '2',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fire containment mostly simple. Fires typically contained within road networks, fuel breaks or buffers &gt;5
            m wide (including spinifex fuel class 1&amp;2: fuel cover &lt;40%)
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '2',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Typical prescribed burning conditions. Sustained spread likely and largely dependant on wind speed, fuel
            quantity and fuel moisture content. Head-fires commonly fragment and go out under low wind speeds and
            diurnal/evening conditions.
          </Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Spinifex',
      category: '2',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              SPINIFEX MODEL
              <br />
              1. Burrows, N.D., Ward, B. and Robinson A. (2009) Fuel dynamics and fire spread in spinifex grasslands of
              the western desert. Proceedings of the Royal Society of Queensland 115, 69-76.
              <br />
              2. Burrows N, Gill M, Sharples J (2018). Development and validation of a model for predicting fire
              behaviour in spinifex grasslands of arid Australia. International Journal of Wildland Fire 27, 271–279.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 3 -----
    {
      fuelType: 'Spinifex',
      category: '3',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>
            Rapid fire growth typically contained within established road networks and fuel breaks mostly without the
            need for additional suppression.
          </BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '3',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of rate of spread (m/h)</Text>
          <Text>1,300</Text>
          <Text>Upper limit of rate of spread (m/h)</Text>
          <Text>7,500</Text>
          {/* <Text>Primary threshold limits</Text> */}
          {/* <Text>2&lt;SI≤10 </Text> */}
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '3',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Rapid fire growth, especially when SI &gt;6 or wind speed &gt; 25 km/h. Often reaching &apos;quasi steady
              state&apos; within 5-10 mins of ignition. Largely wind-driven head-fires becoming increasingly large with
              shifts in wind direction. Increased potential for burning hummocks on flanks to develop into smaller fires
              following changes in wind direction.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>1.3-7.5 km/h</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>3-4.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Potential for spotting is limited except where eucalypt/mallee trees are present where spotting is likely
              to be minimal and limited to short distances (&lt;100 m). Any spot fires are typically overrun by the main
              headfire.
            </Text>
          </div>
        </div>
      ),
      redFlags: ['Wind change forecast during the peak of the afternoon', 'C-Haines >95th percentile (approx. >10)'],
    },
    {
      fuelType: 'Spinifex',
      category: '3',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Potentially damaging impacts on the environment (threats to fauna, habitat loss, e.g. loss of hollow-bearing
            trees, hollow logs, shrub cover, mulga groves) as well as potential pasture/crop/stock loss together with
            loss of rural/structural assets such as fencing, machinery and buildings.
          </Text>
          <Text>Implications for firefighter safety</Text>
          <Text>
            Risk to firefighters. Fire behaviour (speed and direction) highly responsive to wind shifts and positive
            slope
          </Text>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>
            Potential for large fires in the absence of significant areas of low fuel cover. Large area fires up to
            9,000 ha
          </Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Large fire perimeters up to 45 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '3',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fires generally becoming more complex and requiring wider roads, larger fuel breaks or buffers (including
            spinifex fuel class 1&amp;2: fuel cover &lt;40%) &gt;15 m for containment. Active suppression around assets
            generally not necessary unless fuel breaks around assets are inadequate.
          </Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Spinifex',
      category: '3',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            P/B opportunities may exist away from assets and with well established, wide (&gt;10 m) boundaries/edges.
            P/B opportunities may exist away from the peak of the day when conditions are optimal and lighting
            techniques are suitable to achieve P/B objectives.
          </Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Spinifex',
      category: '3',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              SPINIFEX MODEL
              <br />
              1. Burrows, N.D., Ward, B. and Robinson A. (2009) Fuel dynamics and fire spread in spinifex grasslands of
              the western desert. Proceedings of the Royal Society of Queensland 115, 69-76.
              <br />
              2. Burrows N, Gill M, Sharples J (2018). Development and validation of a model for predicting fire
              behaviour in spinifex grasslands of arid Australia. International Journal of Wildland Fire 27, 271–279.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 4 -----
    {
      fuelType: 'Spinifex',
      category: '4',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Damaging wildfires with extremely rapid fire growth.</BoldText>
          <BoldText>Active suppression may be required.</BoldText>
          <BoldText>
            High levels of threat to the environment and/or when in close proximity to people, assets and property.
          </BoldText>
          <BoldText>Elevated risk to the safety of visitors and local communities.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '4',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of rate of spread (m/h)</Text>
          <Text>7,500</Text>
          <Text>Upper limit of rate of spread (m/h)</Text>
          <Text>7,500+</Text>
          {/* <Text>Primary threshold limits</Text>
          <Text>SI&gt;10 </Text> */}
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '4',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Rapid fire growth (&lt;5 mins to steady state), extremely fast moving, wind-driven fires. High potential
              for large fire areas with complete combustion of fuels and few unburnt patches. Burning hummocks often
              developing into smaller fires following changes in wind direction.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>&gt;7.5 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&gt;3.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Possible short distance spotting if eucalypt/mallee trees are present (mostly &lt;200 m) with spot fires
              typically quickly overrun by the main headfire.
            </Text>
          </div>
        </div>
      ),
      redFlags: ['Wind change forecast during the peak of the afternoon', 'C-Haines >95th percentile (approx. >10)'],
    },
    {
      fuelType: 'Spinifex',
      category: '4',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Very high risk of damaging impacts on the environment over large areas (threats to fauna, habitat loss, e.g.
            loss of hollow-bearing trees, hollow logs, shrub cover, mulga groves) as well as very high likelihood of
            pasture/crop/stock loss together with loss of rural assets such as fencing, machinery and buildings.
          </Text>
          <Text>Implications for firefighter safety</Text>
          <Text>
            Risk to firefighters. Fire behaviour (speed and direction) highly responsive to wind shifts and positive
            slope.
          </Text>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Large area fires mostly exceeding 2,000 ha and likely more than 9,000 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Large fire perimeters mostly exceeding 10 km and likely more than 45 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Spinifex',
      category: '4',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fires quickly becoming large, complex and difficult to contain within existing road networks, fuel breaks
            and buffers (including spinifex fuel class 1&amp;2: fuel cover &lt;40%). Fuel breaks typically need to be
            &gt;200 m to be effective. Conditions are likely to be extremely windy. Active suppression including
            indirect attack or machines may be required to protect areas of population, high conservation value and on
            high value built, agricultural and cultural assets.
          </Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Spinifex',
      category: '4',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions are likely to be unsuitable for P/B however opportunities for P/B may be exist pre immediate
            storm/rain events or away from the peak of the day when conditions are optimal and lighting techniques are
            suitable to achieve P/B objectives.
          </Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Spinifex',
      category: '4',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              SPINIFEX MODEL
              <br />
              1. Burrows, N.D., Ward, B. and Robinson A. (2009) Fuel dynamics and fire spread in spinifex grasslands of
              the western desert. Proceedings of the Royal Society of Queensland 115, 69-76.
              <br />
              2. Burrows N, Gill M, Sharples J (2018). Development and validation of a model for predicting fire
              behaviour in spinifex grasslands of arid Australia. International Journal of Wildland Fire 27, 271–279.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ======== Savanna ========
    // ----- Cat 1 -----
    {
      fuelType: 'Savanna',
      category: '1',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Mostly self-extinguishing fires.</BoldText>
          <BoldText>Fires typically quickly contained within simple fuel breaks and landscape features.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '1',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>0</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>100</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '1',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fire difficult to ignite and sustain. Fires generally unlikely to spread and likely to self-extinguish.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>0-50 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;0.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for any spotting is extremely limited</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '1',
      segment: 'Consequences',
      content: (
        <div>
          <Text>Community losses are unlikely.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '1',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Suppression is generally not necessary and delayed containment possible with suitable conditions. Fire
            containment relatively simple and mostly contained within simple road networks, fuel breaks or landscape
            characteristics. Head-fire readily suppressed with offensive, direct attack techniques. Initial attack
            success is typically very high.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Small fires that are typically &lt; <b>0.5 ha</b>. Fires may be allowed to spread within an extended (time
            and area) containment objective.
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Small fire perimeters that are typically &lt; <b>0.5 km</b>
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '1',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Marginal prescribed burn conditions, even at peak of the day. Long line ignitions may be useful to increase
            acceleration.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '1',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              FIRE SPREAD METER FOR NORTHERN AUSTRALIA
              <br />
              Cheney, N.P., Gould, J.S. and Catchpole, W.R. (1998) Prediction of fire spread in grasslands.
              International Journal of Wildland Fire 8, 1-15.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 2 -----
    {
      fuelType: 'Savanna',
      category: '2',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>
            Typical prescribed burning conditions whereby a mosaic of burnt and unburnt patches are created.
          </BoldText>
          <BoldText>
            Fires generally easily contained within simple road networks, fuel breaks and recent fire scars.
          </BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '2',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>100</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>3,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '2',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fire easily sustained. Typically wind driven fires that can spread quickly. Fires mostly only partially
              consuming fuels, typically creating a mosaic of burnt and unburnt patches (decreasing patchiness with
              increasing intensity).
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>&lt;1.5 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;1.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for short distance spotting is limited</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '2',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Community losses are unlikely however, unattended or poorly prepared assets and infrastructure may be at
            risk.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '2',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire containment mostly simple and delayed containment possible with suitable conditions. Fires typically
            contained within road networks, fuel breaks and recent fire scars (&gt;10 m).
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>1,000 ha</b>. Fires may be allowed to spread within an extended (time and area)
            containment objective.
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>13 km</b>
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '2',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Typical prescribed burning conditions for hazard reduction and pasture management. Mostly simple burns with
            adequate fuel breaks, often going out overnight with higher humidity and fuel moisture. Above 2,000 kW/m,
            opportunities may exist for prescribed burns with adequate resourcing and well established, wide
            boundaries/edges. Prescribed burn opportunities may exist away from the peak of the day when conditions are
            suitable to achieve objectives. Point source ignitions may be useful to reduce acceleration at higher
            intensities. Suitable conditions for controlling woody vegetation structure and exotic weeds. Upper limit
            for private landholder burning provided adequate resourcing, training, necessary approvals and permits.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '2',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              FIRE SPREAD METER FOR NORTHERN AUSTRALIA
              <br />
              Cheney, N.P., Gould, J.S. and Catchpole, W.R. (1998) Prediction of fire spread in grasslands.
              International Journal of Wildland Fire 8, 1-15.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 3 -----
    {
      fuelType: 'Savanna',
      category: '3',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Fires generally escalate in size very quickly.</BoldText>
          <BoldText>Fires typically consuming all available fuels.</BoldText>
          <BoldText>
            Fires mostly controlled within wide fuel breaks and recent fire scars, mostly without the need for
            additional containment support.
          </BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '3',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>3,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>17,500</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '3',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Wind driven, rapidly spreading fires with potential for development into large fire area/size and with the
              potential for short distance spotting and long flame lengths. Fires typically consuming all available
              fuel. Increasing scorch height of tree canopy (up to 20-25m) and char height (up to 3-4m).
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>1-8 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>1.5-2.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Possible short distance spotting occurring</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >12)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Savanna',
      category: '3',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            High likelihood of pasture/crop/stock loss together with loss of rural assets such as fencing, machinery and
            buildings. Often producing large amounts of smoke and associated carbon emissions. Visibility is likely to
            be limited due to smoke and dust, potentially impacting traffic management. Fires are often associated with
            reduced biodiversity and habitat damage including loss of food supply for native fauna and traditional
            owners. Stem survival &lt; 80%.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '3',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fires quickly becoming large and generally requiring fuel breaks (or buffers) or recent fire scars (&gt;100
            m) for containment. Active suppression around assets generally not necessary with adequate fuel breaks,
            buffers or recent fire scars. Conditions are likely to be extremely windy. Active suppression including
            indirect attack and/or machines may be required to protect areas of population, high conservation value and
            on high value built, agricultural and cultural assets.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires up to <b>24,000 ha</b>
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large fire perimeters up to <b>70 km</b>
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Savanna',
      category: '3',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions are unlikely to be suitable for prescribed burning. Potential rates of spread, long flame lengths
            and short distance spotting pose a serious risk of burn escapes. Fires typically burning large areas without
            suitable internal patchiness. Fires often don&apos;t go out overnight and fire intensity is likely to be
            inconsistent with land management objectives. Prescribed burn opportunities may exist in line with burn
            objectives and away from the peak of the day provided adequate resourcing and well established, wide
            boundaries/edges. Point source ignitions may be useful to reduce acceleration.
          </Text>
        </div>
      ),
      redFlags: ['Strong wind gusts forecast'],
    },
    {
      fuelType: 'Savanna',
      category: '3',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              FIRE SPREAD METER FOR NORTHERN AUSTRALIA
              <br />
              Cheney, N.P., Gould, J.S. and Catchpole, W.R. (1998) Prediction of fire spread in grasslands.
              International Journal of Wildland Fire 8, 1-15.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 4 -----
    {
      fuelType: 'Savanna',
      category: '4',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Damaging wildfires with extremely rapid fire growth.</BoldText>
          <BoldText>Additional containment and active suppression may be required.</BoldText>
          <BoldText>
            High levels of threat to the environment and/or when in close proximity to people, assets and property.
          </BoldText>
          <BoldText>Elevated risk to the safety of visitors and local communities.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '4',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>17,500</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>17,500+</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '4',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Extremely rapid fire growth and increasing likelihood of large final fire area/size. Possibility for fire
              behaviour to become erratic and plume driven. Strong convective column formation. Wind speed and direction
              likely to be erratic at times. Fires consuming all available fuel.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>Rates of spread in excess of 5km/hr can be expected and possibly &gt;8 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&gt;2.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Likely short distance spotting</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >12)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Savanna',
      category: '4',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Extremely high likelihood of pasture/crop/stock loss together with loss of rural assets such as fencing,
            machinery and buildings. Often producing large amounts of smoke and associated carbon emissions. Visibility
            is likely to be limited due to smoke and dust, potentially impacting traffic management. Fires are often
            associated with reduced biodiversity and habitat damage including loss of food supply for native fauna and
            traditional owners. Stem survival &lt; 20%.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Savanna',
      category: '4',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fires quickly becoming large and generally requiring fuel breaks (or buffers) or recent fire scars (&gt;500
            m) for containment. Active suppression around assets may be required where fuel breaks are inadequate.
            Conditions are likely to be extremely windy. Active suppression including indirect attack and/or machines
            may be required to protect areas of population, high conservation value and on high value built,
            agricultural and cultural assets.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires likely to exceed <b>5,000 ha</b> and possibly exceeding <b>24,000 ha</b>
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large fire perimeters typically over <b>40 km</b> and possibly exceeding <b>70 km</b>
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Savanna',
      category: '4',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions will be unsuitable for prescribed burning. Potential fireline intensity and rates of spread pose
            a serious risk to firefighter safety and the community.
          </Text>
        </div>
      ),
      redFlags: ['Strong wind gusts forecast'],
    },
    {
      fuelType: 'Savanna',
      category: '4',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              FIRE SPREAD METER FOR NORTHERN AUSTRALIA
              <br />
              Cheney, N.P., Gould, J.S. and Catchpole, W.R. (1998) Prediction of fire spread in grasslands.
              International Journal of Wildland Fire 8, 1-15.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ======== Shrubland ========
    // ----- Cat 1 -----
    {
      fuelType: 'Shrubland',
      category: '1',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Fire is unlikely to spread.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '1',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>0</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>50</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '1',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Flame dimensions are generally insufficient to breach sparse and discontinuous fuels or inter-hummock
              gaps.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>&lt;20 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;0.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for any spotting is extremely limited.</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '1',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Small fires that are typically &lt;0.5 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Small fire perimeters that are typically &lt;0.5 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '1',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fire containment relatively simple. Mostly contained within simple road networks, fuel breaks and buffers.
            Suppression generally not necessary.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '1',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>Marginal conditions even at the peak of the day. The probability of sustained spread is minimal.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '1',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              HEATHLAND MODEL
              <br />
              Anderson, W.R., Cruz, M.G., Fernandes, P.M., McCaw, W.L., Vega, J.A., Bradstock, R.A., Fogarty, L., Gould,
              J., McCarthy, G., Marsden-Smedley, J.B., Matthews, S., Mattingley, G., Pearce, G. and van Wilgen, B.W.
              (2015) A generic, empirical-based model for predicting rate of fire spread in shrublands. International
              Journal of Wildland Fire 24 (4), 443-460.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 2 -----
    {
      fuelType: 'Shrubland',
      category: '2',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Typical prescribed burning conditions including scrub rolled firebreaks.</BoldText>
          <BoldText>
            Spreading surface fires, generally easily contained within simple road networks, fuel breaks and buffers.
          </BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '2',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>50</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>500</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '2',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>Sustained spread of fire.</Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>&lt;150 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;0.5-1.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for spotting is limited.</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '2',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Possible fires up to 22 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Possible fire perimeters up to 2 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '2',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fire containment mostly simple. Fires typically contained within simple road networks, fuel breaks or
            buffers &gt;3-4 m wide. Offensive, direct attack techniques on head-fire or flanks largely successful if
            fire control is necessary.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '2',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Typical prescribed burning conditions (including within scrubrolled firebreaks) where the fire will sustain
            spread.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '2',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              HEATHLAND MODEL
              <br />
              Anderson, W.R., Cruz, M.G., Fernandes, P.M., McCaw, W.L., Vega, J.A., Bradstock, R.A., Fogarty, L., Gould,
              J., McCarthy, G., Marsden-Smedley, J.B., Matthews, S., Mattingley, G., Pearce, G. and van Wilgen, B.W.
              (2015) A generic, empirical-based model for predicting rate of fire spread in shrublands. International
              Journal of Wildland Fire 24 (4), 443-460.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 3 -----
    {
      fuelType: 'Shrubland',
      category: '3',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Fast moving, wind driven fires mostly crowning.</BoldText>
          <BoldText>Typically contained within established road networks, fuel breaks and buffers.</BoldText>
          <BoldText>Active suppression may be required where fuel breaks are insufficient around assets.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '3',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>500</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>4,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '3',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fast moving, wind-driven, crown fires with high potential for large fire areas. Mostly complete combustion
              of fuels and few unburnt patches.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>&lt;1.4 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>1-4 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Potential for spotting is limited except where eucalypt/mallee trees are present where spotting is likely
              to be minimal and limited to short distances (&lt;50 m). Any spot fires are typically overrun by the main
              headfire.
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Shrubland',
      category: '3',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Possible fires up to 1,500 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Large fire perimeters up to 15 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '3',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fires generally becoming more complex and requiring wider roads, larger fuel breaks or buffers &gt;10 m for
            containment. Active suppression around assets generally not necessary unless fuel breaks around assets are
            inadequate. Combinations of direct, indirect or parallel attack may be necessary where fuel breaks are
            inadequate.
          </Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Shrubland',
      category: '3',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            P/B opportunities may exist away from assets and with well established, wide (&gt;10 m) boundaries/edges.
            P/B opportunities may exist away from the peak of the day when conditions are optimal and lighting
            techniques are suitable to achieve P/B objectives.
          </Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Shrubland',
      category: '3',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              HEATHLAND MODEL
              <br />
              Anderson, W.R., Cruz, M.G., Fernandes, P.M., McCaw, W.L., Vega, J.A., Bradstock, R.A., Fogarty, L., Gould,
              J., McCarthy, G., Marsden-Smedley, J.B., Matthews, S., Mattingley, G., Pearce, G. and van Wilgen, B.W.
              (2015) A generic, empirical-based model for predicting rate of fire spread in shrublands. International
              Journal of Wildland Fire 24 (4), 443-460.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 4 -----
    {
      fuelType: 'Shrubland',
      category: '4',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Rapidly growing crown fires that are difficult to contain and quickly become large.</BoldText>
          <BoldText>
            Mostly contained within established road networks, fuel breaks and buffers but may require active
            suppression where fuel breaks are insufficient around assets.
          </BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '4',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>4,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>20,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '4',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fast moving, wind-driven, crown fires with high potential for large fire areas. Mostly complete combustion
              of fuels and few unburnt patches.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>0.3-6.5 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>2-8 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Possible short distance spotting mostly &lt;20 m or where eucalypt/mallee trees are present where spotting
              is likely to be minimal and limited to short distances (&lt;100 m). Any spot fires are typically overrun
              by the main headfire.
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Shrubland',
      category: '4',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            High likelihood of pasture/crop/stock loss together with loss of rural assets such as fencing, machinery and
            buildings. Increasing risk of damaging impacts on the environment. Fires often producing large amounts of
            smoke. Visibility is likely to be limited due to smoke and dust, potentially impacting traffic management.
          </Text>
          <Text>Implications for firefighter safety</Text>
          <Text>Increased risk to firefighter safety</Text>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Large area fires that potentially burn up to 35,000 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Large fire perimeters that can extend up to 68 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '4',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fires quickly becoming large and difficult to contain with fuel breaks (or buffers) &gt;100 m likely to be
            effective. Conditions on the fireground are likely to be extremely windy. Active suppression including
            indirect attack or machines may be required to protect areas of population, high conservation value and on
            high value built, agricultural and cultural assets.
          </Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Shrubland',
      category: '4',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions are likely to be unsuitable for P/B however opportunities may exist away from assets and with
            well established, wide (&gt;100 m) boundaries/edges or away from the peak of the day when conditions are
            optimal and lighting techniques are suitable to achieve P/B objectives.
          </Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Shrubland',
      category: '4',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              HEATHLAND MODEL
              <br />
              Anderson, W.R., Cruz, M.G., Fernandes, P.M., McCaw, W.L., Vega, J.A., Bradstock, R.A., Fogarty, L., Gould,
              J., McCarthy, G., Marsden-Smedley, J.B., Matthews, S., Mattingley, G., Pearce, G. and van Wilgen, B.W.
              (2015) A generic, empirical-based model for predicting rate of fire spread in shrublands. International
              Journal of Wildland Fire 24 (4), 443-460.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 5 -----
    {
      fuelType: 'Shrubland',
      category: '5',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Damaging wildfires with extremely rapid fire growth.</BoldText>
          <BoldText>Active suppression may be required.</BoldText>
          <BoldText>
            High levels of threat to the environment and/or when in close proximity to people, assets and property.
          </BoldText>
          <BoldText>Elevated risk to the safety of visitors and local communities.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '5',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>20,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>20,000+</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '5',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Rapid fire growth, extremely fast moving, wind-driven fires. High potential for large fire areas with
              complete combustion of fuels and few unburnt patches.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>Rates of spread in excess of 1.5 km/hr and possibly &gt;6.5 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>flame heights &gt;4 m and likely &gt;8 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Possible short distance spotting mostly &lt;40 m except where eucalypt/mallee trees are present where
              spotting may be up to 200 m with spot fires typically quickly overrun by the main headfire.
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Shrubland',
      category: '5',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Extremely high likelihood of pasture/crop/stock loss together with loss of rural assets such as fencing,
            machinery and buildings. Very high risk of damaging impacts on the environment. Fires often producing large
            amounts of smoke and associated carbon emissions. Visibility is likely to be limited due to smoke and dust,
            potentially impacting traffic management.
          </Text>
          <Text>Implications for firefighter safety</Text>
          <Text>Increased risk to firefighter safety</Text>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Large area fires that burn over 420 ha and possibly over 35,000 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Large fire perimeters that extend over 11 km and possibly over 68 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Shrubland',
      category: '5',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fires quickly becoming large, complex and difficult to contain within existing road networks, fuel breaks
            and buffers. Fuel breaks typically need to be &gt;200 m to be effective. Conditions are likely to be
            extremely windy. Active suppression including indirect attack or machines may be required to protect areas
            of population, high conservation value and on high value built, agricultural and cultural assets.
          </Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Shrubland',
      category: '5',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>Conditions are likely to be unsuitable for P/B.</Text>
        </div>
      ),
      redFlags: ['Wind change forecast'],
    },
    {
      fuelType: 'Shrubland',
      category: '5',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              HEATHLAND MODEL
              <br />
              Anderson, W.R., Cruz, M.G., Fernandes, P.M., McCaw, W.L., Vega, J.A., Bradstock, R.A., Fogarty, L., Gould,
              J., McCarthy, G., Marsden-Smedley, J.B., Matthews, S., Mattingley, G., Pearce, G. and van Wilgen, B.W.
              (2015) A generic, empirical-based model for predicting rate of fire spread in shrublands. International
              Journal of Wildland Fire 24 (4), 443-460.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ======== Mallee-heath ========
    // ----- Cat 1 -----
    {
      fuelType: 'Mallee-heath',
      category: '1',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Mostly self-extinguishing fires.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '1',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>0</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>150</Text>
          <Text>Primary threshold limits</Text>
          <Text>Probability of self-sustained surface fire lower than 50%.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '1',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>Probability of self-sustained, surface fire is low.</Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>&lt;40 m/hr</Text>
            <Text>
              <b>Max flame length</b>
            </Text>
            <Text>flame lengths &lt;1 m</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>flame height &lt;1 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for any spotting is extremely limited.</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '1',
      segment: 'Consequences',
      content: (
        <div>
          <Text>Community losses are unlikely.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '1',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire containment relatively simple and typically contained within simple road networks, fuel breaks and
            buffers without the need for active fire suppression.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Small fires that are likely &lt; <b>1 ha</b>
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            &lt; <b>0.5 km</b>
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '1',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>Marginal prescribed burning conditions, even at peak of the day.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '1',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              MALLEE HEATH MODEL
              <br />
              Cruz, M.G. and Alexander, M.E. (2013) Uncertainty associated with model predictions of surface and crown
              fire rates of spread. Environmental Modelling & Software 47, 16-28.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 2 -----
    {
      fuelType: 'Mallee-heath',
      category: '2',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Typical prescribed burning conditions.</BoldText>
          <BoldText>Fires generally spread as surface fires.</BoldText>
          <BoldText>
            Fires mostly contained within road networks, fuel breaks (including scrub roll buffers) and recent fire
            scars.
          </BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '2',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>150</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>7,000</Text>
          <Text>Primary threshold limits</Text>
          <Text>Probability of self-sustained surface fire higher than 50%.</Text>
          <Text>Other threshold limits</Text>
          <Text>Probability of crown fire occurrence is lower than 33%.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '2',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Surface fires whereby the flame front is able overcome fine scale fuel discontinuities. Isolated torching
              of overstorey fuels.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>&lt;2 km/hr</Text>
            <Text>
              <b>Max flame length</b>
            </Text>
            <Text>flame lengths up to 5 m</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>flame height up to 5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Short range spotting possible up to 10 m.</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '2',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Community losses are unlikely however, unattended or poorly prepared assets and infrastructure may be at
            risk.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '2',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fires typically contained within road networks, fuel breaks (including recent fire scars) and buffers
            &gt;3-4 m wide with active fire suppression.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>1,500 ha</b>
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>17 km</b>
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '2',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Typical prescribed burning conditions for hazard reduction. Simple burns with adequate fuel breaks, often
            going out overnight with higher humidity and fuel moisture.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '2',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              MALLEE HEATH MODEL
              <br />
              Cruz, M.G. and Alexander, M.E. (2013) Uncertainty associated with model predictions of surface and crown
              fire rates of spread. Environmental Modelling & Software 47, 16-28.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 3 -----
    {
      fuelType: 'Mallee-heath',
      category: '3',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Fires typically vigorous surface fires with intermittent crowning possible.</BoldText>
          <BoldText>
            Fires typically controlled within established road networks, wide fuel breaks (and scrub roll buffers) and
            recent fire scars.
          </BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '3',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>7,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>10,000</Text>
          <Text>Primary threshold limits</Text>
          <Text>Probability of crown fire occurrence between 33 and 66%.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '3',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Intermittent crown fire. The passage of the flame front on surface fuels is followed by torching of
              overstorey fuels. Canopy fuel combustion occurs somewhat behind the leading edge of the flame front.
              Average flame front properties not affected by the level of torching and rate of fire spread largely
              determined by surface phase.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>1-3 km/hr</Text>
            <Text>
              <b>Max flame length</b>
            </Text>
            <Text>flame lengths up to 5.4 m</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>flame heights up to 6 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Short range spotting up to 50 m likely, allowing fire to cross small areas of fuel discontinuity such as
              roads or small fuel breaks.
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Mallee-heath',
      category: '3',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Community losses are unlikely however, unattended or poorly prepared assets and infrastructure may be at
            risk.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '3',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire containment generally requiring wider roads, fuel breaks (including recent fire scars) or buffers
            (&gt;10 m) together with active suppression. Active suppression around assets generally not necessary with
            adequate fuel breaks. Onset of crown fire activity can lead to rapid increase in rate of spread and
            intensity (2-3 x surface fire spread rate).
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>3,000 ha</b>
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>25 km</b>
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '3',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Prescribed burning opportunities exist for complex burn plans with adequate resourcing and well established,
            wide boundaries/edges. Opportunities may exist away from the peak of the day when conditions are suitable to
            achieve prescribed burn objectives.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '3',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              MALLEE HEATH MODEL
              <br />
              Cruz, M.G. and Alexander, M.E. (2013) Uncertainty associated with model predictions of surface and crown
              fire rates of spread. Environmental Modelling & Software 47, 16-28.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 4 -----
    {
      fuelType: 'Mallee-heath',
      category: '4',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Fires typically active crown fires that may escalate very quickly.</BoldText>
          <BoldText>Fires may require active suppression around assets with insufficient break widths.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '4',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>10,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>20,000</Text>
          <Text>Primary threshold limits</Text>
          <Text>Probability of crown fire occurrence between 66 and 100%.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '4',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Active or dependent crown fires with crown phase determining the overall rate of spread. Fire propagates
              faster than observed for a surface or intermittent crown fire under same environmental conditions. A
              reduction of the surface phase heat output below a certain level will lead the fire to an intermittent
              crown fire regime.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>1.5-5.5 km/hr</Text>
            <Text>
              <b>Max flame length</b>
            </Text>
            <Text>flame lengths up to 8 m</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>flame heights up to 8 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Escalation in fire activity is typically accompanied by an increase in the number of firebrands generated
              and possible distances &gt;50 m ahead of the flame front.
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Mallee-heath',
      category: '4',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            High likelihood of pasture/crop/stock loss together with loss of rural assets such as fencing, machinery and
            buildings. Increasing risk of damaging impacts on the environment. Fires often producing large amounts of
            smoke. Visibility is likely to be limited due to smoke and dust, potentially impacting traffic management.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '4',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fires quickly becoming large and difficult to contain within existing road networks, fuel breaks (including
            scrub roll buffers) and fire scars. Fuel breaks (or buffers) &gt;100 m are likely to be effective.
            Conditions on the fireground are likely to be extremely windy. Active suppression including indirect attack
            or machines may be required to protect areas of population, high conservation value and on high value built
            and cultural assets.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>12,000 ha</b>
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>50 km</b>
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Mallee-heath',
      category: '4',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions are unlikely to be suitable for prescribed burning. Potential rates of spread, long flame lengths
            and short distance spotting pose a serious risk of burn escapes. Fire intensity may be inconsistent with
            land management objectives.
          </Text>
        </div>
      ),
      redFlags: ['wind change forecast'],
    },
    {
      fuelType: 'Mallee-heath',
      category: '4',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              MALLEE HEATH MODEL
              <br />
              Cruz, M.G. and Alexander, M.E. (2013) Uncertainty associated with model predictions of surface and crown
              fire rates of spread. Environmental Modelling & Software 47, 16-28.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 5 -----
    {
      fuelType: 'Mallee-heath',
      category: '5',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Fires typically damaging crown fires with extremely rapid fire growth.</BoldText>
          <BoldText>
            Fires may require additional containment and active suppression around assets with insufficient break
            widths.
          </BoldText>
          <BoldText>
            High levels of threat to the environment and/or when in close proximity to people, assets and property.
          </BoldText>
          <BoldText>Elevated risk to the safety of visitors and local communities.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '5',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>20,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>20,000+</Text>
          <Text>Primary threshold limits</Text>
          <Text>Probability of crown fire occurrence is 100%</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '5',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>Active or dependent crown fire.</Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>Rates of spread in excess of 3 km/hr can be expected and possibly &gt; 5.5 km/hr</Text>
            <Text>
              <b>Max flame length</b>
            </Text>
            <Text>long flame lengths possibly over 8 m</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>flame heights possibly in excess of 8 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Escalation in fire activity is typically accompanied by an increase in the number of firebrands generated
              and possible distances &gt;50 m ahead of the flame front.
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Mallee-heath',
      category: '5',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Extremely high likelihood of pasture/crop/stock loss together with loss of rural assets such as fencing,
            machinery and buildings. Very high risk of damaging impacts on the environment. Fires often producing large
            amounts of smoke and associated carbon emissions. Visibility is likely to be limited due to smoke and dust,
            potentially impacting traffic management.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Mallee-heath',
      category: '5',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fires quickly becoming large, complex and difficult to contain within existing road networks, fuel breaks
            (including scrub roll buffers) and fire scars. Fuel breaks (or buffers) &gt;500 m are likely to be
            effective. Conditions on the fireground are likely to be extremely windy. Active suppression including
            indirect attack, scrub rolling or machines may be required to protect areas of population, high conservation
            value and on high value built and cultural assets.
          </Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires likely to exceed <b>1,500 ha</b> and possibly exceeding <b>12,000 ha</b>
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large fire perimeters likely to exceed <b>22 km</b> and possibly exceeding <b>50 km</b>
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Mallee-heath',
      category: '5',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions will be unsuitable for prescribed burning. Potential fireline intensity and rates of spread pose
            a serious risk to firefighter safety and the community.
          </Text>
        </div>
      ),
      redFlags: ['wind change forecast'],
    },
    {
      fuelType: 'Mallee-heath',
      category: '5',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              MALLEE HEATH MODEL
              <br />
              Cruz, M.G. and Alexander, M.E. (2013) Uncertainty associated with model predictions of surface and crown
              fire rates of spread. Environmental Modelling & Software 47, 16-28.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ======== Pine ========
    // ----- Cat 1 -----
    {
      fuelType: 'Pine',
      category: '1',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Mostly self-extinguishing, trouble-free fires.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '1',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>0</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>100</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '1',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fire difficult to ignite and sustain. Fires generally unlikely to spread and likely to self-extinguish.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>0-40 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;0.5 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for any spotting is very limited.</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '1',
      segment: 'Consequences',
      content: (
        <div>
          <Text>Community losses are unlikely.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '1',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire control relatively simple. Delayed containment possible with suitable conditions. Head-fire readily
            suppressed with offensive, direct attack techniques. Initial attack success is typically very high.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>12 hrs</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Small fires that are likely &lt; <b>1.5 ha</b> (&lt;<i>0.2 ha</i>)
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            &lt; <b>0.5 km</b> (&lt;<i>0.1 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '1',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Marginal prescribed burning conditions, even at peak of the day. Opportunities may arise where burn
            objectives target very low intensity and fuel consumption, patchy burns or for debris burning (broadcast,
            slash, windrow and piles) with particularly heavy or dry fuels.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '1',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              PINE PLANTATION PYROMETRICS
              <br />
              Cruz, M.G., Alexander, M.E. and Fernandes, P. (2008) Development of a model system to predict wildfire
              behaviour in pine plantations. Australian Forestry 71, 113-121.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 2 -----
    {
      fuelType: 'Pine',
      category: '2',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Typically slow moving, surface fires.</BoldText>
          <BoldText>Fires generally easy to suppress and contain.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '2',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>100</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>750</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '2',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Slow spreading fires, typically involving surface and near-surface fuels and sometimes into the elevated,
              ladder fuels. Spotting is sporadic and limited to short-distances.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>20-150 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;4 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Potential for spotting is limited. Possible isolated spotting up to 60 m under very dry fuel moisture
              conditions
            </Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '2',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Community losses are unlikely however unattended or poorly prepared houses and infrastructure may be at
            risk.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '2',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire control mostly simple with sufficient resources and becoming more complex at higher intensities.
            Offensive, direct attack techniques on head-fire or flanks largely successful in fire control. Delayed
            containment sometimes possible with suitable conditions.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>3 hrs</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>20 ha</b> (<i>0.5 ha</i>).
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>1.5 km</b> (<i>0.2 km</i>).
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '2',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Optimal conditions for prescribed burning (underburning and debris burning). Typically confined within
            control lines. Simple fuel reduction burning with adequate resourcing subject to the presence and
            characteristics of ladder fuels and the sensitivity of the stand to fire. Suitable seasonal and profile
            moisture content (particularly duff) should be considered in the context of conditions for likely overnight
            extinguishment and possible re-ignition. Conditions at the upper end of the range may not be suitable for
            immature stands due to high flame and scorch heights.
          </Text>
        </div>
      ),
      redFlags: [
        'Dry fuel moisture makes overnight extinguishment unlikely and high potential of re-ignition (KBDI > 63, SDl >250 (spring) or a fall of 500 (autumn))',
        'Strong wind gusts forecast',
      ],
    },
    {
      fuelType: 'Pine',
      category: '2',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              PINE PLANTATION PYROMETRICS
              <br />
              Cruz, M.G., Alexander, M.E. and Fernandes, P. (2008) Development of a model system to predict wildfire
              behaviour in pine plantations. Australian Forestry 71, 113-121.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 3 -----
    {
      fuelType: 'Pine',
      category: '3',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Most bushfires in this category.</BoldText>
          <BoldText>Fires typically suppressed with direct, parallel or indirect attack.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '3',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>750</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>4,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '3',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Actively spreading fires typically involving surface, near-surface and elevated fuel layers and
              occasionally canopy fuels. Isolated short range spotting can occur under dry fuel moisture conditions.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>70-800 m/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>2-10 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Potential for isolated spotting is limited to short distances up to around 300 m under dry fuel moisture
              conditions.
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Pine',
      category: '3',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Unattended or poorly prepared houses and infrastructure may be at risk. Potential for impacts on tree health
            in fire sensitive species and young forest stands, particularly at the upper end of the range.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '3',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fires generally becoming more complex and require more resources to control. Combinations of direct,
            indirect or parallel attack may be necessary for fire control.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>35 mins</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>500 ha</b> (<i>8 ha</i>).
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>8 km</b> (<i>1 km</i>).
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text />
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Pine',
      category: '3',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Fire is likely to carry from surface fuels into the crowns of shrubs and trees. Flame and scorch heights are
            likely to injure cambium and overstorey foliage. Increased likelihood of tree mortality and impacts on
            forest floor dynamics such as soil productivity and stability. Conditions may be suitable for more complex
            prescribed burning subject to adequate resourcing and patrolling with well established boundaries/edges.
            Prescribed burning may be conducted away from the peak of the day when conditions are optimal and lighting
            techniques are suitable to achieve objectives. Conditions may present significant risk of re-ignition.
          </Text>
        </div>
      ),
      redFlags: [
        'Dry fuel moisture makes overnight extinguishment unlikely and high potential of re-ignition (KBDI > 63, SDl >250 (spring) or a fall of 500 (autumn))',
        'Strong wind gusts forecast',
      ],
    },
    {
      fuelType: 'Pine',
      category: '3',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              PINE PLANTATION PYROMETRICS
              <br />
              Cruz, M.G., Alexander, M.E. and Fernandes, P. (2008) Development of a model system to predict wildfire
              behaviour in pine plantations. Australian Forestry 71, 113-121.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 4 -----
    {
      fuelType: 'Pine',
      category: '4',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Initial attack success critical to prevent large fire development.</BoldText>
          <BoldText>Increasing focus on defensive suppression strategies.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '4',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>4,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>10,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '4',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Rapidly spreading fires with potential for development into large burn areas within burning period. Fires
              typically involving most fuel layers. Short-range spotting is prevalent, with possibility of medium range
              and occasional long-range distance spotting.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>400 m -1 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>8-12 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Potential for short distance spotting occurring with increasing frequency with possible spotting up to 400
              m
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Pine',
      category: '4',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            6 % of house loss has occurred under these conditions. Increased potential for long term impacts on
            commercial forest productivity, pasture/crop/stock losses as well as rural assets such as fencing, machinery
            and buildings. Potential for house loss exists when homes and properties are adjacent to crown fire prone
            plantations.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '4',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Both ground and aerial resources using offensive strategies likely to be unsuccessful during the peak of the
            day, with focus largely centred on the rear and flanks. Suppression increasingly focused on defensive
            strategies. Fire control is likely to be difficult and require increased resourcing.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>30 mins</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fires up to <b>700 ha</b> (<i>11 ha</i>).
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Possible fire perimeter up to <b>10 km</b> (<i>1.2 km</i>).
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text>Increased risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Pine',
      category: '4',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions are unlikely to be suitable for prescribed burning. Potential fireline intensity and spotting
            activity pose a serious risk for burn escapes. Fire intensity, crown consumption and cambium injury are
            likely to be inconsistent with forest management objectives, impacting stand growth and woody quality even
            in older stands. Conditions present significant risk of re-ignition.
          </Text>
        </div>
      ),
      redFlags: ['Strong wind gusts forecast'],
    },
    {
      fuelType: 'Pine',
      category: '4',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              PINE PLANTATION PYROMETRICS
              <br />
              Cruz, M.G., Alexander, M.E. and Fernandes, P. (2008) Development of a model system to predict wildfire
              behaviour in pine plantations. Australian Forestry 71, 113-121.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 5 -----
    {
      fuelType: 'Pine',
      category: '5',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Conditions limit strategic suppression options.</BoldText>
          <BoldText>High levels of threat to life/property.</BoldText>
          <BoldText>Elevated risk to firefighter safety.</BoldText>
          <BoldText>Initial attack success critical to prevent large fire development.</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '5',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>10,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>30,000</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '5',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fires likely to quickly transition to crowning. Possibility for fire behaviour to become erratic and plume
              driven. Strong convective column formation. Wind speed and direction likely to be erratic at times.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>600 m -3 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>10 m - approx. double forest height</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Short and medium range spotting possible up to 1 km</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Pine',
      category: '5',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Increased risk of long term economic and environmental impacts. 24% of house loss has occurred under these
            conditions. High risk to the community related to inappropriate pre-considered plans, inadequate sheltering.
            High likelihood of plantation asset loss together with loss of rural assets such as pasture, crop, stock,
            fencing, machinery and buildings. Limited visibility due to smoke and dust.
          </Text>
          <Text>
            Strong winds are likely to impact infrastructure (e.g. power lines) and fall trees increasing the likelihood
            of new ignitions as well as obstructed roads and power outages.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '5',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Control of developed fires is extremely difficult and unlikely until conditions ease. Suppression will be
            largely based on defensive strategies, ensuring firefighter and community preparedness and safety. Offensive
            strategies could position crews in danger, however safe opportunities may exist for direct, indirect or
            parallel attack on the rear and flanks. Important initial attack opportunities may exist for new ignitions.
            Conditions on the fireground are likely to be extremely windy and smoky limiting visibility and restricting
            aviation and access. Aerial resources are likely to be ineffective at holding fire.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>10 mins</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires up to <b>6,500 ha</b> (<i>100 ha</i>).
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large fire perimeters up to <b>30 km</b> (<i>3.7 km</i>).
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text>Increased risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Pine',
      category: '5',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions will be unsuitable for prescribed burning. Potential fireline intensity and spotting activity
            pose a serious risk to firefighter safety and the community.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '5',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              PINE PLANTATION PYROMETRICS
              <br />
              Cruz, M.G., Alexander, M.E. and Fernandes, P. (2008) Development of a model system to predict wildfire
              behaviour in pine plantations. Australian Forestry 71, 113-121.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 6 -----
    {
      fuelType: 'Pine',
      category: '6',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Elevated risk to firefighter safety.</BoldText>
          <BoldText>High probability of loss of life and property.</BoldText>
          <BoldText>Initial attack success critical to prevent large fire development.</BoldText>
          <BoldText>
            Conditions limit strategic suppression options. Wind speed and limited visibility may ground some aviation
            resources.
          </BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '6',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower limit of fire intensity (kW/m)</Text>
          <Text>30,000</Text>
          <Text>Upper limit of fire intensity (kW/m)</Text>
          <Text>30,000+</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '6',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Fires likely to quickly transition to crowning. Possibility for fire behaviour to become erratic and plume
              driven. Strong convective column formation. Wind speed and direction likely to be erratic at times.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>ROS range</b>
            </Text>
            <Text>Rate of spread in excess of 2 km/hr can be expected and possibly &gt;3 km/hr</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>Possibly up to 35 m (approx. double forest height)</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>
              Short and medium range with possible long distance spotting occurring 2-3 km ahead of the main fire front
            </Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th percentile (approx. >10)', 'Wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Pine',
      category: '6',
      segment: 'Consequences',
      content: (
        <div>
          <Text>
            Very high risk of long term economic and environmental impacts. 70% of house loss has occurred under these
            conditions. Very high risk to the community related to inappropriate pre-considered plans, inadequate
            sheltering. Extremely high likelihood of plantation asset loss together with loss of rural assets such as
            pasture, crop, stock, fencing, machinery and buildings. Very limited visibility due to smoke and dust.
          </Text>
          <Text>
            Extremely strong winds are likely to impact infrastructure (e.g. power lines) and fall trees increasing the
            likelihood of new ignitions as well as obstructed roads and power outages.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '6',
      segment: 'Fire suppression & containment',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Fire control of developed fires is extremely difficult and unlikely until conditions ease. Focus will be
            largely based on defensive strategies, ensuring firefighter and community preparedness and safety. Offensive
            strategies could position crews in danger, however safe opportunities may exist for direct, indirect or
            parallel attack on the rear and flanks. Important initial attack opportunities may exist for new ignitions.
            Conditions on the fireground are likely to be extremely windy and smoky limiting visibility and restricting
            aviation and access. Conditions are likely to impact performance and effectiveness of aerial resources with
            a high probability that some aircraft will be unable to operate due to high winds and limited visibility.
            Systems such as communications, will be heavily challenged with a likelihood of difficulties and outages.
          </Text>
          {/* =================== */}
          <Text>Reference time to 5 ha</Text>
          <Text>&lt; 10 mins</Text>
          {/* =================== */}
          <Text>
            Fire area in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large area fires exceeding <b>1,000 ha</b> (<i>16 ha</i>) and potentially &gt; <b>6,500 ha</b> (&gt;{' '}
            <i>100 ha</i>)
          </Text>
          {/* =================== */}
          <Text>
            Fire perimeter in <b>4hr</b> (<i>30min</i>)
            <br />
            (Credible worst case (maximum potential))
          </Text>
          <Text>
            Large fire perimeters exceeding <b>15 km</b> (<i>2 km</i>) and potentially &gt; <b>30 km</b> (&gt;{' '}
            <i>3.7 km</i>)
          </Text>
          {/* =================== */}
          <Text>Implications for firefighter safety</Text>
          <Text>Extreme risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Pine',
      category: '6',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions will be unsuitable for prescribed burning. Potential fireline intensity and spotting activity
            pose a serious risk to firefighter safety and the community.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Pine',
      category: '6',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              PINE PLANTATION PYROMETRICS
              <br />
              Cruz, M.G., Alexander, M.E. and Fernandes, P. (2008) Development of a model system to predict wildfire
              behaviour in pine plantations. Australian Forestry 71, 113-121.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ======== Buttongrass ========
    // ----- Cat 1 -----
    {
      fuelType: 'Buttongrass',
      category: '1',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Mostly self-extinguishing, trouble-free fires.</BoldText>
          <BoldText>Moorland FDR: 0</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '1',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower ROS limit (m/hr)</Text>
          <Text>0</Text>
          <Text>Upper ROS limit (m/hr)</Text>
          <Text>30</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '1',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>Fires unlikely to be sustained and may be difficult to ignite.</Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Fireline intensity (kW/m)</b>
            </Text>
            <Text>&lt;300</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>Small flame heights typically &lt;1 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Potential for any spotting is minimal.</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '1',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Fires that are likely &lt;1 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Fire perimeters that are likely &lt;0.5 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '1',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fire control relatively simple. Mostly contained within simple, natural boundaries, road networks and fuel
            breaks &gt;2 m. Delayed containment possible with suitable conditions. Head-fire readily suppressed with
            offensive, direct attack techniques. Initial attack success is typically very high.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '1',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Marginal prescribed burn conditions, even at peak of the day. Fire may fail to sustain over fuel
            discontinuities.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '1',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              BUTTONGRASS MODEL
              <br />
              1. Marsden-Smedley, J.B. and Catchpole, W.R. (1995a) Fire behaviour modelling in Tasmanian buttongrass
              moorlands II. Fire behaviour. International Journal of Wildland Fire 5, 215-228.
              <br />
              2. Marsden-Smedley, J.B. and Catchpole, W.R. (1995b) Fire behaviour modelling in Tasmanian buttongrass
              moorlands I. Fuel characteristics. International Journal of Wildland Fire 5, 202-214.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 2 -----
    {
      fuelType: 'Buttongrass',
      category: '2',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Typical prescribed burning conditions.</BoldText>
          <BoldText>Fires generally easy to suppress.</BoldText>
          <BoldText>Moorland FDR: LOW (1-5)</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '2',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower ROS limit (m/hr)</Text>
          <Text>30</Text>
          <Text>Upper ROS limit (m/hr)</Text>
          <Text>450</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '2',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>Mostly slow spreading fires.</Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Fireline intensity (kW/m)</b>
            </Text>
            <Text>150-5,000</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&lt;6 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Possible short distance spotting up to 5 m can be expected</Text>
          </div>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '2',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Possible fires up to 150 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Possible fire perimeters up to 5 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '2',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fire control mostly simple with sufficient resources and becoming more complex at higher intensities. Fires
            typically contained within natural boundaries, road networks and fuel breaks &gt;5 m. Offensive, direct
            attack techniques on head-fire or flanks largely successful in fire control. Delayed containment sometimes
            possible with suitable conditions.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '2',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>Suitable fire behaviour for hazard reductions and ecosystem management burns.</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '2',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              BUTTONGRASS MODEL
              <br />
              1. Marsden-Smedley, J.B. and Catchpole, W.R. (1995a) Fire behaviour modelling in Tasmanian buttongrass
              moorlands II. Fire behaviour. International Journal of Wildland Fire 5, 215-228.
              <br />
              2. Marsden-Smedley, J.B. and Catchpole, W.R. (1995b) Fire behaviour modelling in Tasmanian buttongrass
              moorlands I. Fuel characteristics. International Journal of Wildland Fire 5, 202-214.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 3 -----
    {
      fuelType: 'Buttongrass',
      category: '3',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Fires difficult to control.</BoldText>
          <BoldText>Moorland FDR: MOD (6-12) and HIGH (13-24)</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '3',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower ROS limit (m/hr)</Text>
          <Text>450</Text>
          <Text>Upper ROS limit (m/hr)</Text>
          <Text>2,040</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '3',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>Typically wind driven and quickly spreading fires.</Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Fireline intensity (kW/m)</b>
            </Text>
            <Text>2,500-10,500</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>2-9 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>Possible short distance spotting up to 30 m can be expected</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th Percentile (approx 5-7)', 'wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Buttongrass',
      category: '3',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Possible fires up to 850 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Possible fire perimeters up to 10 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '3',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fires generally becoming more complex and require more resources to control. Combinations of direct,
            indirect or parallel attack may be necessary for fire control. Requires increased effort and resources to
            contain fire within existing road networks and fuel break boundaries. Fires typically requiring fuel breaks
            10-25 m wide and supported by pumps to achieve containment (control lines &gt;5 m for flanks and
            back-fires).
          </Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Buttongrass',
      category: '3',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions may be suitable for prescribed burning provided sufficient, secure, non-flammable boundaries are
            present. Fire behaviour too intense to suppress fire without relying on non-flammable boundaries.{' '}
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '3',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              BUTTONGRASS MODEL
              <br />
              1. Marsden-Smedley, J.B. and Catchpole, W.R. (1995a) Fire behaviour modelling in Tasmanian buttongrass
              moorlands II. Fire behaviour. International Journal of Wildland Fire 5, 215-228.
              <br />
              2. Marsden-Smedley, J.B. and Catchpole, W.R. (1995b) Fire behaviour modelling in Tasmanian buttongrass
              moorlands I. Fuel characteristics. International Journal of Wildland Fire 5, 202-214.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 4 -----
    {
      fuelType: 'Buttongrass',
      category: '4',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Initial attack success critical to prevent large fire development.</BoldText>
          <BoldText>Increasing focus on defensive suppression strategies.</BoldText>
          <BoldText>Moorland FDR: VERY HIGH (25-50)</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '4',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower ROS limit (m/hr)</Text>
          <Text>2,040</Text>
          <Text>Upper ROS limit (m/hr)</Text>
          <Text>4,200</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '4',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Wind driven, rapidly spreading fires with potential for development into large fire area/size and with the
              potential for short distance spotting and long flame lengths.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Fireline intensity (kW/m)</b>
            </Text>
            <Text>5,500-21,000</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>3-12 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>High risk of spotting across fire breaks. Spotting up to 500 m is common.</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th Percentile (approx 5-7)', 'wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Buttongrass',
      category: '4',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            High likelihood of pasture/crop/stock loss together with loss of rural assets such as fencing, machinery and
            buildings.
          </Text>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Possible fires up to 3,500 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Possible fire perimeters up to 20 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '4',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Offensive strategies likely to be challenged during the peak of the day with focus largely centred on the
            rear and flanks. Suppression increasingly focused on defensive strategies. Fire control is likely to be
            difficult and require increased resourcing. Fuel breaks 25-50 m wide supported by tankers required for
            containment (control lines &gt;10 m for flanks and back-fires). Personnel positioned down-wind and to the
            flanks of the fire should be made aware of the high risk of the fire jumping fire-breaks.
          </Text>
          <Text>Increased risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Buttongrass',
      category: '4',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions are unlikely to be suitable for prescribed burning. Potential rates of spread, long flame lengths
            and short distance spotting pose a serious risk of burn escapes and fire intensity may be inconsistent with
            land management objectives.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '4',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              BUTTONGRASS MODEL
              <br />
              1. Marsden-Smedley, J.B. and Catchpole, W.R. (1995a) Fire behaviour modelling in Tasmanian buttongrass
              moorlands II. Fire behaviour. International Journal of Wildland Fire 5, 215-228.
              <br />
              2. Marsden-Smedley, J.B. and Catchpole, W.R. (1995b) Fire behaviour modelling in Tasmanian buttongrass
              moorlands I. Fuel characteristics. International Journal of Wildland Fire 5, 202-214.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 5 -----
    {
      fuelType: 'Buttongrass',
      category: '5',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Initial attack success critical to prevent large fire development.</BoldText>
          <BoldText>High levels of threat to life/property.</BoldText>
          <BoldText>Elevated risk to firefighter safety.</BoldText>
          <BoldText>Conditions limit strategic suppression options.</BoldText>
          <BoldText>Moorland FDR: EXTREME (51-100)</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '5',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower ROS limit (m/hr)</Text>
          <Text>4,200</Text>
          <Text>Upper ROS limit (m/hr)</Text>
          <Text>8,400</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '5',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Extremely rapid fire growth and increasing likelihood of large final fire area/size. Possibility for fire
              behaviour to become erratic and plume driven. Strong convective column formation. Wind speed and direction
              likely to be erratic at times.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Fireline intensity (kW/m)</b>
            </Text>
            <Text>10,500-43,500</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>4-16 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>High risk of spotting across fire breaks. Spotting up to 2 km is common.</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th Percentile (approx 5-7)', 'wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Buttongrass',
      category: '5',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Increasingly high likelihood of pasture/crop/stock loss together with loss of rural assets such as fencing,
            machinery and buildings. Limited visibility due to smoke and dust.
          </Text>
          <Text style={span2Columns}>
            Strong winds are likely to impact infrastructure (e.g. power lines) and fall trees increasing the likelihood
            of obstructed roads and power outages.
          </Text>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Possible fires up to 15,000 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Possible fire perimeters up to 45 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '5',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Control of developed fires is extremely difficult and unlikely until conditions ease. Suppression will be
            largely based on defensive strategies, ensuring firefighter and community preparedness and safety. Fuel
            breaks &gt;100 m wide and supported by tankers are required for containment (control lines &gt;10 m and
            supported by pumps for flanks and back-fires). Offensive strategies could position crews in danger, however
            safe opportunities may exist for direct, indirect or parallel attack on the rear and flanks. Essential no
            personnel be positioned down-wind or to the flank of the fire unless they have safe fuel-free zones to
            retreat into. Conditions on the fireground are likely to be extremely windy and smokey limiting visibility
            and restricting aviation and access.
          </Text>
          <Text>Increased risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Buttongrass',
      category: '5',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions will be unsuitable for prescribed burning. Potential fireline intensity and rates of spread pose
            a serious risk to firefighter safety and the community.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '5',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              BUTTONGRASS MODEL
              <br />
              1. Marsden-Smedley, J.B. and Catchpole, W.R. (1995a) Fire behaviour modelling in Tasmanian buttongrass
              moorlands II. Fire behaviour. International Journal of Wildland Fire 5, 215-228.
              <br />
              2. Marsden-Smedley, J.B. and Catchpole, W.R. (1995b) Fire behaviour modelling in Tasmanian buttongrass
              moorlands I. Fuel characteristics. International Journal of Wildland Fire 5, 202-214.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
    // ----- Cat 6 -----
    {
      fuelType: 'Buttongrass',
      category: '6',
      segment: 'Key messages',
      content: (
        <div>
          <BoldText>Elevated risk to firefighter safety.</BoldText>
          <BoldText>High probability of loss of life and property when in close proximity to assets.</BoldText>
          <BoldText>Initial attack success critical to prevent large fire development.</BoldText>
          <BoldText>Conditions limit strategic suppression options.</BoldText>
          <BoldText>Moorland FDR: EXTREME (over 100)</BoldText>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '6',
      segment: 'Intensities & thresholds',
      content: (
        <div style={basicGridStyle}>
          <Text>Lower ROS limit (m/hr)</Text>
          <Text>8,400</Text>
          <Text>Upper ROS limit (m/hr)</Text>
          <Text>8,400+</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '6',
      segment: 'Indicative fire behaviour & fire weather',
      content: (
        <div>
          <div style={basicGridStyle}>
            <Text style={span2Columns}>
              Extremely rapid fire growth and high likelihood of large final fire area/size. Possibility for fire
              behaviour to become erratic and plume driven. Strong convective column formation. Wind speed and direction
              likely to be erratic at times.
            </Text>
          </div>
          <div style={basicGridStyleSmall}>
            <Text>
              <b>Fireline intensity (kW/m)</b>
            </Text>
            <Text>&gt;21,500</Text>
            <Text>
              <b>Max flame height range</b>
            </Text>
            <Text>&gt;8 m</Text>
            <Text>
              <b>Spotting Potential</b>
            </Text>
            <Text>High risk of spotting across fire breaks. Spotting over 2 km is common.</Text>
          </div>
        </div>
      ),
      redFlags: ['C-Haines >95th Percentile (approx 5-7)', 'wind change forecast during the peak of the afternoon'],
    },
    {
      fuelType: 'Buttongrass',
      category: '6',
      segment: 'Consequences',
      content: (
        <div style={basicGridStyle}>
          <Text style={span2Columns}>
            Extremely high likelihood of pasture/crop/stock loss together with loss of rural assets such as fencing,
            machinery and buildings. Very limited visibility due to smoke and dust. Very high risk to the community
            related to inappropriate pre-considered plans, inadequate sheltering.
          </Text>
          <Text style={span2Columns}>
            Strong winds are very likely to impact infrastructure (e.g. power lines) and fall trees resulting in a high
            likelihood of obstructed roads and power outages.
          </Text>
          <Text>Maximum potential fire area (without suppression)</Text>
          <Text>Large area fires exceeding 4,500 ha and potentially exceeding 15,500 ha</Text>
          <Text>Maximum potential fire perimeter (without suppression)</Text>
          <Text>Large fire periemeters exceeding 35 km and potentially exceeding 45 km</Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '6',
      segment: 'Fire suppression & containment',
      content: (
        <div>
          <Text>
            Fire control is extremely difficult and unlikely until conditions ease. Suppression will be largely based on
            defensive strategies, ensuring firefighter and community preparedness and safety. Offensive strategies could
            position crews in danger, however safe opportunities may exist for direct, indirect or parallel attack on
            the rear and flanks. Important initial attack opportunities may exist for new ignitions. Conditions on the
            fireground are likely to be extremely windy and smokey limiting visibility and restricting aviation and
            access. Conditions are likely to impact performance and effectiveness of aerial resources with a high
            probability that some aircraft will be unable to operate due to high winds and limited visibility. Systems
            such as communications, will be heavily challenged with a likelihood of difficulties and outages. Essential
            no personnel be positioned down-wind or to the flank of the fire unless they have safe fuel-free zones to
            retreat into.
          </Text>
          <Text>Increased risk to firefighter safety</Text>
        </div>
      ),
      redFlags: ["Wind change forecast - potential conditions for 'dead man zone'"],
    },
    {
      fuelType: 'Buttongrass',
      category: '6',
      segment: 'Prescribed burn implications',
      content: (
        <div>
          <Text>
            Conditions will be unsuitable for prescribed burning. Potential fireline intensity and spotting activity
            pose a serious risk to firefighter safety and the community.
          </Text>
        </div>
      ),
      redFlags: [],
    },
    {
      fuelType: 'Buttongrass',
      category: '6',
      segment: 'References',
      content: (
        <div>
          <Text>
            <p>
              BUTTONGRASS MODEL
              <br />
              1. Marsden-Smedley, J.B. and Catchpole, W.R. (1995a) Fire behaviour modelling in Tasmanian buttongrass
              moorlands II. Fire behaviour. International Journal of Wildland Fire 5, 215-228.
              <br />
              2. Marsden-Smedley, J.B. and Catchpole, W.R. (1995b) Fire behaviour modelling in Tasmanian buttongrass
              moorlands I. Fuel characteristics. International Journal of Wildland Fire 5, 202-214.
            </p>
          </Text>
        </div>
      ),
      redFlags: [],
    },
  ],
};

export default fireDangerDefinitions;
