interface NextTimeStepInterface {
  currentTime: Date | null | undefined;
  hoursPerStep: number | null;
  latestTime: Date | null | undefined;
  hourInMs: number;
  normaliseDate: (date: Date) => Date;
  isPlayingInterval: NodeJS.Timeout | null;
  timesteps: number | undefined;
}

/**
 * When called, the next timeslider will move to the next time step and sroll
 *    to it if necessary.
 *
 * Used in the Keyboard event callback when the right arrow is pressed.
 *
 * @param currentTime : The current time (UNIX timestamp since last epoch)
 * @param hoursPerStep : The number of hours per step (e.g. 3, 22, 24, etc)
 * @param latestTime : The latest time in the current timestep.
 * @param hourInMs : (constant) 60(min) * 60(sec) * 1000(ms)
 * @param normaliseDate : (func ptr) Method for normalising the date
 * @param isPlayingInterval : Flag for checking if the timeslider is in play
 * @param timesteps : The largest number of timesteps for this thing
 *    mode.
 */
function nextTimeStepHelper({
  currentTime,
  hoursPerStep,
  latestTime,
  hourInMs,
  normaliseDate,
  isPlayingInterval,
  timesteps,
}: NextTimeStepInterface): [boolean, boolean, boolean, Date | null] {
  let currTimeAndHrPerStepNotNullFlag = false;
  let setCurrentTimeFlag = false;
  let isPlayingIntervalFlag = false;
  let newTime: Date | null = null;

  if (currentTime && hoursPerStep) {
    currTimeAndHrPerStepNotNullFlag = true;
    newTime = new Date(currentTime.getTime() + hoursPerStep * hourInMs);
    if (
      latestTime &&
      (hoursPerStep === 24 && timesteps === 6
        ? newTime <= normaliseDate(latestTime)
        : newTime < normaliseDate(latestTime))
    ) {
      setCurrentTimeFlag = true;
    } else {
      setCurrentTimeFlag = false;
      if (isPlayingInterval != null) isPlayingIntervalFlag = true;
    }
  }
  return [currTimeAndHrPerStepNotNullFlag, setCurrentTimeFlag, isPlayingIntervalFlag, newTime];
}

export default nextTimeStepHelper;
