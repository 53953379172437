import { LayerManager } from 'models';

interface GetLatestTimeInterface {
  layers: LayerManager.Layer[] | undefined;
}

/**
 * Get the latest time so we know where the dark ticks need to stop
 * @returns the latest time.
 */
function getLatestTimeHelper({ layers }: GetLatestTimeInterface) {
  return layers?.reduce<Date | null>((acc, layer) => {
    if (!layer.isTimeEnabled || layer.timeSteps == null || layer.timeSteps.length === 0) return acc;
    const latestForThisLayer = layer.timeSteps.reduce((latestDateSoFar, date) =>
      latestDateSoFar < date ? date : latestDateSoFar,
    );
    return acc == null || latestForThisLayer > acc ? latestForThisLayer : acc;
  }, null);
}

export default getLatestTimeHelper;
