interface DragSliderHelperInterface {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>;
  sliderTabRef: React.RefObject<HTMLDivElement>;
  sliderTabTextRef: React.RefObject<HTMLDivElement>;
  activeTabWidth: number;
  tabActiveTextContainerMarginLeft: number;
  getDateFromMarginOffset: (offset: number) => Date | null;
  dragDate: Date | null;
  currentTime: Date | null | undefined;
}

/**
 * Handles with slider drag event update.
 * It will calculate the hover date from the pixel offests and using 'dragDate'
 * when it detects a new normalised date has been crossed. Then the timeslider
 * state and date will update.
 * This allows the map to render as the slider is being dragged.
 * @param event The slider drag event
 * @param sliderTabRef Reference to the slider HTML `<div>` element
 * @param sliderTabTextRef Reference to the slider texts HTML `<div>` element
 * @param activeTabWidth (constant) the active tab's width
 * @param tabActiveTextContainerMarginLeft (constant) the left hand margin for
 *      the tab's text container.
 * @param getDateFromMarginOffset (func ptr) for determining the date based on
 *      a given offset number
 * @param dragDate The date when the drag began(?)
 * @param currentTime The current time now
 * @param setCurrentTime (func ptr) for setting the current timebased on
 *      a given date.
 *
 */
function dragSliderHelper({
  event,
  sliderTabRef,
  sliderTabTextRef,
  activeTabWidth,
  tabActiveTextContainerMarginLeft,
  getDateFromMarginOffset,
  dragDate,
  currentTime,
}: DragSliderHelperInterface): [number | null, number | null, Date | null, boolean] {
  let newMargin = null;
  let newMarginText = null;
  let newDate = null;
  let newDateCheck = false;

  if (sliderTabRef.current && sliderTabTextRef.current) {
    // Used in determining the new date to display (as the date is computed RELATIVE to the first tab)
    newMargin = event.clientX - event.currentTarget.getBoundingClientRect().x - activeTabWidth / 2;
    newMarginText = Math.max(newMargin, Math.abs(tabActiveTextContainerMarginLeft));

    newDate = getDateFromMarginOffset(newMargin);

    // Perform the newDate check
    const isNewDate =
      newDate &&
      ((dragDate && newDate.getTime() !== dragDate.getTime()) ||
        (!dragDate && currentTime && newDate.getTime() !== currentTime.getTime()));

    if (isNewDate) {
      newDateCheck = true;
    }
  }
  return [newMargin, newMarginText, newDate, newDateCheck];
}

export default dragSliderHelper;
