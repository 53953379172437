interface GetDateFromMarginOffsetInterface {
  timesliderPixeloffset: number;
  startOfDay: Date | null | undefined;
  hoursPerStep: number | null;
  tabSpacing: number;
  activeTabWidth: number;
  tabWidth: number;
  timeStepOffsets: { [timeStepOffset: string]: number };
  hourInMs: number;
}

/**
 * Used for when the slider is being dragged to calculate the date from
 * the specific pixel location of the slider. This is then normalised to
 * figure out what time is selected by this.
 * @returns The date represented by the provided offset (or `null`).
 */
function getDateFromMarginOffsetHelper({
  timesliderPixeloffset,
  startOfDay,
  hoursPerStep,
  tabSpacing,
  activeTabWidth,
  tabWidth,
  timeStepOffsets,
  hourInMs,
}: GetDateFromMarginOffsetInterface) {
  // If the layer is 1 hourly/3 hourly or daily
  if (startOfDay && hoursPerStep != null) {
    // Calculate the clicked timeslider tab segment based on UI elements
    const timesliderTabIndex = Math.floor(
      (timesliderPixeloffset + tabSpacing / 2 + activeTabWidth / 2) / (tabWidth + tabSpacing),
    );

    let newDate: Date;

    // If clicked timeslider tab is on a 3 hourly layer
    // Calculate the relevant day, floored to the initial value within the 3 hourly band.
    if (hoursPerStep === 3) {
      newDate = new Date(
        startOfDay.getTime() +
          Math.floor((timesliderTabIndex - timeStepOffsets.offsetFor3HourLayers) / hoursPerStep) *
            hoursPerStep *
            hourInMs +
          hourInMs * timeStepOffsets.offsetFor3HourLayers,
      );

      return newDate;
    }

    // If clicked timeslider tab is on a 1 hourly layer
    // Calculate the relevant day, ignoring the offset (eg. treat 10:30am as 10am).
    if (hoursPerStep === 1) {
      newDate = new Date(
        startOfDay.getTime() +
          (timesliderTabIndex / hoursPerStep) * hoursPerStep * hourInMs +
          hourInMs * timeStepOffsets.offsetFor1HourLayers,
      );

      return newDate;
    }

    // If clicked the timeslider and there is only a single timestep, do nothing.
    if (hoursPerStep === 0) {
      return null;
    }

    // If clicked timeslider tab is on a daily layer (default case)
    // Calculate the relevant day, floored to the initial value at midnight.
    newDate = new Date(startOfDay.getTime() + Math.floor(timesliderTabIndex / hoursPerStep) * hoursPerStep * hourInMs);
    return newDate;
  }
  return null;
}

export default getDateFromMarginOffsetHelper;
