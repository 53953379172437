/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, TableCell, TableRow, Button } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { WmsManager, LayerManager, Loadable } from 'models';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    th: {
      fontWeight: 'bold',
      fontSize: '16px',
      borderBottom: `1px solid ${theme.palette.common.neutralXLight}`,
      borderRight: `1px solid ${theme.palette.common.neutralXLight}`,
      minWidth: 320,
      width: 320,
    },
    tc: {
      borderBottom: `1px solid ${theme.palette.common.neutralXLight}`,
      textAlign: 'center',
    },
    smPad: {
      padding: 16,
    },
    square: {
      borderRadius: 4,
      width: 48,
      height: 48,
      padding: 2,
      textAlign: 'center',
      fontWeight: 'bold',
      paddingTop: theme.spacing(0.75),
      display: 'grid',
      placeItems: 'center',
      gridAutoFlow: 'column',
    },
  }),
);

const useThinStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    th: {
      fontWeight: 'bold',
      fontSize: '16px',
      borderBottom: `1px solid ${theme.palette.common.neutralXLight}`,
      borderRight: `1px solid ${theme.palette.common.neutralXLight}`,
      minWidth: 320,
      width: 320,
    },
    tc: {
      borderBottom: `1px solid ${theme.palette.common.neutralXLight}`,
      textAlign: 'center',
      fontSize: '14px',
    },
    smPad: {
      padding: 12,
    },
    square: {
      borderRadius: 4,
      width: 48,
      height: 48,
      padding: 1,
      fontSize: '14px',
      textAlign: 'center',
      fontWeight: 'bold',
      paddingTop: theme.spacing(0.75),
      display: 'grid',
      placeItems: 'center',
      gridAutoFlow: 'column',
    },
  }),
);

interface IRatingRegion {
  name: string | null;
  values: (string | number | { value: string | number; layerId: LayerManager.Layer.LayerIds; text?: string })[];
  subRegions?: IRatingRegion[];
}

interface IProps {
  rating: IRatingRegion;
  legendColorDefault?: string;
  legends: Record<LayerManager.Layer.LayerIds, Loadable<WmsManager.WMS.GetLegend.Legend>>;
  thin?: boolean;
  small?: boolean;
  initExpanded?: boolean;
  onExpandChange?: (expanded: boolean) => void;
}

const lumiosity = (rgb: number[]): number => {
  const srgb = rgb.map((x) => x / 255);
  const r = srgb[0] <= 0.03928 ? srgb[0] / 12.92 : ((srgb[0] + 0.055) / 1.055) ** 2.4;
  const g = srgb[1] <= 0.03928 ? srgb[1] / 12.92 : ((srgb[1] + 0.055) / 1.055) ** 2.4;
  const b = srgb[2] <= 0.03928 ? srgb[2] / 12.92 : ((srgb[2] + 0.055) / 1.055) ** 2.4;
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const RatingRow: React.FunctionComponent<IProps> = ({
  rating,
  legendColorDefault,
  legends,
  thin,
  small,
  initExpanded,
  onExpandChange,
}) => {
  const normalClasses = useStyles();
  const thinClasses = useThinStyles();
  const classes = thin ? thinClasses : normalClasses;
  const theme = useTheme();

  const [expanded, setExpandedRaw] = useState(initExpanded ?? false);

  const setExpanded = (isExpanded: boolean) => {
    setExpandedRaw(isExpanded);
    if (onExpandChange) onExpandChange(isExpanded);
  };

  const renderExpandoButton = () => {
    if (!rating.subRegions || rating.subRegions?.length === 0) return <></>;
    const ariaLabel = `expand for subregions of ${rating.name}`;
    return (
      <Button
        aria-label={ariaLabel}
        style={{ position: 'absolute', right: 0, top: 0 }}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </Button>
    );
  };

  const toBackgroundColour = (value: string | number, layerId?: LayerManager.Layer.LayerIds) =>
    (layerId && legends[layerId]?.object && WmsManager.getLegendEntry(value, legends[layerId]?.object)?.color) ??
    legendColorDefault ??
    theme.palette.common.neutralXXLight;

  const renderCellContent = (value: string | number, layerId?: LayerManager.Layer.LayerIds, text?: string) => {
    const rangeRegexp = /^(\d+)-(\d+)$/g;
    const getColorFromHex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

    const regexpResults = typeof value !== 'number' ? rangeRegexp.exec(value) : null;

    if (regexpResults && regexpResults[1] != null && regexpResults[2] != null) {
      const background1 = toBackgroundColour(regexpResults[1], layerId);
      const background2 = toBackgroundColour(regexpResults[2], layerId);
      const rgb1 =
        getColorFromHex
          .exec(background1)
          ?.slice(1)
          .map((x) => Number.parseInt(x, 16)) ?? null;
      const rgb2 =
        getColorFromHex
          .exec(background2)
          ?.slice(1)
          .map((x) => Number.parseInt(x, 16)) ?? null;
      const color1 = rgb1 == null || lumiosity(rgb1) > 0.179 ? 'black' : 'white';
      const color2 = rgb2 == null || lumiosity(rgb2) > 0.179 ? 'black' : 'white';
      return (
        <div
          className={classes.square}
          style={{ background: `linear-gradient(to right, ${background1} 50%, ${background2} 50%)` }}
        >
          <span style={{ color: color1 }}>
            {Number.parseInt(regexpResults[1], 10) < 10 && Number.parseInt(regexpResults[2], 10) >= 10 ? (
              <>&nbsp;&nbsp;</>
            ) : (
              ''
            )}
            {regexpResults[1]}
          </span>
          <span>–</span>
          <span style={{ color: color2 }}>{regexpResults[2]}</span>
        </div>
      );
    }

    const background = toBackgroundColour(value, layerId);

    const rgb =
      getColorFromHex
        .exec(background)
        ?.slice(1)
        .map((x) => Number.parseInt(x, 16)) ?? null;
    const color = rgb == null || lumiosity(rgb) > 0.179 ? 'black' : 'white';
    return (
      <div className={classes.square} style={{ background, color }}>
        {text ?? value}
      </div>
    );
  };

  return (
    <>
      <TableRow>
        <TableCell className={classes.th}>
          <div style={{ position: 'relative' }}>
            {rating.name ?? ''}
            {renderExpandoButton()}
          </div>
        </TableCell>
        {rating.values.map((data, idx) => (
          <TableCell className={`${classes.tc} ${small ? classes.smPad : ''}`} key={idx}>
            {typeof data === 'string' || typeof data === 'number'
              ? renderCellContent(data)
              : renderCellContent(data.value, data.layerId, data.text)}
          </TableCell>
        ))}
      </TableRow>
      {expanded && (
        <>
          {rating.subRegions?.map((x) => (
            <RatingRow key={x.name} rating={x} legends={legends} />
          ))}
        </>
      )}
    </>
  );
};

export default RatingRow;
