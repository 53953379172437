import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    indicatorIcon: {
      width: 16,
      height: 16,
      display: 'inline-block',
      borderRadius: 2,
    },
  }),
);

interface IProps {
  colour: string;
  size?: number | string;
  radius?: number | string;
  margin?: number | string;
}

const IndicatorIcon: React.FunctionComponent<IProps> = ({ colour, size, radius, margin }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.indicatorIcon}
      style={{ background: colour, width: size, height: size, borderRadius: radius, margin }}
    />
  );
};

export default IndicatorIcon;
