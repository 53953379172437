interface PrevTimeStepInterface {
  currentTime: Date | null | undefined;
  hoursPerStep: number | null;
  hourInMs: number;
  earliestTime: Date | null | undefined;
  latestTime: Date | null | undefined;
  normaliseDate: (date: Date) => Date;
  setCurrentTime: (date?: Date | null, initial?: boolean) => void;
  setAndNormaliseCurrentTime: (date?: Date | null, initial?: boolean) => void;
  scrollToTab: () => Promise<void>;
}

function prevTimeStepEventHandler({
  currentTime,
  hoursPerStep,
  hourInMs,
  earliestTime,
  latestTime,
  normaliseDate,
  setCurrentTime,
  setAndNormaliseCurrentTime,
  scrollToTab,
}: PrevTimeStepInterface) {
  if (currentTime && hoursPerStep) {
    const newTime = new Date(currentTime.getTime() - hoursPerStep * hourInMs);
    if (earliestTime && newTime >= normaliseDate(earliestTime)) {
      setCurrentTime(newTime);
    } else {
      setAndNormaliseCurrentTime(latestTime);
    }
  }
  scrollToTab();
}

export default prevTimeStepEventHandler;
