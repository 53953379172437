interface ToggleSpeedInterface {
  playbackSpeedIndex: number;
  setplaybackSpeedIndex: (value: React.SetStateAction<number>) => void;
  isPlayingInterval: NodeJS.Timeout | null;
  restartInterval: (newPlaybackSpeed?: number) => void;
  playBackSpeeds: number[];
}

function toggleSpeedEventHandler({
  playbackSpeedIndex,
  setplaybackSpeedIndex,
  isPlayingInterval,
  restartInterval,
  playBackSpeeds,
}: ToggleSpeedInterface) {
  const newPlaybackIndex = (playbackSpeedIndex + 1) % playBackSpeeds.length;
  setplaybackSpeedIndex(newPlaybackIndex);
  if (isPlayingInterval != null) restartInterval(playBackSpeeds[newPlaybackIndex]);
}

export default toggleSpeedEventHandler;
